import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
  Form,
} from "react-bootstrap";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  asyncTagsList,
  capitalizeString,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { openModal, releasedPhoneNumberListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import Tooltip from "rc-tooltip";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";

const initialFilterValues = {
  signalHouseSID: "",
  phoneNumber: "",
  groupId: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
  numberStatus: "",
  tenDLCStatus: "",
  friendlyName: "",
  qTags: [],
};

const ReleaseNumber = (props: any) => {
  document.title = "Signal House Portal Released Phone Number";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.Groups,
    (numbers, groups) => ({
      loading: numbers.loading,
      releasedPhoneNumberObj: numbers.releasedPhoneNumberObj,
      error: numbers.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, releasedPhoneNumberObj, groupDetails } =
    useSelector(selectProfile);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        qTags:
          values.qTags.length > 0 ? values.qTags?.map((dt: any) => dt.tag) : "",
      });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Number</span>,
      selector: (row: { phoneNumber: number | string }) => row.phoneNumber,
      cell: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row.phoneNumber}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Friendly Name</span>,
      selector: (row: { friendlyName: any }) => row.friendlyName,
      cell: (row: { friendlyName: any; phoneNumber: string }) => (
        <span>
          {row.friendlyName}
          <i
            className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
            style={{
              WebkitTextStroke: "0.5px",
            }}
            onClick={() => {
              dispatch(
                openModal({
                  path: "AddFriendlyName",
                  data: {
                    title: "Add Friendly Name",
                    detailsfriendlyName: row.friendlyName,
                    phoneNumber: row?.phoneNumber,
                    getOwnPhoneNumber: () =>
                      handleGetData(currPage, rowsPerPage),
                    footer: false,
                  },
                })
              );
            }}
          />
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Messaging Status</span>,
      selector: (row: { tenDLCWhiteListStatus: any }) =>
        row.tenDLCWhiteListStatus,
      sortable: true,
      cell: (cell: { tenDLCWhiteListStatus: any }) => {
        const status = cell.tenDLCWhiteListStatus?.toLowerCase() || "N/A";
        switch (status) {
          case "mt":
          case "false":
          case "in-process":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(
                  cell.tenDLCWhiteListStatus.replaceAll("-", " ")
                )}
              </span>
            );
          case "ready":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus)}
              </span>
            );
          case "pending":
            return (
              <span className="d-flex align-items-center text-black">
                <span className="badge border border-2 border-white rounded-circle bg-black p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus)}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(status)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Number Status</span>,
      selector: (row: { numberStatus: any }) => row.numberStatus,
      sortable: true,
      cell: (cell: { numberStatus: any }) => {
        switch (cell.numberStatus) {
          case "Inactive":
            return (
              <span
                className="badge bg-body-secondary border border-primary text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Inactive
              </span>
            );
          case "Active":
            return (
              <span
                className="badge bg-body-secondary border border-secondary text-secondary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(String(cell.numberStatus) || "N/A")}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Tags</span>,
      selector: (row: { tags: any }) => row.tags.join(", "),
      cell: (row: { tags: any; phoneNumber: string }) => (
        <div className="d-flex flex-wrap">
          {row.tags.map((tag: any, i: number) => (
            <span className="details-tag" key={i}>
              {tag}
            </span>
          ))}
          <i
            className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
            style={{
              WebkitTextStroke: "0.5px",
            }}
            onClick={() => {
              dispatch(
                openModal({
                  path: "EditTags",
                  data: {
                    title: "Tags",
                    detailsTags: row.tags,
                    phoneNumber: row?.phoneNumber,
                    getOwnPhoneNumber: () =>
                      handleGetData(currPage, rowsPerPage),
                    footer: false,
                  },
                })
              );
            }}
          />
        </div>
      ),
      grow: 2,
      wrap: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Capabilities</span>,
      selector: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
        };
      }) => {
        const capabilities = [];
        if (row?.capabilities?.voice) capabilities.push("Voice");
        if (row?.capabilities?.sms) capabilities.push("SMS");
        if (row?.capabilities?.mms) capabilities.push("MMS");
        return capabilities.join(", ");
      },
      cell: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      cell: (row: {
        phoneNumber?: number | string;
        signalHouseSID?: number | string;
      }) => {
        return (
          <Button
            className="btn  btn-sm"
            variant="outline-secondary"
            onClick={() =>
              props.router.navigate(
                `/mynumbers/${row?.phoneNumber}/${row?.signalHouseSID}`
              )
            }
          >
            Configure
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(releasedPhoneNumberListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        qTags:
          validation.values.qTags.length > 0
            ? validation.values.qTags?.map((dt: any) => dt.tag)
            : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        qTags:
          validation.values.qTags.length > 0
            ? validation.values.qTags?.map((dt: any) => dt.tag)
            : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Released Phone Number"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => setOpen(!open)}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">
                          Phone Number SID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumberSID"
                          placeholder="Search by Phone Number SID"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "signalHouseSID",
                              e.target.value
                            );
                          }}
                          value={validation.values.signalHouseSID}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="phoneNumber"
                          placeholder="Search by Phone Number"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "phoneNumber",
                              e.target.value
                            );
                          }}
                          value={validation.values.phoneNumber}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <AsyncPaginate
                          isDisabled={!validation.values.groupId}
                          key={validation.values.groupId}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) => option?.sub_group_id}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup as never
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup as never
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="numberStatus">
                          Number Status
                        </Form.Label>
                        <Select
                          name="numberStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.numberStatus &&
                              validation?.errors?.numberStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Active", label: "Active" },
                            { value: "Inactive", label: "Inactive" },
                          ]}
                          placeholder="Select status"
                          value={
                            validation.values.numberStatus
                              ? {
                                  label: validation.values.numberStatus,
                                  value: validation.values.numberStatus,
                                }
                              : null
                          }
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("numberStatus", e.value);
                            } else {
                              validation.setFieldValue("numberStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tenDLCStatus">
                          Messaging Status
                        </Form.Label>
                        <Select
                          name="tenDLCStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.tenDLCStatus &&
                              validation?.errors?.tenDLCStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Pending", label: "Pending" },
                            { value: "Ready", label: "Ready" },
                            { value: "In-Progress", label: "In-Progress" },
                          ]}
                          placeholder="Select status"
                          value={
                            validation.values.tenDLCStatus
                              ? {
                                  label: validation.values.tenDLCStatus,
                                  value: validation.values.tenDLCStatus,
                                }
                              : null
                          }
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("tenDLCStatus", e.value);
                            } else {
                              validation.setFieldValue("tenDLCStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="friendlyName">
                          Friendly Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="friendlyName"
                          placeholder="Enter Friendly Name"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "friendlyName",
                              e.target.value
                            );
                          }}
                          value={validation.values.friendlyName}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="qTags">Tags</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "qTags"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.qTags &&
                              validation?.errors?.qTags
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.qTags
                              ? validation.values.qTags
                              : null
                          }
                          loadOptions={asyncTagsList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                              isReleased: true,
                            }),
                            "tagSearch"
                          )}
                          getOptionValue={(option: any) => option?.tag}
                          getOptionLabel={(option: any) => option?.tag}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.qTags === validation.values.qTags
                            ) {
                              return;
                            }

                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup as never
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              validation.setFieldValue("qTags", option);
                            } else {
                              validation.setFieldValue("qTags", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.setValues(initialFilterValues);
                          validation.resetForm();
                          validation.setFieldValue(
                            "groupId",
                            groupDetails?.records?.[0]?.group_id
                          );
                          handleGetData(currPage, rowsPerPage);
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(releasedPhoneNumberObj?.records)
                        ? releasedPhoneNumberObj?.records
                        : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={releasedPhoneNumberObj?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ReleaseNumber);
