import { APIClient, baseUrl } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();
// // Gets the logged in user data from local session

// // Gets the logged in user data from local session
// export const getLoggedInUser = () => {
//   const user = localStorage.getItem("user");
//   if (user) return JSON.parse(user);
//   return null;
// };

// // is user is logged in
// export const isUserAuthenticated = () => {
//   return getLoggedInUser() !== null;
// };

// Register api
export const registerApi = (url2: any, data: any) =>
  api.create(
    `${url.REGISTER_COMPANY_ADMIN}?registrationEmailVerifyUri=${url2}`,
    data
  );

export const registerNotionApi = (data: any) => api.createNotion(data);

// Verify email api
export const verifyEmailApi = (token: any) =>
  api.get(url.VERIFY_EMAIL, { token });

// Register sub user api
export const registerSubUserApi = (url2: any, data: any) => {
  const urlTemp = () => {
    switch (data?.roles) {
      case "company_admin":
        return url.REGISTER_COMPANY_ADMIN_BY_ADMIN;
      case "company_billing":
        return url.REGISTER_COMPANY_BILLING;
      case "company_developer":
        return url.REGISTER_COMPANY_DEVELOPER;
      case "company_standard":
        return url.REGISTER_COMPANY_STANDARD;
      default:
        return url.REGISTER_SUBUSER;
    }
  };
  return api.create(`${urlTemp()}?registrationEmailVerifyUri=${url2}`, data);
};

// Sub user groups assign api
export const subUserGroupsAssignApi = (
  groupId: string,
  subUserId: string,
  data: any
) => api.create(`${url.SUBUSER_GROUPS_ASSIGN}/${groupId}/${subUserId}`, data);

// Sub user subgroups assign api
export const subUserSubGroupsAssignApi = (data: any) =>
  api.create(url.SUBUSER_SUBGROUPS_ASSIGN, data);

// get sub users list
export const subUsersListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.SUBUSERS_LIST, { page, limit: recordsPerPage, ...otherParams });

// get sub users list
export const subUserLoginDetailsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.SUBUSER_LOGIN_DETAIL, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Delete sub user login session
export const deleteSubUserLoginSessionApi = (emailId: any) =>
  api.delete(`${url.SUBUSER_LOGIN_DETAIL}/${emailId}`);

// update sub user password api
export const updateSubUserPassApi = (data: any, emailId: any) =>
  api.update(`${url.UPDATE_SUBUSER_PASS}/${emailId}`, data);

// Login api
export const loginApi = (data: any) => api.create(url.LOGIN, data);

// get logged in user
export const authUserApi = () => api.get(url.GET_AUTH_USER, null);

// upload file
export const fileUploadApi = (data: any, isFormData?: boolean) =>
  api.create(url.UPLOAD_FILE, data, isFormData);

// get file from path
export const getFileApi = (path: any) =>
  api.get(url.GET_FILE, { path, responseType: "blob" });

// update auth user
export const updateAuthUserApi = (data: any) =>
  api.update(url.UPDATE_AUTH_USER, data);

export const authHistoryApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) => api.get(url.authHistory, { page, limit: recordsPerPage, ...otherParams });

// check user & forgot password api
export const forgotPassApi = (emailId: any) =>
  api.get(url.CHECK_USER, {
    emailId,
    forgotPassUri: `${
      process.env.REACT_APP_FRONTEND_URL || "https://dev.signalhouse.io"
    }${url.FORGOT_PASS}`,
  });

// update password api
export const updatePassApi = (data: any, token?: any, email?: any) =>
  api.update(
    `${token ? url.CREATE_PASS : url.UPDATE_PASS}${
      token ? `?token=${token}&email=${email}` : ""
    }`,
    data
  );

// get brand list
export const brandListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_BRAND_LIST, { page, limit: recordsPerPage, ...otherParams });

// get brand details
export const brandDetailsApi = (id: any) =>
  api.get(`${url.GET_BRAND_DETAILS}/${id}`, null);

// get tags list
export const tagsListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_TAGS_LIST, { page, limit: recordsPerPage, ...otherParams });

// create brand
export const createBrandApi = (data: any) => api.create(url.CREATE_BRAND, data);

// update brand and resubmit brand
export const updateBrandApi = (brandId: any, data: any) =>
  api.put(url.UPDATE_BRAND(brandId), data);

// Revert Brand
export const revertBrandApi = (brandId: any) =>
  api.put(url.REVERT_BRAND(brandId), null);

// delete brand
export const deleteBrandApi = (brandId: any) =>
  api.delete(url.DELETE_BRAND(brandId), null);

// Status Appeal of Brand
export const appealStatusApi = (brandId: any, payload: any) =>
  api.create(url.APPEAL(brandId), payload);

//Delete attachment Appeal of Brand
export const deleteAppealAttachmentApi = (attachmentId: any) =>
  api.delete(url.BRAND_AND_CAMPAIGN_ATTACHMENT(attachmentId), null);

// Download Brand Appeal attachment
export const downloadAppealAttachmentApi = (attachmentId: any) =>
  api.get(url.BRAND_AND_CAMPAIGN_ATTACHMENT(attachmentId), null);

// get csp webhook events list
export const cspWebhookEventsListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.CSP_WEBHOOK_EVENTS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

export const numProvListListApi = (
  campaignId: string,
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(`${url.GET_CAMPAIGN_DETAILS}/${campaignId}${url.OSR_PHONES}`, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Status Appeal of Brand
export const getAppealsApi = (brandId: any) =>
  api.get(url.APPEAL(brandId), null);

//Appeal File Upload
export const fileUplaoadApi = (brandId: string, payload: any) =>
  api.create(url.FILE_UPLOAD(brandId), payload, true);

//Appeal get File
export const getEvidenceApi = (brandId: string) =>
  api.get(url.FILE_UPLOAD(brandId), null);

export const getEvidencesApi = (attachmentId: string) =>
  api.get(url.BRAND_AND_CAMPAIGN_ATTACHMENT(attachmentId), null);

//brand/{brandId}/externalVetting
export const applyVettingApi = (brandId: string, payload: any) =>
  api.create(url.Vetting(brandId), payload);

//Vetting apply and import
export const applyAndImportVettingApi = () => api.get(url.VETTING_CLASS, null);

export const applyAndImportVettingEvpApi = () =>
  api.get(url.VETTING_CLASS_AND_IMPORT, null);

//submit vatting
export const vattingSubmitApi = (brandId: string, payload: any) =>
  api.create(url.VETTING_CLASS_SUBMIT(brandId), payload);

// submit Import Vetting
export const importVattingSubmitApi = (brandId: string, payload: any) =>
  api.put(url.VETTING_CLASS_IMPORT_SUBMIT(brandId), payload);

// get vatting list
export const getVattingListApi = (brandId: string) =>
  api.get(url.VETTING_CLASS_REPORT(brandId), null);

// vertical enums list
export const verticalListApi = () => api.get(url.GET_VERTICAL_LIST, null);

// MNO enums data
export const BrandSuspendedMNOsApi = () => api.get(url.MNO, null);

// stock exchange enums list
export const stockExchangeListApi = () =>
  api.get(url.GET_STOCK_EXCHANGE_LIST, null);

// country enums list
export const countryListApi = () => api.get(url.GET_COUNTRY_LIST, null);

// state enums list
export const stateListApi = () => api.get(url.GET_STATE_LIST, null);

// brand appeal category enums list
export const brandAppealCategoryListApi = () =>
  api.get(url.GET_BRAND_APPEAL_CATEGORY_LIST, null);

// entoty type enums list
export const entityTypeListApi = () => api.get(url.GET_ENTITY_TYPE_LIST, null);

// brand relationship enums list
export const brandRelationshipListApi = () =>
  api.get(url.GET_BRAND_RELATIONSHIP_LIST, null);

// get numbers
export const getNumbersApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) => {
  return api.get(url.GET_PHONE_NUMBER, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });
};

// get numbers
export const buyNumberApi = (payload: any) => {
  return api.create(url.BUY_PHONE_NUMBER, payload);
};

//edit configure phone number data
export const editConfigurePhoneNumber = (payload: any) => {
  return api.getNumber(url.Configure_Phone_Number_Edit, payload);
};

//edit get own number details
export const ownNumberDetail = (phoneNumber: any) => {
  return api.getNumber(url.GET_OWN_NUMBER_DETAILS, phoneNumber);
};

//edit configure phone number data
export const saveTags = (payload: any) => {
  return api.create(url.SAVE_TAGS, payload);
};

// phone number api for Porting Requests filter
export const portingPhoneNumberApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.PORTING_PHONE_NUMBER, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

//Active Phone Number
export const activePhoneNumberApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.Active_Phone_Number, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get verified caller ids
export const verifiedCallerIDsListApi = (
  phoneNumber: any,
  friendlyName: any
) => {
  return api.get(url.GET_VERIFIED_CALLER_IDS, {
    phoneNumber: phoneNumber || "",
    friendlyName: friendlyName || "",
  });
};

export const releasePhoneNumberApi = (payload: any) => {
  return api.create(url.RELEASE_PHONE_NUMBER, payload);
};

// create new caller id
export const createVerifiedCallerIDsApi = (data: any) =>
  api.create(url.CREATE_VERIFIED_CALLER, data);

// verify caller id
export const checkVerificationApi = (data: any) =>
  api.create(url.CHECK_VERIFICATION, data);

// brand relationship enums list
export const releasedPhoneNumbersListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_RELEASED_PHONE_NUMBERS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get port request
export const portRequestListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_PORT_REQUEST, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get out port request
export const portOutRequestListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_OUT_PORT_REQUEST, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// create port number request
export const createPortNumberRequestApi = (data: any) =>
  api.create(url.CREATE_PORT_NUMBER_REQUEST, data);

// create lookup number request
export const createPhoneNumberLookupApi = (data: any) =>
  api.create(url.PHONE_NUMBER_LOOKUP, data);

// get campaign list
export const campaignListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_CAMPAIGN_LIST, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get campaign campaignDcaElected Status
export const campaignDcaElectedStatusListApi = (data?: any) =>
  api.get(url.GET_CAMPAIGN_DCA_ELECTED_STATUS_LIST, data);

// get usecase list
export const usecaseListApi = () => api.get(url.GET_USECASE, null);

// get connectivity partners list
export const connectivityPartnersListApi = () =>
  api.get(url.GET_CONNECTIVITY_PARTNERS, null);

// get campaign usecase from brand
export const usecaseFromBrandApi = (brandId: any) =>
  api.get(`${url.GET_CAMPAIGN_BRAND}/${brandId}`, null);

// get campaign terms from usecase and brand
export const termsFromUsecaseApi = (brandId: any, usecaseId: any) =>
  api.get(`${url.GET_CAMPAIGN_BRAND}/${brandId}/usecase/${usecaseId}`, null);

// get campaign t&c
export const termsAndConditionsApi = () => api.get(url.GET_TnC, null);

// get reseller list
export const resellerListApi = () => api.get(url.GET_RESELLER_LIST, null);

// create reseller
export const createResellerApi = (data: any) =>
  api.create(url.GET_RESELLER_LIST, data);

// create campaign
export const createCampaignApi = (data: any) =>
  api.create(url.CREATE_CAMPAIGN, data);

// upload campaign MMS
export const uploadCampaignMmsApi = (
  id: any,
  data: any,
  isFormData?: boolean
) => api.create(`${url.GET_CAMPAIGN_DETAILS}/${id}/mms`, data, isFormData);

// get campaign details
export const campaignDetailsApi = (id: any) =>
  api.get(`${url.GET_CAMPAIGN_DETAILS}/${id}`, null);

// get campaign partner details
export const campaignPartnerDetailsApi = (id: any) =>
  api.get(`${url.GET_PARTNER_CAMPAIGN_DETAILS}/${id}`, null);

// get campaign mnoMetadata
export const getCampaignMnoMetadataApi = (id: any, isPartner?: boolean) =>
  api.get(
    `${
      isPartner ? url.GET_PARTNER_CAMPAIGN_DETAILS : url.GET_CAMPAIGN_DETAILS
    }/${id}/mnoMetadata`,
    null
  );

// get campaign MMS
export const getCampaignMmsApi = (id: any) =>
  api.get(`${url.GET_CAMPAIGN_DETAILS}/${id}/mms`, null);

// get campaign operation status
export const campaignOperationStatusApi = (id: any, isPartner?: boolean) =>
  api.get(
    `${
      isPartner ? url.GET_PARTNER_CAMPAIGN_DETAILS : url.GET_CAMPAIGN_DETAILS
    }/${id}/operationStatus`,
    null
  );

// get campaign DCA elected
export const campaignDcaElectedApi = (id: any) =>
  api.get(`${url.GET_CAMPAIGN_DETAILS}/${id}/mnoIdsWithDcaElected`, null);

// update campaign
export const updateCampaignApi = (id: any, data: any) =>
  api.put(`${url.GET_CAMPAIGN_DETAILS}/${id}`, data);

// get campaign attachment
export const getCampaignAttachmentApi = (id: any) =>
  api.get(
    `${url.GET_CAMPAIGN_ATTACHMENT}/${encodeURIComponent(
      encodeURIComponent(id)
    )}`,
    { responseType: "blob" }
  );

// Delete campaign attachment
export const deleteCampaignAttachmentApi = (id: any) =>
  api.delete(
    `${url.GET_CAMPAIGN_ATTACHMENT}/${encodeURIComponent(
      encodeURIComponent(id)
    )}`
  );

// campaign Nudge CNP to Review/Appeal Rejection
export const nudgeCampaignApi = (id: any, data: any) =>
  api.create(`${url.GET_CAMPAIGN_DETAILS}/${id}/nudge`, data);

// resubmit campaign
export const resubmitCampaignApi = (id: any, data: any) =>
  api.put(`${url.GET_CAMPAIGN_DETAILS}/${id}/resubmit`, data);

// Deactivate campaign
export const deactivateCampaignApi = (id: any) =>
  api.delete(`${url.GET_CAMPAIGN_DETAILS}/${id}`);

// get payment history
export const paymentHistoryApi = (page?: any, limit?: any) =>
  api.get(url.WALLET_SUMMARY, {
    page,
    limit,
  });

// create payment intent
export const createPaymentIntentApi = (amount: any) =>
  api.create(`${url.CREATE_PAYMENT_INTENT}?amount=${amount}`, {});

// Get payment methods
export const getPaymentMethodApi = () => api.get(url.PAYMENT_METHOD, null);

// Attach a payment method by ID
export const attachPaymentMethodIdApi = (paymentMethodId: any) =>
  api.create(`${url.PAYMENT_METHOD}/${paymentMethodId}`, {});

// Delete payment method by ID
export const deletePaymentMethodIdApi = (paymentMethodId: any) =>
  api.delete(`${url.PAYMENT_METHOD}/${paymentMethodId}`);

// Create a new wallet customer
export const createCustomerApi = () => api.create(url.WALLET_CUSTOMER, {});

// Pay with a specified payment method
export const payWithPaymentMethodApi = (paymentMethodId: any, amount: any) =>
  api.create(
    `${url.PAY_WITH_PAYMENT_METHOD}/${paymentMethodId}?amount=${amount}`,
    {}
  );

// get sms & mms combine logs
export const getAllMessageLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.ALL_MESSAGE_LOGS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Get message logs
export const getMessageLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) => api.get(url.MESSAGE_LOGS, { page, limit: recordsPerPage, ...otherParams });

export const getMmsLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) => api.get(url.MMS_LOGS, { page, limit: recordsPerPage, ...otherParams });

export const getEventLogsApi = (
  pagination: any,
  phoneNumber: any,
  otherParams?: any
) =>
  api.get(url.GET_OWN_NUMBERS_EVENT_LOGS(), {
    phoneNumber,
    page: pagination.page,
    limit: pagination.recordsPerPage,
    ...otherParams,
  });

export const getRegulatoryInformationApi = (
  // pagination: any,
  phoneNumber: any,
  otherParams?: any
) =>
  api.get(url.GET_REGULATORY_INFORMATION(), {
    phoneNumber,
    // page: pagination.page,
    // limit: pagination.recordsPerPage,
    ...otherParams,
  });

// ...
//Get message logs for a single number
export const getPersonalMessageLogsApi = (
  pagination: any,
  phoneNumber: any,
  otherParams?: any
) =>
  api.get(url.GET_PERSONAL_MESSAGE_LOGS(phoneNumber), {
    page: pagination.page,
    limit: pagination.recordsPerPage,
    ...otherParams,
  });

// Get message logs
export const getMessageLogDetailsApi = (payload: any) =>
  api.get(url.MESSAGE_LOGS_DETAILS, payload);

// Get mms logs details
export const getMmsLogDetailsApi = (payload: any) =>
  api.get(url.MMS_LOGS_DETAILS, payload);

// Get usage summary
export const getUsageSummaryApi = (startDate?: any, endDate?: any) =>
  api.get(url.USAGE_SUMMARY, { startDate, endDate });

// Get usage transactions
export const getUsageTransactionsApi = (
  serviceType: any,
  startDate?: any,
  endDate?: any,
  page?: any,
  limit?: any
) => {
  const payload = {
    startDate,
    endDate,
    page,
    limit,
  };
  if (!!serviceType) {
    return api.get(url.USAGE_TRANSACTIONS, {
      ...payload,
      serviceType,
    });
  } else {
    return api.get(url.USAGE_TRANSACTIONS, {
      ...payload,
    });
  }
};

// Get auto recharge configuration
export const getAutoRechargeConfigApi = () =>
  api.get(url.WALLET_AUTO_RECHARGE, null);

// Configure auto recharge
export const configAutoRechargeApi = (data: any) =>
  api.create(url.WALLET_AUTO_RECHARGE, data);

// Update auto recharge configuration
export const updateAutoRechargeConfigApi = (data: any) =>
  api.update(url.WALLET_AUTO_RECHARGE, data);

// send sms
export const sendSMSApi = (data: any) => api.create(url.SEND_SMS, data);

// send mms
export const sendMMSApi = (data: any) => api.create(url.SEND_MMS, data);

// upload mms file
export const uploadMmsFileApi = (data: any, isFormData?: boolean) =>
  api.create(url.UPLOAD_MMS_FILE, data, isFormData);

// get mms file
export const getMmsFileApi = (id: string) =>
  api.get(`${url.GET_MMS_FILE}/${id}`, { responseType: "blob" });

//  Create Message Template
export const createMessageTemplateApi = (data: any) =>
  api.create(url.MESSAGE_CREATE_TEMPLATE, data);

// Find Message Template
export const findMessageTemplateApi = (data: any) =>
  api.get(url.MESSAGE_FIND_TEMPLATE, data);

//Edit Message Template
export const editMessageTemplateApi = (data: any) =>
  api.put(url.MESSAGE_EDIT_TEMPLATE, data);

//Delete Message Template
export const deleteMessageTemplateApi = (data: any) =>
  api.delete(url.MESSAGE_DELETE_TEMPLATE(data), null);

//get Template id
export const getMessageTemplateDetailsApi = (data: any) =>
  api.get(url.MESSAGE_TEMPLATE_DETAILS, data);

//Message Template File Upload
export const messageTemplatefileUplaoadApi = (brandId: string, payload: any) =>
  api.create(url.FILE_UPLOAD(brandId), payload, true);

// upload file
// export const messagefileUploadApi = (data: any) =>
//   api.create(url.UPLOAD_FILE, data);

export const messageFileUploadApi = (data: any, isFormData?: boolean) =>
  api.create(url.UPLOAD_FILE, data, isFormData);

export const messaeGetFileApi = (path: any) =>
  api.get(url.GET_FILE, { path, responseType: "blob" });

// get user steps for home page
export const getUserStepsApi = () => api.get(url.USER_STEPS, null);

// update user steps
export const updateUserStepsApi = (data: any) =>
  api.create(url.USER_STEPS, data);

//Attachment ID Sender Number
export const attachmentIdSenderNumberApi = (data: any) =>
  api.create(url.ATTACHMENT_ID_SENDER_NUMBER, data);

//Ten DLC Whitelist
export const tenDLCWhitelistApi = (data: any) =>
  api.create(url.TEN_DLC_WHITE_LIST, data);

// Get Account Pricing for Settings
export const getAccountPricingApi = () => api.get(url.ACCOUNT_PRICING, null);

//Get All Webhooks
export const getWebhooksListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.WEBHOOK, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Get Webhook By Id
export const getWebhookDetailsByIdApi = (id: any) =>
  api.get(`${url.WEBHOOK}/${id}`, null);

// webhook logs for detail page
export const getWebhookLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_WEBHOOK_LOGS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Webhook Events
export const getWebhookEventsApi = (otherParams?: any) =>
  api.get(url.GET_WEBHOOK_EVENTS, { ...otherParams });

// create a New Webhook
export const createWebhookApi = (data: any) => api.create(url.WEBHOOK, data);

export const editWebhookApi = (id: any, data: any) =>
  api.update(`${url.WEBHOOK}/${id}`, data);

//Delete Message Template
export const deleteWebhookApi = (id: any) =>
  api.delete(`${url.WEBHOOK}/${id}`, null);

export const getPortingStatusLogsApi = (portingId: any, pagination: any) =>
  api.get(url.GET_PORTING_STATUS_LOGS(), {
    portingId,
    page: pagination.page,
    limit: pagination.recordsPerPage,
  });

export const getNumVerifyLookUpApi = (phoneNumber: string) =>
  api.get(url.NUMBER_VERIFY_LOOKUP(phoneNumber), null);

//  Create Message Template
export const addFriendlyNameApi = (data: any) =>
  api.create(url.ADD_FRIENDLY_NAME, data);

export const getLookupLogsApi = (pagination: any, otherParams?: any) =>
  api.get(url.GET_LOOKUP_LOGS(), {
    page: pagination.page,
    limit: pagination.limit,
    ...otherParams,
  });

// Get All Groups
export const getAllGroupsApi = (pagination: any) =>
  api.get(url.GET_ALL_GROUPS(), {
    page: pagination.page,
    limit: pagination.recordsPerPage,
  });

//Create New Group Api
export const createNewGroupApi = (data: any) =>
  api.create(url.CREATE_NEW_GROUP, data);

// Update a Group Api
export const updateGroupApi = (data: any) =>
  api.update(`${url.UPDATE_GROUP}?groupId=${data?.groupId}`, data);

// get group details
export const geGroupDetailsApi = (data: any) =>
  api.get(url.GROUP_DETAILS, data);

//Delete group
export const deleteGroupApi = (data: any) =>
  api.delete(url.DELETE_GROUP(data), null);

// get subgroup list
export const subgroupListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_SUBGROUP_LIST, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get subgroup details
export const subgroupDetailsApi = (id: any) =>
  api.get(`${url.GET_SUBGROUP_DETAILS}/${id}`, null);

// create subgroup
export const createSubgroupApi = (data: any) =>
  api.create(`${url.CREATE_SUBGROUP}?groupId=${data?.groupId}`, data);

// update subgroup
export const updateSubgroupApi = (data: any) =>
  api.update(`${url.UPDATE_SUBGROUP}?subGroupId=${data?.subGroupId}`, data);

// 2fa modules apis
export const numVerify = () => api.get(`${url.NUM_VERIFY}`, null);
export const getOtpAPi = () => api.get(url.GET_OTP, null);
export const verifyNum = (data: any) => api.create(url.NUM_VERIFY, data);
export const verifyLoginOTP = (data: any) =>
  api.create(url.LOGIN_OTP_VERIFY, data);

// analytics
export const analyticsSmsV1Api = (otherParams?: any) =>
  api.get(url.ANALYTICS_SMS_V1, otherParams);

export const analyticsMmsV1Api = (otherParams?: any) =>
  api.get(url.ANALYTICS_MMS_V1, otherParams);

export const smsStatusLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.SMS_STATUS_LOGS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

export const mmsStatusLogsApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.MMS_STATUS_LOGS, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// Get Notification Events
export const getNotificationEventsApi = () =>
  api.get(url.NOTIFICATION_LOGS, null);

// create a Notification Events
export const createNotificationEventsApi = (data: any) =>
  api.create(url.CREATE_NOTIFICATION_EVENTS, data);

// get web notifications list api
export const getWebNotificationsListApi = () =>
  api.get(url.WEB_NOTIFICATIONS, null);

export const fetchCampaignDetailsApi = (campaignId: any, brandId: any) =>
  api.get(`${brandId ? "/campaign/" : "/partnerCampaign/"}${campaignId}`, null);

// update web notification api
export const updateWebNotificationApi = (data: any) =>
  api.update(url.WEB_NOTIFICATIONS, data);

// get notifications list
export const notificationsListApi = (
  page?: any,
  recordsPerPage?: any,
  otherParams?: any
) =>
  api.get(url.GET_NOTIFICATIONS_LIST, {
    page,
    limit: recordsPerPage,
    ...otherParams,
  });

// get help-center all api
export const getHelpCenterPageApi = (data: any) => api.createNotion(data);
