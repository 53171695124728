import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import TimezoneSelect from "react-timezone-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createNewGroupThunk,
  fileUploadThunk,
  getAllGroupsThunk,
  getFileThunk,
  openModal,
  updateAuthUserThunk,
  updateGroupThunk,
  updateUserStepsThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  customSelectTheme,
  getChangedValues,
  getInitials,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import BreadCrumb from "Common/BreadCrumb";
import Loader from "assets/images/spinner-white.svg";
import { toast } from "react-toastify";
import { onCloseSubject } from "Common/modals/Modal";

const General = () => {
  const dispatch = useDispatch<any>();
  const fileInputRef = useRef(null);

  const [profileUrl, setProfileUrl] = useState("");

  const selectAccount = createSelector(
    (state: any) => state.AuthUser,
    (state: any) => state.Groups,
    (authUser, groups) => ({
      loading: authUser.loading,
      loading2: authUser.loading2,
      user: authUser.authUser,
      stepsData: authUser.stepsData,
      success: authUser.success,
      error: authUser.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, loading2, user, stepsData, groupDetails } =
    useSelector(selectAccount);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      profilePictureLink: user?.profilePictureLink || "",
      firstName: user?.firstName || "",
      middleName: user?.middleName || "",
      lastName: user?.lastName || "",
      phone: user?.phone ? Number(user?.phone) : "",
      emailId: user?.emailId || "",
      companyName: user?.companyName || "",
      cspUid: user?.cspUid?.toUpperCase() || "",
      billingAddress: {
        addressLine1: user?.billingAddress?.addressLine1 || "",
        addressLine2: user?.billingAddress?.addressLine2 || "",
        city: user?.billingAddress?.city || "",
        state: user?.billingAddress?.state || "",
        country: user?.billingAddress?.country || "US",
        postalCode: user?.billingAddress?.postalCode || "",
      },
      shippingAddress: {
        addressLine1: user?.shippingAddress?.addressLine1 || "",
        addressLine2: user?.shippingAddress?.addressLine2 || "",
        city: user?.shippingAddress?.city || "",
        state: user?.shippingAddress?.state || "",
        country: user?.shippingAddress?.country || "US",
        postalCode: user?.shippingAddress?.postalCode || "",
      },
      timezone:
        user?.timezone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "",
      groupName: user?.companyName || "",
      groupDescription: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      // middleName: Yup.string().required("Please Enter Middle Name"),
      lastName: Yup.string().required("Please Enter last Name"),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(
          /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
          "Phone number must start with 1-3 digit country code followed by 7-15 digit phone number, numbers only"
        ),
      emailId: Yup.string().required("Please Enter Email Address"),
      companyName: Yup.string().required("Please Enter Company Name"),
    }),
    onSubmit: (values: any) => {
      const cb = () => {
        !stepsData?.completedProfile &&
          dispatch(
            updateUserStepsThunk(
              { completedProfile: true },
              user?.completedSteps
            )
          );
      };

      const userInitValues = { ...validation.initialValues };
      delete userInitValues["groupName"];
      delete userInitValues["groupDescription"];

      const userValues = { ...values, phone: values.phone.toString() };
      delete userValues["groupName"];
      delete userValues["groupDescription"];

      const userChangedData = getChangedValues(userInitValues, userValues);

      let changePhone = false;
      if (userChangedData.phone !== user?.phone) {
        userChangedData["numberVerified"] = "pending";
        userChangedData["twoFAEnabled"] = 0;

        changePhone = true;
      }
      dispatch(updateAuthUserThunk(userChangedData, cb)).then(() => {
        if (changePhone && user?.phone && user?.twoFAEnabled === 1) {
          toast.success(
            "Please verify your new mobile number to re-enable Two-Factor Authentication (2FA)."
          );
        }
      });

      const grpInitValues = {
        groupName: groupDetails?.records?.[0]?.group_name,
        groupDescription: groupDetails?.records?.[0]?.group_description,
        groupContactPersonFirstName:
          groupDetails?.records?.[0]?.group_contact_person_first_name,
        groupContactPersonLastName:
          groupDetails?.records?.[0]?.group_contact_person_last_name,
        groupContactPersonMiddleName:
          groupDetails?.records?.[0]?.group_contact_person_middle_name,
        groupCompanyName: groupDetails?.records?.[0]?.group_company_name,
        addressLine1: groupDetails?.records?.[0]?.address_line1,
        addressLine2: groupDetails?.records?.[0]?.address_line2,
        city: groupDetails?.records?.[0]?.city,
        state: groupDetails?.records?.[0]?.state,
        country: groupDetails?.records?.[0]?.country,
        postalCode: groupDetails?.records?.[0]?.postal_code,
        groupContactPhoneNumber:
          groupDetails?.records?.[0]?.group_contact_phone_number,
      };

      const grpValues = {
        groupName: values.companyName,
        groupDescription: values.groupDescription,
        groupContactPersonFirstName: values.firstName,
        groupContactPersonLastName: values.lastName,
        groupContactPersonMiddleName: values.middleName,
        groupCompanyName: values.companyName,
        addressLine1: values.billingAddress.addressLine1,
        addressLine2: values.billingAddress.addressLine2,
        city: values.billingAddress.city,
        state: values.billingAddress.state,
        country: values.billingAddress.country,
        postalCode: values.billingAddress.postalCode,
        groupContactPhoneNumber: values.phone.toString(),
      };

      if (groupDetails?.records?.[0]?.group_id) {
        const grpChangedData = removeEmptyAndNullValues(
          getChangedValues(grpInitValues, grpValues)
        );
        dispatch(
          updateGroupThunk(
            removeEmptyAndNullValues({
              ...grpChangedData,
              groupContactPersonOrigin: "Web",
            }),
            groupDetails?.records?.[0]?.group_id,
            () => dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }))
          )
        );
      } else {
        dispatch(
          createNewGroupThunk(
            removeEmptyAndNullValues({
              ...grpValues,
              groupContactPersonOrigin: "Web",
            }),
            () => dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }))
          )
        );
      }
    },
  });

  useEffect(() => {
    getImgLink();
  }, [validation.values.profilePictureLink]);

  const uploadImage = (e: any) => {
    const cb = (files: any) => {
      validation.setFieldValue("profilePictureLink", files?.[0]?.path);
    };

    const handleSave = (file: any) => {
      try {
        onCloseSubject.next({});
        const formData = new FormData();
        formData.append("profileImage", file);
        dispatch(fileUploadThunk(formData, true, cb));
      } catch (e) {}
    };

    try {
      e.preventDefault();
      let files = e.target.files;

      // 2MB
      if (files?.[0]?.size > 2097152) {
        toast.error("Please select a file less than 2MB.");
      } else {
        const reader = new FileReader();
        if (!reader) return;
        reader.onload = () => {
          e.target.value = null;
          dispatch(
            openModal({
              path: "ImageCropper",
              data: {
                title: "Crop Image",
                footer: false,
                src: reader.result?.toString(),
                handleSave,
                handleCancel: () => {
                  onCloseSubject.next({});
                },
              },
            })
          );
        };
        reader.readAsDataURL(files[0]);
      }
    } catch (error) {}
  };

  const handlePhotoRemove = () => {
    // validation.setFieldValue("profilePictureLink", "");
  };

  const getImgLink = () => {
    if (validation.values.profilePictureLink) {
      const cb = (img: Blob | MediaSource) => {
        const temp = URL.createObjectURL(img);
        setProfileUrl(temp);
      };
      dispatch(getFileThunk(validation.values.profilePictureLink, cb));
    }
  };

  const handleCancelClick = () => {
    validation.resetForm();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Account settings" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Card>
            <Card.Body>
              <Row>
                <div className="mb-1">
                  <h5>Personal Details</h5>
                </div>
                <Col lg={12}>
                  <Row className="border bg-light-gray rounded-3 p-3">
                    <Col lg={12} className="mb-3 d-flex justify-content-end">
                      {/* <Form.Label
                          htmlFor="profilePictureLink"
                          className="mb-0 me-3"
                        >
                          Profile Picture
                        </Form.Label> */}
                      <div className="profile-user position-relative">
                        {loading || loading2 ? (
                          <img
                            src={Loader}
                            alt="loading"
                            className="avatar-lg rounded-circle p-1 img-thumbnail object-fit-cover position-absolute start-0 top-0  bg-dark bg-opacity-50"
                          />
                        ) : null}
                        {profileUrl ? (
                          <img
                            src={profileUrl}
                            onError={() => {
                              setProfileUrl("");
                            }}
                            alt=""
                            className="avatar-lg rounded-circle p-1 img-thumbnail object-fit-cover"
                          />
                        ) : (
                          <div className="avatar-lg rounded-circle p-1 img-thumbnail m-auto">
                            <div className="avatar-title rounded-circle bg-secondary fs-5xl">
                              {getInitials(
                                `${validation.values.firstName} ${validation.values.lastName}`
                              )}
                            </div>
                          </div>
                        )}
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                          <input
                            ref={fileInputRef}
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input d-none"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={uploadImage}
                            disabled={loading || loading2}
                          />
                          <label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="bi bi-camera"></i>
                            </span>
                          </label>
                        </div>
                        <span
                          className="profile-photo-remove fs-3xs translate-middle badge rounded-circle bg-primary"
                          onClick={handlePhotoRemove}
                        >
                          <span className="notification-badge">X</span>
                        </span>
                      </div>
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="firstName">
                        First Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        isInvalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.firstName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="middleName">Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="middleName"
                        name="middleName"
                        placeholder="Enter middle name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.middleName || ""}
                        isInvalid={
                          validation.touched.middleName &&
                          validation.errors.middleName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.middleName &&
                      validation.errors.middleName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.middleName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="lastName">
                        Last Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        isInvalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.lastName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="phone">
                        Phone Number <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="phone"
                        name="phone"
                        placeholder="Enter phone number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone || ""}
                        isInvalid={
                          validation.touched.phone && validation.errors.phone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phone && validation.errors.phone ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.phone}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="emailId">Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        id="emailId"
                        name="emailId"
                        placeholder="Enter email"
                        disabled={true}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.emailId || ""}
                        isInvalid={
                          validation.touched.emailId &&
                          validation.errors.emailId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.emailId &&
                      validation.errors.emailId ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.emailId}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="companyName">
                        Company Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter company name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyName || ""}
                        isInvalid={
                          validation.touched.companyName &&
                          validation.errors.companyName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.companyName &&
                      validation.errors.companyName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.companyName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="timezone">Timezone</Form.Label>
                      <TimezoneSelect
                        id="timezone"
                        name="timezone"
                        labelStyle="original" // 'original' | 'altName' | 'abbrev' | 'offsetHidden'
                        displayValue="GMT" // 'GMT' | 'UTC'
                        styles={styleObj(
                          validation.touched.timezone &&
                            validation.errors.timezone
                        )}
                        theme={customSelectTheme}
                        placeholder="Select timezone"
                        onChange={(e: any) => {
                          validation.setFieldValue(
                            "timezone",
                            e.value ? e.value : ""
                          );
                        }}
                        value={validation.values.timezone || ""}
                        isInvalid={
                          validation.touched.timezone &&
                          validation.errors.timezone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.timezone &&
                      validation.errors.timezone ? (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {validation.errors.timezone}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6}>
                      <Form.Label htmlFor="cspUid">CSP ID</Form.Label>
                      <Form.Control
                        type="text"
                        id="cspUid"
                        name="cspUid"
                        placeholder="Enter csp id"
                        disabled={user?.cspUid}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "cspUid",
                            e.target.value.toUpperCase()
                          );
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.cspUid || ""}
                        isInvalid={
                          validation.touched.cspUid && validation.errors.cspUid
                            ? true
                            : false
                        }
                      />
                      {validation.touched.cspUid && validation.errors.cspUid ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.cspUid}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <div className="hstack gap-2 justify-content-end mb-4">
            <Button
              className="btn btn-outline-primary"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading || loading2}
            >
              {loading2 && <Spinner size="sm" animation="border" />} Update
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default General;
