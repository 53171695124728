import React, { useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getWebNotificationsListThunk,
  updateWebNotificationThunk,
} from "slices/thunk";
import moment from "moment";
import { Link } from "react-router-dom";

const NotificationDropdown = () => {
  const dispatch = useDispatch<any>();

  const selectData = createSelector(
    (state: any) => state.Notifications,
    (notifications) => ({
      webNotificationsData: notifications.webNotificationsData,
      errors: notifications.errors,
    })
  );

  const { webNotificationsData } = useSelector(selectData);
  const [open, setOpen] = useState(false);

  const fetchNotifications = async () => {
    await dispatch(getWebNotificationsListThunk());
  };

  const handleUpdateNotification = async (id: string, clearAll?: boolean) => {
    await dispatch(
      updateWebNotificationThunk(
        {
          notificationId: clearAll ? "all" : [id],
          updatedStatus: "read",
        },
        fetchNotifications
      )
    );
  };

  const setDropdown = () => {
    !open && fetchNotifications();
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Dropdown
        className="topbar-head-dropdown ms-1 header-item"
        id="notificationDropdown"
        show={open}
        onToggle={setDropdown}
      >
        <Dropdown.Toggle
          id="notification"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-dark rounded-circle arrow-none"
        >
          <i className="bi bi-bell fs-2xl"></i>
          {webNotificationsData?.data?.length > 0 && (
            <span className="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-primary">
              <span className="notification-badge">
                {webNotificationsData?.data?.length}
              </span>
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div className="dropdown-head rounded-top">
            <div className="p-3 border-bottom border-bottom-dashed">
              <Row className="align-items-center">
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mb-0 fs-lg fw-semibold">
                      Notifications{" "}
                      {webNotificationsData?.data?.length > 0 && (
                        <span className="badge bg-primary-subtle text-primary fs-sm notification-badge">
                          {webNotificationsData?.data?.length}
                        </span>
                      )}
                    </h6>
                    <p
                      className="text-primary mb-0 cursor-pointer"
                      onClick={() => {
                        handleUpdateNotification("", true);
                      }}
                    >
                      Clear All
                    </p>
                  </div>
                  <p className="fs-md text-muted mt-1 mb-0">
                    You have{" "}
                    <span className="fw-semibold notification-unread">
                      {webNotificationsData?.data?.length}
                    </span>{" "}
                    unread messages
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="py-2 ps-2" id="notificationItemsTabContent">
            {webNotificationsData?.data?.length > 0 ? (
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">
                  New
                </h6>
                {webNotificationsData?.data?.map((dt: any, i: number) => (
                  <div
                    className="text-reset notification-item d-block dropdown-item position-relative unread-message cursor-default"
                    key={i}
                  >
                    <div className="d-flex">
                      <div className="avatar-xs flex-shrink-0">
                        {dt?.notificationAlert === "primary" ? (
                          <span className="avatar-title bg-primary-subtle text-primary rounded-circle fs-lg">
                            <i className="bx bx-check-circle"></i>
                          </span>
                        ) : dt?.notificationAlert === "warning" ? (
                          <span className="avatar-title bg-warning-subtle text-warning rounded-circle fs-lg">
                            <i className="ri ri-error-warning-line"></i>
                          </span>
                        ) : dt?.notificationAlert === "success" ? (
                          <span className="avatar-title bg-secondary-subtle text-secondary rounded-circle fs-lg">
                            <i className="bx bx-check-circle"></i>
                          </span>
                        ) : dt?.notificationAlert === "error" ? (
                          <span className="avatar-title bg-primary-subtle text-primary rounded-circle fs-lg">
                            <i className="ri ri-error-warning-line"></i>
                          </span>
                        ) : (
                          <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                            <i className="bx bx-check-circle"></i>
                          </span>
                        )}
                      </div>
                      <div className="ms-3 me-2 flex-grow-1">
                        <div className="stretched-link">
                          <h6
                            className="mt-0 fs-md mb-2 lh-base"
                            style={{ wordBreak: "break-word" }}
                          >
                            {dt?.notificationMessage}
                          </h6>
                        </div>
                        <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {dt?.createdDate
                              ? moment(dt?.createdDate).fromNow()
                              : "Unknown time"}
                          </span>
                        </p>
                      </div>
                      <div className="fs-md">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={i.toString()}
                            checked={false}
                            onChange={() => {
                              handleUpdateNotification(dt?.notificationId);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={i.toString()}
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </SimpleBar>
            ) : (
              <div className="d-flex text-muted justify-content-center align-items-center p-2">
                No notifications found
              </div>
            )}
            <div
              className="d-flex text-muted justify-content-center align-items-center pt-3 pb-2"
              style={{
                background: "var(--tb-dropdown-bg)",
                borderTop: "1px solid var(--tb-border-color)",
              }}
            >
              <Link to="/all-notifications" onClick={() => setDropdown()}>
                Show All Notifications
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
