import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import UsageSummary from "./UsageSummary";
import Widgets from "./widgets";
import DeliveryRate from "./DeliveryRate";
import LastDays from "./LastDays";
import { removeEmptyAndNullValues } from "helpers/common";
import {
  customSelectStyles,
  customSelectTheme,
  last12Months,
} from "helpers/common";
import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getUsageSummaryThunk,
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
} from "slices/thunk";
import Flatpickr from "react-flatpickr";

const selectOpts = [...last12Months()];

const Dashbaord = () => {
  document.title = "Signal House Portal Dashboard";

  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState<any>({});
  const [month, setMonth] = useState({
    startDate: moment().subtract(7, "days").startOf("day").format("MM-DD-YYYY"),
    endDate: moment().endOf("day").format("MM-DD-YYYY"),
  });

  const dateRangeArr = [
    {
      label: "24 hours",
      value: "24 hours",
    },
    {
      label: "7 days",
      value: "7 days",
    },
    {
      label: "30 days",
      value: "30 days",
    },
    {
      label: "120 days",
      value: "120 days",
    },
    {
      label: "Custom (fill custom range)",
      value: "Custom",
    },
  ];

  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [selectedRange, setSelectedRange] = useState(dateRangeTemp.label);
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [resCardObj, setResCardObj] = useState<any>({});

  const selectAnalytics = createSelector(
    (state: any) => state.Analytics,
    (analytics) => ({
      analyticsAllV1: analytics.analyticsAllV1,
    })
  );

  const { analyticsAllV1 } = useSelector(selectAnalytics);

  const fetchAnalyticsData = (params: any) => {
    const cb = () => {
      dispatch(getAnalyticsMmsV1Thunk(removeEmptyAndNullValues({ ...params })));
    };
    dispatch(
      getAnalyticsSmsV1Thunk(removeEmptyAndNullValues({ ...params }), cb)
    );
  };

  useEffect(() => {
    const params = {
      groupId: "GKY75X",
      startDate: moment(month.startDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
      endDate: moment(month.endDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
    };
    fetchAnalyticsData({ ...params });
  }, [dispatch, month]);

  useEffect(() => {
    if (analyticsAllV1) {
      setAnalyticsData(analyticsAllV1);
      setLoading(false);
    }
  }, [analyticsAllV1]);

  useEffect(() => {
    if (month?.startDate && month?.endDate) {
      const formattedStartDate = moment(month.startDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(month.endDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
    }
  }, [dispatch, month]);

  useEffect(() => {
    setDateRangeTemp(dateRangeArr[1]);
    setSelectedRange(dateRangeArr[1].label);
  }, []);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getCardData();
    }
  }, [analyticsAllV1]);

  const getCardData = () => {
    const calculateResponseRate = () => {
      const groupDataByPhoneNumber = (
        data: Record<string, Record<string, Record<string, number>>>
      ) => {
        return Object.keys(data).reduce(
          (acc: Record<string, Record<string, number>>, date) => {
            const dateData = data[date];
            Object.keys(dateData).forEach((phoneNumber) => {
              if (!acc[phoneNumber]) {
                acc[phoneNumber] = {};
              }
              Object.keys(dateData[phoneNumber]).forEach((key) => {
                acc[phoneNumber][key] =
                  (acc[phoneNumber][key] || 0) + dateData[phoneNumber][key];
              });
            });
            return acc;
          },
          {}
        );
      };

      const smsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.SMS);
      const mmsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.MMS);
      const allDataByPhoneNumber: any = Object.keys({
        ...smsDataByPhoneNumber,
        ...mmsDataByPhoneNumber,
      }).reduce((acc: any, phoneNumber: any) => {
        acc[phoneNumber] = {};
        const smsData = smsDataByPhoneNumber[phoneNumber] || {};
        const mmsData = mmsDataByPhoneNumber[phoneNumber] || {};
        const allKeys = new Set([
          ...Object.keys(smsData),
          ...Object.keys(mmsData),
        ]);
        allKeys.forEach((key) => {
          acc[phoneNumber][key] = (smsData[key] || 0) + (mmsData[key] || 0);
        });
        return acc;
      }, {});

      const calculateRateForType = (
        data: Record<string, Record<string, number>>
      ) => {
        let respondedNumbers = 0;
        let totalDelivered = 0;

        Object.keys(data).forEach((phoneNumber) => {
          const phoneData = data[phoneNumber];

          if (phoneData.delivered > 0) {
            totalDelivered++;
            // Check if the number has any inbound messages that are not opt-outs
            if (phoneData.totalInbound > phoneData.totalOptOut) {
              respondedNumbers++;
            }
          }
        });

        return totalDelivered > 0
          ? (respondedNumbers / totalDelivered) * 100
          : 0;
      };

      const smsResponseRate = calculateRateForType(smsDataByPhoneNumber);
      const mmsResponseRate = calculateRateForType(mmsDataByPhoneNumber);
      const totalResponseRate = calculateRateForType(allDataByPhoneNumber);

      return {
        smsrr: smsResponseRate,
        mmsrr: mmsResponseRate,
        allrr: totalResponseRate,
      };
    };

    const responseRateData = calculateResponseRate();

    setResCardObj({
      SMS: { responseRate: responseRateData.smsrr },
      MMS: { responseRate: responseRateData.mmsrr },
      All: { responseRate: responseRateData.allrr },
    });
  };

  const handleGetData = (params?: any) => {
    dispatch(getAnalyticsSmsV1Thunk(removeEmptyAndNullValues({ ...params })));
    dispatch(getAnalyticsMmsV1Thunk(removeEmptyAndNullValues({ ...params })));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 me-3">Dashboard</h4>

            <div className="d-flex align-items-center">
              <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                Date Range: {dateRangeTemp?.value}
              </span>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropdown-menu-md p-4"
                  style={{ width: "600px" }}
                >
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (
                        dateRangeTemp?.value === "Custom" &&
                        customDateRange.length === 2
                      ) {
                        const startDate = moment(customDateRange[0]).format(
                          "MM-DD-YYYY"
                        );
                        const endDate = moment(customDateRange[1]).format(
                          "MM-DD-YYYY"
                        );
                        setMonth({ startDate, endDate });
                      } else if (dateRangeTemp?.value !== "Custom") {
                        // Use previously selected date range values on submit
                        let startDate = "";
                        let endDate = moment()
                          .endOf("day")
                          .format("MM-DD-YYYY");

                        switch (dateRangeTemp?.value) {
                          case "24 hours":
                            startDate = moment()
                              .subtract(1, "days")
                              .startOf("day")
                              .format("MM-DD-YYYY");
                            break;
                          case "7 days":
                            startDate = moment()
                              .subtract(7, "days")
                              .startOf("day")
                              .format("MM-DD-YYYY");
                            break;
                          case "30 days":
                            startDate = moment()
                              .subtract(30, "days")
                              .startOf("day")
                              .format("MM-DD-YYYY");
                            break;
                          case "120 days":
                            startDate = moment()
                              .subtract(120, "days")
                              .startOf("day")
                              .format("MM-DD-YYYY");
                            break;
                          default:
                            break;
                        }

                        setMonth({ startDate, endDate });
                      }
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label>Date Range</Form.Label>
                        <Select
                          styles={customSelectStyles}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            setSelectedRange(e.label);

                            if (e?.value === "Custom") {
                              setCustomDateRange([null, null]);
                            }
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>

                      {dateRangeTemp?.value === "Custom" && (
                        <Col sm={6} className="mb-3">
                          <Form.Label>Select Date Range</Form.Label>
                          <Flatpickr
                            styles={customSelectStyles}
                            theme={customSelectTheme}
                            className="form-control flatpickr-custom"
                            name="dateRange"
                            placeholder="Select date range"
                            options={{
                              mode: "range",
                              dateFormat: "m-d-Y",
                            }}
                            value={customDateRange}
                            onChange={(dates: any) => setCustomDateRange(dates)}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          disabled={loading}
                          onClick={() => {
                            setCustomDateRange([null, null]);
                          }}
                        >
                          Clear
                        </Button>
                        <Button className="btn btn-primary" type="submit">
                          {loading && <Spinner size="sm" animation="border" />}{" "}
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Widgets month={month} selectedRange={selectedRange} />
          <UsageSummary />
          <Row>
            {/* <Revenue /> */}
            <LastDays />
            <DeliveryRate />
          </Row>
          {/* <Row> */}
          {/* <Events /> */}
          {/* <EmailSent /> */}
          {/* </Row> */}
          <Row>
            {/* <UsersActivity /> */}
            {/* <SummaryTable month={month} /> */}
            {/* <SyncStatus /> */}
            {/* <OpenDeals /> */}
            {/* <TasksList /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashbaord;
