import {
  getAllGroupsApi,
  createNewGroupApi,
  updateGroupApi,
  geGroupDetailsApi,
  deleteGroupApi,
  subgroupListApi,
  subgroupDetailsApi,
  createSubgroupApi,
  updateSubgroupApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading4,
  apiLoading3,
  getAllGroupsSuccessful,
  getAllGroupsFailed,
  createGroupSuccessful,
  createGroupFailed,
  updateGroupSuccessful,
  updateGroupFailed,
  findGroupDetailsSuccess,
  findGroupDetailsFailed,
  deleteGroupSuccess,
  deleteGroupFailed,
  subgroupsDataSuccessful,
  subgroupsDataFailed,
  subgroupDetailsSuccessful,
  subgroupDetailsFailed,
  subgroupCreateSuccessful,
  subgroupCreateFailed,
  subgroupUpdateSuccessful,
  subgroupUpdateFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getAllGroupsThunk =
  (pagination: { page: number; recordsPerPage: number }) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await getAllGroupsApi(pagination);
      if (data?.code) {
        dispatch(getAllGroupsFailed(data));
        // toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getAllGroupsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getAllGroupsFailed(error));
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createNewGroupThunk =
  (groupData: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let data: any = await createNewGroupApi({
        ...groupData,
      });
      if (data?.code || data?.errorCode) {
        dispatch(createGroupFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(createGroupSuccessful(data));
        // toast.success("Group created successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(createGroupFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateGroupThunk =
  (groupData: any, groupId: string, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let data: any = await updateGroupApi({
        ...groupData,
        groupId,
      });
      if (data?.code || data?.errorCode) {
        dispatch(updateGroupFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(updateGroupSuccessful(data));
        // toast.success("Group updated successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(updateGroupFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const findGroupDetailThunk = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    let data: any = await geGroupDetailsApi(payload);

    if (data?.code || data?.errorCode) {
      dispatch(findGroupDetailsFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(findGroupDetailsSuccess(data));
      // toast.success(data?.message);
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(findGroupDetailsFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

// delete group
export const deleteGroupThunk = (groupId: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    let data: any = await deleteGroupApi(groupId);

    if (data?.code || data?.errorCode) {
      dispatch(deleteGroupFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(deleteGroupSuccess(data));
      toast.success(data?.message || "Group deleted successfully");
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(deleteGroupFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getSubgroupListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading4());

    try {
      const data: any = await subgroupListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(subgroupsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subgroupsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(subgroupsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSubgroupDetailsThunk =
  (id: string) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await subgroupDetailsApi(id);

      if (data?.code || data?.errorCode) {
        dispatch(subgroupDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subgroupDetailsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(subgroupDetailsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createSubgroupThunk =
  (subgroup: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading4());

      let data: any = await createSubgroupApi({
        ...subgroup,
      });

      if (data?.code || data?.errorCode) {
        dispatch(subgroupCreateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subgroupCreateSuccessful(data));
        toast.success("Subgroup created successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(subgroupCreateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateSubgroupThunk =
  (subgroup: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading4());

      const data: any = await updateSubgroupApi(subgroup);

      if (data?.code || data?.errorCode) {
        dispatch(subgroupUpdateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subgroupUpdateSuccessful(data));
        toast.success("Subgroup updated successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(subgroupUpdateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
