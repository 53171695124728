// import Radiobox from "Common/Radiobox";
import withRouter from "Common/withRouter";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  customSelectTheme,
  // dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
// import Flatpickr from "react-flatpickr";
import { AsyncPaginate } from "react-select-async-paginate";

const NPR3 = ({
  validation,
  subgroupTemp,
  setSubgroupTemp,
  groupDetails,
}: any) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
            <AsyncPaginate
              // isMulti={true}
              isDisabled={!validation.values.groupId}
              // closeMenuOnSelect={false}
              // hideSelectedOptions={false}
              menuPortalTarget={document.body}
              key={validation.values.groupId}
              styles={styleObj(
                validation?.touched?.subGroupId &&
                  validation?.errors?.subGroupId
              )}
              theme={customSelectTheme}
              components={{
                Option: (props: any) => {
                  return (
                    <div
                      ref={props.innerRef}
                      {...props.innerProps}
                      className={`selectItemWithCheckbox ${
                        // subgroupTemp?.some(
                        //   (dt: any) => dt?.sub_group_id === "*"
                        // ) && props.value !== "*"
                        //   ? "disabled"
                        //   : ""
                        ""
                      }`}
                    >
                      {/* <Form.Check
                        type="checkbox"
                        id={"all-events"}
                        label={props.children}
                        checked={
                          props.isSelected ||
                          subgroupTemp?.some(
                            (dt: any) => dt?.sub_group_id === "*"
                          )
                        }
                        readOnly
                      /> */}
                      {props.children}
                    </div>
                  );
                },
              }}
              value={subgroupTemp || []}
              loadOptions={asyncSubgroupList(
                removeEmptyAndNullValues({
                  groupId: groupDetails?.records?.[0]?.group_id,
                }),
                "subGroupId"
              )}
              getOptionValue={(option: any) => option?.sub_group_id}
              getOptionLabel={(option: any) => option?.sub_group_id}
              onChange={(option: any) => {
                setSubgroupTemp([option]);
                validation.setFieldValue("subGroupId", [option?.sub_group_id]);

                // if (option?.some((dt: any) => dt?.sub_group_id === "*")) {
                //   setSubgroupTemp([
                //     { sub_group_name: "All", sub_group_id: "*" },
                //   ]);
                //   validation.setFieldValue("subGroupId", ["All"]);
                // } else {
                //   setSubgroupTemp(option);
                //   validation.setFieldValue(
                //     "subGroupId",
                //     option?.map(
                //       (dt?: { sub_group_id?: string }) => dt?.sub_group_id
                //     )
                //   );
                // }
              }}
              isSearchable={true}
              additional={{
                page: 1,
              }}
            />
            {validation?.touched?.subGroupId &&
            validation?.errors?.subGroupId ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {validation?.errors?.subGroupId}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Form.Label htmlFor="brandId">Brand</Form.Label>
            <AsyncPaginate
              key="brandId"
              styles={styleObj(
                validation?.touched?.brandId && validation?.errors?.brandId
              )}
              theme={customSelectTheme}
              value={
                validation.values.brandId
                  ? { brandId: validation.values.brandId }
                  : null
              }
              loadOptions={asyncBrandList(
                {
                  groupId: groupDetails?.records?.[0]?.group_id,
                  subGroupId:
                    validation.values.subGroupId?.length > 0
                      ? validation.values.subGroupId?.[0]?.sub_group_id
                      : "",
                },
                "brandId"
              )}
              getOptionValue={(option: any) => option?.brandId}
              getOptionLabel={(option: any) => option?.brandId}
              onChange={(option: any) => {
                validation.setFieldValue("brandId", option.brandId);
                validation.setFieldValue("campaignId", "");
              }}
              isSearchable={true}
              additional={{
                page: 1,
              }}
            />
            {validation?.touched?.brandId && validation?.errors?.brandId ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {validation?.errors?.brandId}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Form.Label htmlFor="campaignId">Campaign</Form.Label>
            <AsyncPaginate
              key={validation.values.brandId}
              styles={styleObj(
                validation?.touched?.campaignId &&
                  validation?.errors?.campaignId
              )}
              theme={customSelectTheme}
              value={
                validation.values.campaignId
                  ? { campaignId: validation.values.campaignId }
                  : null
              }
              loadOptions={asyncCampaignList(
                removeEmptyAndNullValues({
                  brandId: validation.values.brandId,
                }),
                "campaignId"
              )}
              isDisabled={!validation.values.brandId}
              getOptionValue={(option: any) => option?.campaignId}
              getOptionLabel={(option: any) => option?.campaignId}
              onChange={(option: any) => {
                validation.setFieldValue("campaignId", option.campaignId);
              }}
              isSearchable={true}
              additional={{
                page: 1,
              }}
            />
            {validation?.touched?.campaignId &&
            validation?.errors?.campaignId ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {validation?.errors?.campaignId}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Col>
        {/* <Col lg={12}>
          <div className="mb-3">
            <Form.Label htmlFor="searchAddress">
              Search for an address
            </Form.Label>
            <Form.Control
              type="text"
              id="searchAddress"
              name="searchAddress"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation?.values?.searchAddress || ""}
              isInvalid={
                validation?.touched?.searchAddress &&
                validation?.errors?.searchAddress
                  ? true
                  : false
              }
            />
            {validation.touched?.searchAddress &&
            validation.errors?.searchAddress ? (
              <Form.Control.Feedback type="invalid">
                {validation.errors?.searchAddress}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Col>
        <p className="text-center">-- Or enter it manually --</p> */}
        {/* <Col lg={6}>
          <Form.Label htmlFor="requestedFOCDate">
            When do you want this to activate? (Requested FOC Date)
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.requestedFOCDate &&
          validation.errors.requestedFOCDate ? (
            <p className="text-primary">{validation.errors.requestedFOCDate}</p>
          ) : null}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="Earliest available date"
                id="Earliest available date"
                name="requestedFOCDate"
                value="Earliest available date"
                checked={
                  validation?.values?.requestedFOCDate ===
                  "Earliest available date"
                }
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("requestedFOCDate", e.target.value)
                }
              />
            </div>
            <div>
              <Radiobox
                label="Specified date"
                id="Specified date"
                name="requestedFOCDate"
                value="Specified date"
                checked={
                  validation?.values?.requestedFOCDate === "Specified date"
                }
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("requestedFOCDate", e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={6}>
          {validation?.values?.requestedFOCDate === "Specified date" && (
            <div className="mb-3">
              <Form.Label>
                Activation Date <span className="text-primary">*</span>
              </Form.Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "m-d-Y",
                }}
                value={validation?.values?.activationDate}
                onChange={([date]: any) => {
                  validation.setFieldValue(
                    "activationDate", dateFormat(date)
                  );
                }}
              />
            </div>
          )}
        </Col>
        <Col lg={6}>
          <Form.Label htmlFor="howToActivate">
            How to activate?
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.howToActivate &&
          validation.errors.howToActivate ? (
            <p className="text-primary">{validation.errors.howToActivate}</p>
          ) : null}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="Automatically"
                id="Automatically"
                name="howToActivate"
                value="Automatically"
                checked={validation?.values?.howToActivate === "Automatically"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("howToActivate", e.target.value)
                }
              />
            </div>
            <div>
              <Radiobox
                label="Manually"
                id="Manually"
                name="howToActivate"
                value="Manually"
                checked={validation?.values?.howToActivate === "Manually"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("howToActivate", e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <Form.Label htmlFor="portNoAssociatedWithAcc">
            Would you like to port more numbers associated with this account?{" "}
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.portNoAssociatedWithAcc &&
          validation.errors.portNoAssociatedWithAcc ? (
            <p className="text-primary">
              {validation.errors.portNoAssociatedWithAcc}
            </p>
          ) : null}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="Yes"
                id="Yes"
                name="portNoAssociatedWithAcc"
                value="Yes"
                checked={validation?.values?.portNoAssociatedWithAcc === "Yes"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue(
                    "portNoAssociatedWithAcc",
                    e.target.value
                  )
                }
              />
            </div>
            <div>
              <Radiobox
                label="No"
                id="No"
                name="portNoAssociatedWithAcc"
                value="No"
                checked={validation?.values?.portNoAssociatedWithAcc === "No"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue(
                    "portNoAssociatedWithAcc",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </Col> */}
        {/* <h5>FastPort</h5>
        <p>This port is FastPort eligible.</p>
        <Col lg={12}>
          <Form.Label htmlFor="fastPort">
            On the confirmed activation date:
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.fastPort && validation.errors.fastPort ? (
            <p className="text-primary">{validation.errors.fastPort}</p>
          ) : null}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="I will activate the numbers myself (On Demand)"
                id="Yes2"
                name="fastPort"
                value="Yes"
                checked={validation?.values?.fastPort === "Yes"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("fastPort", e.target.value)
                }
              />
            </div>
            <div>
              <Radiobox
                label="Signalhouse will activate the numbers automatically at the specified time (Schedule)"
                id="No2"
                name="fastPort"
                value="No"
                checked={validation?.values?.fastPort === "No"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("fastPort", e.target.value)
                }
              />
            </div>
          </div>
        </Col> */}
        <Col lg={12}>
          <div>
            <Form.Label htmlFor="comments">Comments</Form.Label>
            <textarea
              id="comments"
              name="comments"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation?.values?.comments || ""}
              className={`form-control ${
                validation.touched?.comments && validation.errors?.comments
                  ? "is-invalid"
                  : ""
              }`}
              rows={3}
            />
            {validation.touched?.comments && validation.errors?.comments ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {validation.errors?.comments}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(NPR3);
