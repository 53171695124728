import { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserLogoutThunk,
  getAllGroupsThunk,
  getAuthUserThunk,
  getUserStepsThunk,
  getWebNotificationsListThunk,
  logoutUserThunk,
} from "slices/thunk";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const AuthProtected = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (state: any) => state.AuthUser,
    (login, authUser) => ({
      loginData: login.loginData,
      user: authUser.authUser,
      authUserPermissions: authUser.authUserPermissions,
    })
  );
  const { loginData, user, authUserPermissions } = useSelector(
    selectAccountAndLogin
  );

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    if (obj.apiKey && obj.authToken) {
      setAuthorization(obj.apiKey, obj.authToken);
      dispatch(getAuthUserThunk());
      dispatch(getWebNotificationsListThunk());
      dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }));
    } else if (!obj.apiKey && !obj.authToken) {
      dispatch(logoutUserThunk());
      dispatch(authUserLogoutThunk());
    }
  }, [loginData]);

  useEffect(() => {
    if (user) dispatch(getUserStepsThunk(user?.completedSteps));
  }, [user]);

  const obj = loginData && JSON.parse(loginData);
  if (!loginData || (!obj?.apiKey && !obj?.authToken)) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (!user || Object.keys(user).length === 0) {
    return "";
  }
  const hasPermission = (key: string) => {
    if (!authUserPermissions || !key) return false;
    const permission = key
      .split(".")
      .reduce((obj, k) => obj && obj[k], authUserPermissions);
    return permission && permission.read === true;
  };

  if (
    Object.keys(authUserPermissions).length > 0 &&
    !hasPermission(props.moduleKey)
  ) {
    return (
      <div
        className="page-title-box d-flex flex-column align-items-center justify-content-center"
        style={{
          height: "100vh",
          fontFamily: '"Poppins", sans-serif',
        }}
      >
        <h4
          className="mb-3 d-flex align-items-center"
          style={{
            fontSize: "calc(0.8125rem* 1.5)",
            fontWeight: 600,
            color: "#0f1114",
          }}
        >
          Permission Denied
        </h4>
        <p>You don't have permission to access this page.</p>
        <Button
          variant="secondary"
          className="d-flex align-items-center"
          onClick={() => navigate(-1)}
          style={{ fontSize: "0.8125rem" }}
        >
          <i className="bx bx-arrow-back me-1"></i> Go Back
        </Button>
      </div>
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <>
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
