import React, { useEffect, useState, useMemo } from "react";
import { Accordion, Card, Col, Container, Row, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSubgroupDetailsThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { Link } from "react-router-dom";

const SubGroupDetails = (props: any) => {
  document.title = "Signal House Portal Sub Group Details";

  const { subGroupId } = useParams();
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading3: groups.loading3,
      subgroupDetails: groups.subgroupDetails,
      error: groups.error,
    })
  );

  const { loading3, subgroupDetails } = useSelector(selectAccount);
  const [activeAcc, setActiveAcc] = useState<string>("");
  const [activeAcc2, setActiveAcc2] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    subGroupId && dispatch(getSubgroupDetailsThunk(subGroupId));
  }, [subGroupId]);

  const filterData = (data: any, term: string) => {
    if (!term) return data;

    return data.filter((brand: any) => {
      const brandMatch =
        brand.brandId?.toLowerCase().includes(term.toLowerCase()) || false;
      const campaignMatch =
        brand.campaigns?.some((campaign: any) =>
          campaign.campaignId?.toLowerCase().includes(term.toLowerCase())
        ) || false;
      const phoneNumberMatch =
        brand.campaigns?.some(
          (campaign: any) =>
            campaign.ownNumbers?.some((number: any) =>
              number.phoneNumber?.includes(term)
            ) ||
            campaign.releasedNumbers?.some((number: any) =>
              number.phoneNumber?.includes(term)
            )
        ) || false;
      const portingIdMatch =
        brand.campaigns?.some((campaign: any) =>
          campaign.portingRequestIds?.some((porting: any) =>
            porting.portingId?.toLowerCase().includes(term.toLowerCase())
          )
        ) || false;

      return brandMatch || campaignMatch || phoneNumberMatch || portingIdMatch;
    });
  };

  const filteredBrands = useMemo(() => {
    return filterData(subgroupDetails?.data?.brands || [], searchTerm);
  }, [subgroupDetails?.data?.brands, searchTerm]);

  // Update activeAcc and activeAcc2 when filteredBrands changes
  useEffect(() => {
    if (searchTerm === "") {
      // Close all accordions when search string is removed
      setActiveAcc("");
      setActiveAcc2("");
    } else if (filteredBrands.length > 0) {
      const firstBrand = filteredBrands[0];
      setActiveAcc(firstBrand.brandId || "");

      // Check if we need to open a specific campaign
      if (firstBrand.campaigns && firstBrand.campaigns.length > 0) {
        const matchingCampaign = firstBrand.campaigns.find(
          (campaign: any) =>
            campaign.campaignId
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            campaign.ownNumbers?.some((number: any) =>
              number.phoneNumber?.includes(searchTerm)
            ) ||
            campaign.releasedNumbers?.some((number: any) =>
              number.phoneNumber?.includes(searchTerm)
            ) ||
            campaign.portingRequestIds?.some((porting: any) =>
              porting.portingId
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
        );

        if (matchingCampaign) {
          setActiveAcc2(matchingCampaign.campaignId || "");
        } else {
          setActiveAcc2("");
        }
      } else {
        setActiveAcc2("");
      }
    } else {
      setActiveAcc("");
      setActiveAcc2("");
    }
  }, [filteredBrands, searchTerm]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Sub Group"
            style={{ width: "100%", maxWidth: 520 }}
            isFilter={true}
            filterButton={
              <Form.Control
                type="text"
                placeholder="Search by Brand ID, Campaign ID, Phone Number, or Porting ID"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            }
            isBack={true}
            backClick={() => props.router?.navigate(-1)}
          />
          <div className="position-relative wrapper">
            {loading3 ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Card>
                <Card.Body className="">
                  {subgroupDetails?.data?.subGroupData?.length > 0 ? (
                    <>
                      <div className="mb-1 ">
                        <h5>Group details</h5>
                      </div>
                      <Col lg={12} className="mt-3">
                        <Row className="border bg-light-gray rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Id:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.subGroupData?.[0]
                                ?.group_id || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Id:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.subGroupData?.[0]
                                ?.sub_group_id || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Name:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.subGroupData?.[0]
                                ?.sub_group_name || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Description:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.subGroupData?.[0]
                                ?.sub_group_description || "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Status:</h6>
                            {subgroupDetails?.data?.subGroupData?.[0]
                              ?.status === "Active" ? (
                              <span className="badge bg-secondary-subtle text-secondary fs-sm">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-primary-subtle text-primary fs-sm">
                                Inactive
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <p className="m-0">No Record Found</p>
                  )}
                </Card.Body>

                <Card.Body>
                  <h5 className="mb-3">Sub Group Brands</h5>
                  {filteredBrands.length > 0 ? (
                    filteredBrands.map((dt1: any, i1: number) =>
                      dt1?.campaigns?.length > 0 ? (
                        <Accordion
                          alwaysOpen={false}
                          activeKey={activeAcc}
                          className="accordion-flush summary-accordian"
                          key={i1}
                        >
                          <Accordion.Item
                            eventKey={dt1?.brandId || i1.toString()}
                          >
                            <Accordion.Header
                              id={dt1?.brandId || i1.toString()}
                              style={{
                                borderBottom: "1px solid rgba(0,0,0,.12)",
                              }}
                              className="lefticon-accordion"
                              onClick={() => {
                                if (
                                  activeAcc === dt1?.brandId ||
                                  activeAcc === i1.toString()
                                ) {
                                  setActiveAcc("");
                                  setActiveAcc2("");
                                } else {
                                  setActiveAcc(dt1?.brandId || i1.toString());
                                  setActiveAcc2("");
                                }
                              }}
                            >
                              <div>
                                <Link to={`/brands/${dt1.brandId}`}>
                                  {dt1?.brandId}{" "}
                                  <span className="text-body">
                                    - {dt1?.brandName || "-"}
                                  </span>
                                </Link>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <h5 className="mb-2">Sub Group Campaigns</h5>
                              {dt1?.campaigns?.length > 0 ? (
                                dt1?.campaigns.map((dt2: any, i2: number) => (
                                  <Accordion
                                    alwaysOpen={false}
                                    activeKey={activeAcc2}
                                    className="accordion-flush summary-accordian"
                                    key={i2}
                                  >
                                    <Accordion.Item
                                      eventKey={
                                        dt2?.campaignId || i2.toString()
                                      }
                                    >
                                      <Accordion.Header
                                        id={dt2?.campaignId || i2.toString()}
                                        style={{
                                          borderBottom:
                                            "1px solid rgba(0,0,0,.12)",
                                        }}
                                        className="lefticon-accordion"
                                        onClick={() => {
                                          if (
                                            activeAcc2 === dt2?.campaignId ||
                                            activeAcc2 === i2.toString()
                                          ) {
                                            setActiveAcc2("");
                                          } else {
                                            setActiveAcc2(
                                              dt2?.campaignId || i2.toString()
                                            );
                                          }
                                        }}
                                      >
                                        <div>
                                          <Link
                                            to={
                                              dt2?.campaignType === "own"
                                                ? `/campaigns/${dt2.campaignId}`
                                                : `/campaigns-partner/${dt2.campaignId}`
                                            }
                                          >
                                            {dt2?.campaignId}
                                          </Link>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <h5 className="mb-3">
                                          Sub Group Numbers
                                        </h5>
                                        {dt2?.ownNumbers?.length > 0 ? (
                                          <div className="d-flex flex-wrap">
                                            {dt2?.ownNumbers?.map(
                                              (dt3: any, i3: number) => (
                                                <div
                                                  key={i3}
                                                  className="p-2 flex-grow-1"
                                                  style={{
                                                    minWidth: "200px",
                                                    maxWidth: "100%",
                                                    backgroundColor:
                                                      "#fbfcff6e",
                                                    border:
                                                      "0.5px solid #dde1ef",
                                                  }}
                                                >
                                                  <Link
                                                    to={`/mynumbers/${dt3?.phoneNumber}/${dt3?.signalHouseSID}`}
                                                  >
                                                    {dt3?.phoneNumber}
                                                  </Link>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <p className="mt-3">
                                            No Record Found
                                          </p>
                                        )}

                                        <h5 className="mt-4 mb-3">
                                          Sub Group Released Numbers
                                        </h5>
                                        {dt2?.releasedNumbers?.length > 0 ? (
                                          <div className="d-flex flex-wrap">
                                            {dt2?.releasedNumbers?.map(
                                              (dt3: any, i3: number) => (
                                                <div
                                                  key={i3}
                                                  className="p-2 flex-grow-1"
                                                  style={{
                                                    minWidth: "200px",
                                                    maxWidth: "100%",
                                                    backgroundColor:
                                                      "#fbfcff6e",
                                                    border:
                                                      "0.5px solid #dde1ef",
                                                  }}
                                                >
                                                  <Link
                                                    to={`/mynumbers/${dt3?.phoneNumber}/${dt3?.signalHouseSID}`}
                                                  >
                                                    {dt3?.phoneNumber}
                                                  </Link>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <p className="mt-3">
                                            No Record Found
                                          </p>
                                        )}

                                        <h5 className="mt-4 mb-3">
                                          Sub Group Porting Request IDs
                                        </h5>
                                        {dt2?.portingRequestIds?.length > 0 ? (
                                          <div className="d-flex flex-wrap">
                                            {dt2?.portingRequestIds?.map(
                                              (dt3: any, i3: number) => (
                                                <div
                                                  key={i3}
                                                  className="p-2 flex-grow-1"
                                                  style={{
                                                    minWidth: "200px",
                                                    maxWidth: "100%",
                                                    backgroundColor:
                                                      "#fbfcff6e",
                                                    border:
                                                      "0.5px solid #dde1ef",
                                                  }}
                                                >
                                                  <Link
                                                    to={`/porting-status/${dt3?.portingId}`}
                                                  >
                                                    {dt3?.portingId}
                                                  </Link>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <p className="mt-3">
                                            No Record Found
                                          </p>
                                        )}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                ))
                              ) : (
                                <p className="mt-3">No Record Found</p>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <div
                          className="accordion-flush summary-accordian sub-group-brands-accordian accordion"
                          key={i1}
                        >
                          <div className="accordion-item">
                            <h2
                              id={dt1?.brandId || i1}
                              className="lefticon-accordion accordion-header"
                              style={{
                                borderBottom: "1px solid rgba(0,0,0,.12)",
                              }}
                            >
                              <button
                                type="button"
                                aria-expanded="false"
                                className="accordion-button collapsed"
                              >
                                <div>
                                  <Link to={`/brands/${dt1.brandId}`}>
                                    {dt1?.brandId || "N/A"}{" "}
                                    <span className="text-body">
                                      - {dt1?.brandName || "-"}
                                    </span>
                                  </Link>
                                </div>
                              </button>
                            </h2>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <p className="mt-3">No matching records found</p>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubGroupDetails);
