import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Nav,
  Tab,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getCampaignListThunk,
  getCampaignPartnerListThunk,
  getConnectivityPartnersListThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  removeEmptyAndNullValues,
  dateFormat,
  customSelectTheme,
  styleObj,
  asyncBrandList,
  asyncSubgroupList,
  asyncCampaignList,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Tooltip from "rc-tooltip";

const CampaignsList = (props: any) => {
  document.title = "Signal House Portal Campaigns";
  const dispatch = useDispatch<any>();

  const [currPage1, setCurrPage1] = useState(1);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [searchParams] = useSearchParams();
  const statusFromUrl = searchParams.get("status") || "";

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (campaigns, enums, groups) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading,
      campaignsData: campaigns.campaignsData,
      campaignsPartnerData: campaigns.campaignsPartnerData,
      error: campaigns.error,
      connectivityPartnersObj: enums.connectivityPartnersObj,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    loading2,
    campaignsData,
    campaignsPartnerData,
    connectivityPartnersObj,
    groupDetails,
  } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      brandName: [],
      campaignId: [],
      createdAt: "",
      startDate: "",
      endDate: "",
      status: statusFromUrl,
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          values.brandName.length > 0
            ? values.brandName?.map((dt: any) => dt.displayName)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        createdAt: "",
        startDate: values.startDate
          ? moment(values.startDate, "MM-DD-YYYY").startOf("day").toISOString()
          : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      });
      handleGetData1(currPage1, rowsPerPage1, temp);
      handleGetData2(currPage2, rowsPerPage2, temp);
    },
  });

  const columns1 = [
    {
      name: <span className="font-weight-bold fs-sm">Registered On</span>,
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      },
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      selector: (row: { campaignId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <b data-tag="allowRowEvents">{row.campaignId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.campaignId.localeCompare(b.campaignId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      selector: (row: { brandId: string }) => (
        <span
          data-tag="allowRowEvents"
          className="text-secondary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.router.navigate(`/brands/${row.brandId}`);
          }}
        >
          <b data-tag="allowRowEvents">{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.brandId.localeCompare(b.brandId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand Name</span>,
      selector: (row: { brandName: string }) => capitalizeString(row.brandName),
      sortable: true,
      sortFunction: (a: any, b: any) => a.brandName.localeCompare(b.brandName),
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.usecase.localeCompare(b.usecase),
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      sortable: true,
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "active":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "rejected":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      name: <span className="font-weight-bold fs-sm">DCA Election</span>,
      sortable: true,
      selector: (cell: {
        dcaElectedStatus: { description?: string; mnOs?: any };
      }) => {
        const status = cell?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );

        const statusLabel = () => {
          switch (status?.dcaComplete?.toLowerCase()) {
            case "pending":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge border"
                  style={{
                    borderColor: "#6c757d",
                    background: "#d3d3d3e0",
                    color: "#6c757d",
                  }}
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            case "rejected":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-primary border border-primary text-white"
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            case "approved":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-secondary border border-secondary text-white"
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            default:
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-body-secondary border border-secondary text-secondary"
                >
                  {capitalizeString(status?.dcaComplete || "-")}
                </span>
              );
          }
        };

        return (
          <>
            {statusLabel()}
            {cell?.dcaElectedStatus?.description && (
              <Tooltip
                placement="bottom"
                overlay={cell?.dcaElectedStatus?.description}
              >
                <i
                  className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                  style={{ bottom: 15 }}
                />
              </Tooltip>
            )}
          </>
        );
      },
      sortFunction: (a: any, b: any) => {
        const statusA = a?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );
        const statusB = b?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );
        return statusA?.dcaComplete
          ?.toLowerCase()
          .localeCompare(statusB?.dcaComplete);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { campaignId: string; campaignType: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/campaigns/${cell.campaignId}`)
              }
            />
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      name: <span className="font-weight-bold fs-sm">Shared With Me On</span>,
      selector: (row: { sharedWithMe: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedWithMe, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.sharedWithMe).getTime() -
          new Date(b.sharedWithMe).getTime()
        );
      },
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Shared To On</span>,
      selector: (row: { sharedToOn: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedToOn, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.sharedToOn).getTime() - new Date(b.sharedToOn).getTime()
        );
      },
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      selector: (row: { campaignId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <b data-tag="allowRowEvents">{row.campaignId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.campaignId.localeCompare(b.campaignId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      selector: (row: { brandId: string }) => (
        <span
          data-tag="allowRowEvents"
          className="text-secondary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.router.navigate(`/brands/${row.brandId}`);
          }}
        >
          <b data-tag="allowRowEvents">{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.brandId.localeCompare(b.brandId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.usecase.localeCompare(b.usecase),
    },
    {
      name: <span className="font-weight-bold fs-sm">Downstream CNP</span>,
      selector: (row: { downstreamCnpId: string }) =>
        connectivityPartnersObj[row.downstreamCnpId] ||
        row.downstreamCnpId ||
        "-",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aValue =
          connectivityPartnersObj[a.downstreamCnpId] ||
          a.downstreamCnpId ||
          "-";
        const bValue =
          connectivityPartnersObj[b.downstreamCnpId] ||
          b.downstreamCnpId ||
          "-";
        return aValue.localeCompare(bValue);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Upstream CNP</span>,
      selector: (row: { upstreamCnpId: string }) =>
        connectivityPartnersObj[row.upstreamCnpId] || row.upstreamCnpId || "-",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aValue =
          connectivityPartnersObj[a.upstreamCnpId] || a.upstreamCnpId || "-";
        const bValue =
          connectivityPartnersObj[b.upstreamCnpId] || b.upstreamCnpId || "-";
        return aValue.localeCompare(bValue);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      sortable: true,
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "active":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "rejected":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      name: <span className="font-weight-bold fs-sm">DCA Election</span>,
      sortable: true,
      selector: (cell: {
        dcaElectedStatus: { description?: string; mnOs?: any };
      }) => {
        const status = cell?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );

        const statusLabel = () => {
          switch (status?.dcaComplete?.toLowerCase()) {
            case "pending":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge border"
                  style={{
                    borderColor: "#6c757d",
                    background: "#d3d3d3e0",
                    color: "#6c757d",
                  }}
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            case "rejected":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-primary border border-primary text-white"
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            case "approved":
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-secondary border border-secondary text-white"
                >
                  {capitalizeString(status?.dcaComplete)}
                </span>
              );
            default:
              return (
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-body-secondary border border-secondary text-secondary"
                >
                  {capitalizeString(status?.dcaComplete || "-")}
                </span>
              );
          }
        };

        return (
          <>
            {statusLabel()}
            {cell?.dcaElectedStatus?.description && (
              <Tooltip
                placement="bottom"
                overlay={cell?.dcaElectedStatus?.description}
              >
                <i
                  className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                  style={{ bottom: 15 }}
                />
              </Tooltip>
            )}
          </>
        );
      },
      sortFunction: (a: any, b: any) => {
        const statusA = a?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );
        const statusB = b?.dcaElectedStatus?.mnOs.find(
          (dt: any) => dt.mnoName === "T-Mobile"
        );
        return statusA?.dcaComplete
          ?.toLowerCase()
          .localeCompare(statusB?.dcaComplete);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { campaignId: string; campaignType: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/campaigns-partner/${cell.campaignId}`)
              }
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetData1(currPage1, rowsPerPage1);
    handleGetData2(currPage2, rowsPerPage2);
    dispatch(getConnectivityPartnersListThunk());
  }, []);

  const handleGetData1 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignListThunk(
        page,
        perPage,
        {
          ...otherParams,
          campaignType: "own",
          ...(statusFromUrl && { status: statusFromUrl }),
        },
        true
      )
    );
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignPartnerListThunk(
        page,
        perPage,
        {
          ...otherParams,
          campaignType: "partner",
        },
        true
      )
    );
  };

  const handleRowClick1 = (row: { campaignId: string }) => {
    props.router.navigate(`/campaigns/${row.campaignId}`);
  };

  const handleRowClick2 = (row: { campaignId: string }) => {
    props.router.navigate(`/campaigns-partner/${row.campaignId}`);
  };

  const handlePageChange1 = (page: number) => {
    setCurrPage1(page);
    handleGetData1(
      page,
      rowsPerPage1,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(
      page,
      rowsPerPage2,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange1 = (rows: number) => {
    setCurrPage1(1);
    setRowsPerPage1(rows);
    handleGetData1(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Campaigns"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <AsyncPaginate
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) => option?.sub_group_id}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("brandName", []);
                              validation.setFieldValue("campaignId", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand ID</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandName"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandName &&
                              validation?.errors?.brandName
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandName?.length > 0
                              ? validation.values.brandName
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "brandName"
                          )}
                          getOptionValue={(option: any) => option?.displayName}
                          getOptionLabel={(option: any) => option?.displayName}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandName", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">
                          Campaign ID
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        displayName:
                                          dt?.displayName ||
                                          dt?.brandName ||
                                          "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        displayName:
                                          dt?.displayName ||
                                          dt?.brandName ||
                                          "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );

                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName:
                                    dt?.displayName || dt?.brandName || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="createdAt">Created At</Form.Label>
                        <Flatpickr
                          className="form-control"
                          name="createdAt"
                          placeholder="Created At"
                          options={{
                            enableTime: false,
                            onOpen: () => {
                              setDatePickerModal(true);
                            },
                            onClose: () => {
                              setDatePickerModal(false);
                            },
                            altFormat: "F j, Y",
                            dateFormat: "m-d-Y",
                            mode: "range",
                            onChange: (
                              value: any,
                              dateStr: string,
                              instance: any
                            ) => {
                              validation.setFieldValue(
                                "createdAt",
                                dateStr || ""
                              );
                              value?.[0] &&
                                validation.setFieldValue(
                                  "startDate",
                                  moment(value?.[0]).format("MM-DD-YYYY")
                                );
                              value?.[1] &&
                                validation.setFieldValue(
                                  "endDate",
                                  moment(value?.[1]).format("MM-DD-YYYY")
                                );
                            },
                          }}
                          value={validation.values.createdAt || ""}
                        />
                      </Col>
                      {/* <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="status">Campaign Status</Form.Label>
                        <AsyncPaginate
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.status &&
                              validation?.errors?.status
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.status
                              ? { value: validation.values.status, label: validation.values.status }
                              : null
                          }
                          options={[
                            { value: 'ACTIVE', label: 'Active' },
                            { value: 'PENDING', label: 'Pending' },
                            { value: 'EXPIRED', label: 'Expired' }
                          ]}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "status",
                                option.value
                              );
                            } else {
                              validation.setFieldValue("status", "");
                            }
                          }}
                          isSearchable={true}
                        />
                      </Col> */}
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDatePickerModal(false);
                          handleGetData1(currPage1, rowsPerPage1);
                          handleGetData2(currPage2, rowsPerPage2);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new campaign"
            createLink="/campaigns/create"
          />
          <Tab.Container defaultActiveKey="campaign">
            <Nav
              as="ul"
              variant="tabs"
              className="nav-tabs-custom nav-primary mb-3"
            >
              <Nav.Item as="li">
                <Nav.Link eventKey="campaign">Campaigns</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="partner">CNP Campaigns</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="campaign">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsData?.records)
                              ? campaignsData?.records
                              : []
                          }
                          columns={columns1}
                          handleRowClick={handleRowClick1}
                          handlePageChange={handlePageChange1}
                          handleRowsPerPageChange={handleRowsPerPageChange1}
                          currPage={currPage1}
                          rowsPerPage={rowsPerPage1}
                          totalRecords={campaignsData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="partner">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading2 ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsPartnerData?.records)
                              ? campaignsPartnerData?.records
                              : []
                          }
                          columns={columns2}
                          handleRowClick={handleRowClick2}
                          handlePageChange={handlePageChange2}
                          handleRowsPerPageChange={handleRowsPerPageChange2}
                          currPage={currPage2}
                          rowsPerPage={rowsPerPage2}
                          totalRecords={campaignsPartnerData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignsList);
