import withRouter from "Common/withRouter";
import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
// import { customSelectTheme } from "helpers/common";
// import Select from "react-select";
import Loader from "assets/images/spinner-white.svg";
import SignatureCanvas from "react-signature-canvas";
import Radiobox from "Common/Radiobox";

const base64ToFile = (base64String?: any, filename?: any) => {
  let arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const NPR4 = ({ validation, handlePdfGenerate }: any) => {
  const sigCanvas = useRef<SignatureCanvas>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isTab, setIsTablet] = useState(false);
  const [signature, handleSignature] = useState("Draw Signature");

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    if (window.outerWidth < 993) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  };

  const clear = () => {
    sigCanvas?.current?.clear();
  };

  const save = () => {
    const dataURL =
      sigCanvas?.current?.getTrimmedCanvas()?.toDataURL("image/png") || "";
    const file: any = base64ToFile(dataURL, "signature.png");

    if (typeof file === "object" && file !== null) {
      setIsLoading(true);
      validation.setFieldValue("signature", file);
      setTimeout(() => handlePdfGenerate(() => setIsLoading(false)), 100);
    }
  };

  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(err.message || "Something went wrong");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      if (typeof files[0] === "object" && files[0] !== null) {
        setIsLoading(true);
        validation.setFieldValue("signature", files?.[0]);
        setTimeout(() => handlePdfGenerate(() => setIsLoading(false)), 100);
      }
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="d-flex flex-column">
          <h5>
            Draw or Upload Signature Below{" "}
            <span className="text-primary">*</span>
          </h5>
          {validation.touched.letterOfAuthorization &&
          validation.errors.letterOfAuthorization ? (
            <p className="text-primary mb-2">
              {validation.errors.letterOfAuthorization}
            </p>
          ) : null}
          {validation?.values?.letterOfAuthorization && (
            <ul className="vstack gap-2 list-unstyled my-3">
              <li>
                <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0 ronded">
                      <div className="avatar-title bg-body-secondary text-body">
                        <i className="ri ri-link-m fs-lg"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0 text-break">
                        {validation?.values?.letterOfAuthorization}
                      </h6>
                    </div>
                    <div className="flex-shrink-0 text-muted">
                      <i
                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                        onClick={() => {
                          validation.setFieldValue("letterOfAuthorization", "");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="Draw Signature"
                id="Draw Signature"
                name="signatureType1"
                value="Draw Signature"
                checked={signature === "Draw Signature"}
                onChange={(e: { target: { value: string } }) =>
                  handleSignature(e.target.value)
                }
              />
            </div>
            <div>
              <Radiobox
                label="Upload Signature"
                id="Upload Signature"
                name="signatureType1"
                value="Upload Signature"
                checked={signature === "Upload Signature"}
                onChange={(e: { target: { value: string } }) =>
                  handleSignature(e.target.value)
                }
              />
            </div>
          </div>
          {signature !== "Upload Signature" ? (
            <div className="mb-3 mb-lg-0">
              <Form.Label htmlFor="Signature">Draw Signature Here</Form.Label>
              <div className="position-relative">
                {isLoading ? (
                  <div className="d-flex align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                    <img
                      src={Loader}
                      alt="loading"
                      className="d-flex mx-auto img-thumbnail border-0 bg-transparent"
                    />
                  </div>
                ) : null}
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    height: isTab ? 400 : 200,
                    className: "rounded border w-100",
                  }}
                />
              </div>
              <div className="hstack gap-2 justify-content-end mt-2">
                <Button className="btn btn-outline-primary" onClick={clear}>
                  Clear
                </Button>
                <Button variant="primary" onClick={save}>
                  Save Signature
                </Button>
              </div>
            </div>
          ) : (
            <div className="mb-3 mb-lg-0">
              <Form.Label htmlFor="signature">Upload Signature</Form.Label>
              <Dropzone
                onDrop={(acceptedFiles: any, fileRejections: any) => {
                  handleAcceptedFiles(acceptedFiles, fileRejections);
                }}
                multiple={false}
                accept={{
                  "image/*": [".jpeg", ".jpg", ".png"],
                }}
              >
                {({ getRootProps }: any) => (
                  <div
                    className="position-relative dropzone dz-clickable text-center border rounded"
                    style={{ minHeight: 200 }}
                  >
                    {isLoading ? (
                      <div className="position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                        <img
                          src={Loader}
                          alt="loading"
                          className="img-thumbnail border-0 bg-transparent"
                        />
                      </div>
                    ) : null}
                    <div
                      className="dz-message needsclick cursor-pointer"
                      {...getRootProps()}
                    >
                      <div className="mb-3">
                        <i className="ri-image-fill text-muted fs-8xl" />
                      </div>
                      <p className="fs-lg">
                        Drop a file to upload or click to upload
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )}
        </Col>

        {validation?.values?.portingService === "Entire Service" && (
          <Col lg={6} className="d-flex flex-column">
            <h5>Add Billing Statement Below</h5>
            {validation?.values?.billingStatement && (
              <ul className="vstack gap-2 list-unstyled my-3">
                <li>
                  <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                    <div className="d-flex align-items-center gap-2">
                      <div className="avatar-xs flex-shrink-0 ronded">
                        <div className="avatar-title bg-body-secondary text-body">
                          <i className="ri ri-link-m fs-lg"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-0 text-break">
                          {validation?.values?.billingStatement}
                        </h6>
                      </div>
                      <div className="flex-shrink-0 text-muted">
                        <i
                          className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                          onClick={() => {
                            validation.setFieldValue("billingStatement", "");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            <div className="mb-3 mb-lg-0">
              <Form.Label htmlFor="signature">
                Upload Billing Statement <span className="text-primary">*</span>
              </Form.Label>
              {validation.errors.billingStatement ? (
                <p className="text-primary">
                  {validation.errors.billingStatement}
                </p>
              ) : null}
              <Dropzone
                onDrop={(acceptedFiles: any, fileRejections: any) => {
                  // handleAcceptedFiles2(acceptedFiles, fileRejections);
                }}
                multiple={false}
                accept={{
                  "application/pdf": [".pdf"],
                }}
              >
                {({ getRootProps }: any) => (
                  <div
                    className="position-relative dropzone dz-clickable text-center border rounded"
                    style={{ minHeight: 240 }}
                  >
                    {isLoading ? (
                      <div className="position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                        <img
                          src={Loader}
                          alt="loading"
                          className="img-thumbnail border-0 bg-transparent"
                        />
                      </div>
                    ) : null}
                    <div
                      className="dz-message needsclick cursor-pointer"
                      {...getRootProps()}
                    >
                      <div className="mb-3">
                        <i className="ri-file-fill text-muted fs-8xl" />
                      </div>
                      <p className="fs-lg">
                        Drop a file to upload or click to upload
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default withRouter(NPR4);
