import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  dateFormat,
  removeEmptyAndNullValues,
  humanizeString,
  customSelectTheme,
  styleObj,
  asyncBrandList,
  asyncSubgroupList,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import {
  getCountryListThunk,
  getEntityTypeListThunk,
  getBrandListThunk,
  deleteBrandThunk,
  openModal,
} from "slices/thunk";
import { submitLoadingSubject } from "Common/modals/Modal";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Flatpickr from "react-flatpickr";

const BrandsList = (props: any) => {
  document.title = "Signal House Portal Brands";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Brands,
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (brands, enums, groups) => ({
      loading: brands.loading,
      brandsData: brands.brandsData,
      entityTypeArr: enums.entityTypeArr,
      groupDetails: groups.AllGroups,
      error: brands.error,
    })
  );

  const { loading, brandsData, entityTypeArr, groupDetails } =
    useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      },
      width: "220px",
    },
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Brand ID
        </span>
      ),
      selector: (row: { brandId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <b>{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.brandId.localeCompare(b.brandId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { displayName: string }) =>
        capitalizeString(row.displayName),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.displayName.localeCompare(b.displayName),
    },
    {
      name: <span className="font-weight-bold fs-sm">Country</span>,
      selector: (row: { country: string }) => row.country,
      sortable: true,
      sortFunction: (a: any, b: any) => a.country.localeCompare(b.country),
    },
    {
      name: <span className="font-weight-bold fs-sm">Entity Type</span>,
      selector: (row: { entityType: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {humanizeString(row.entityType)}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.entityType.localeCompare(b.entityType),
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.brandIdentityStatus.localeCompare(b.brandIdentityStatus),
      selector: (cell: { brandIdentityStatus: string }) => {
        switch (cell.brandIdentityStatus.toLowerCase()) {
          case "verified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "unverified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "vetted_verified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "self_declared ":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#6c757d" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ backgroundColor: "#6c757d" }}
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { brandId: string }) => {
        return (
          <>
            <i
              className="ri-pencil-fill align-middle me-3 text-muted fs-xl"
              onClick={() => navigate(`/brands/edit/${cell.brandId}`)}
            />
            <i
              className="ri-delete-bin-fill align-middle text-muted fs-xl"
              onClick={() => {
                dispatch(
                  openModal({
                    modalbody:
                      "Are you sure you want to delete this brand? This action is irrevocable.",
                    data: {
                      title: "Delete Brand",
                      footer: true,
                      cancelBtn: true,
                      buttonText: {
                        submit: "Delete",
                      },
                      onSubmit: ({ onClose }: any) => {
                        const cb = () => {
                          onClose();
                          handleGetData(
                            currPage,
                            rowsPerPage,
                            removeEmptyAndNullValues({
                              ...validation.values,
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              brandName:
                                validation.values.brandName.length > 0
                                  ? validation.values.brandName?.map(
                                      (dt: any) => dt.displayName
                                    )
                                  : "",
                              createdAt: "",
                              startDate: validation.values.startDate
                                ? moment(
                                    validation.values.startDate,
                                    "MM-DD-YYYY"
                                  )
                                    .startOf("day")
                                    .toISOString()
                                : "",
                              endDate: validation.values.endDate
                                ? moment(
                                    validation.values.endDate,
                                    "MM-DD-YYYY"
                                  )
                                    .endOf("day")
                                    .toISOString()
                                : "",
                            })
                          );
                        };
                        submitLoadingSubject.next(true);
                        dispatch(deleteBrandThunk(cell.brandId, cb)).then(
                          () => {
                            submitLoadingSubject.next(false);
                          }
                        );
                      },
                    },
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      brandName: [],
      entityType: "",
      ein: "",
      universalEin: "",
      identityStatus: "",
      referenceId: "",
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          values.brandName.length > 0
            ? values.brandName?.map((dt: any) => dt.displayName)
            : "",
        createdAt: "",
        startDate: values.startDate
          ? moment(values.startDate, "MM-DD-YYYY").startOf("day").toISOString()
          : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    dispatch(getCountryListThunk());
    dispatch(getEntityTypeListThunk());
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getBrandListThunk(page, perPage, otherParams || {}));
  };

  const handleRowClick = (row: { brandId: string }) => {
    props.router.navigate(`/brands/${row.brandId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        brandName:
          validation.values.brandName.length > 0
            ? validation.values.brandName?.map((dt: any) => dt.displayName)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Brands"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <AsyncPaginate
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) => option?.sub_group_id}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("brandName", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand ID</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandName"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandName &&
                              validation?.errors?.brandName
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandName?.length > 0
                              ? validation.values.brandName
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "brandName"
                          )}
                          getOptionValue={(option: any) => option?.displayName}
                          getOptionLabel={(option: any) => option?.displayName}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("brandName", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="entityType">
                          Entity Type
                        </Form.Label>
                        <Select
                          name="entityType"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.entityType &&
                              validation?.errors?.entityType
                          )}
                          theme={customSelectTheme}
                          options={
                            Array.isArray(entityTypeArr) &&
                            entityTypeArr?.length > 0
                              ? entityTypeArr?.map((dt: string) => ({
                                  label: humanizeString(dt),
                                  value: dt,
                                }))
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("entityType", e.value);
                            } else {
                              validation.setFieldValue("entityType", "");
                            }
                          }}
                          value={
                            validation?.values?.entityType
                              ? {
                                  label: humanizeString(
                                    validation?.values?.entityType
                                  ),
                                  value: validation?.values?.entityType,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="ein">EIN/Tax Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="ein"
                          onChange={(e) => {
                            validation.setFieldValue("ein", e.target.value);
                          }}
                          value={validation.values.ein}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="universalEin">
                          Universal EIN
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="universalEin"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "universalEin",
                              e.target.value
                            );
                          }}
                          value={validation.values.universalEin}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="identityStatus">
                          Identity Status
                        </Form.Label>
                        <Select
                          name="identityStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.identityStatus &&
                              validation?.errors?.identityStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            {
                              label: humanizeString("SELF_DECLARED"),
                              value: "SELF_DECLARED",
                            },
                            {
                              label: humanizeString("UNVERIFIED"),
                              value: "UNVERIFIED",
                            },
                            {
                              label: humanizeString("VERIFIED"),
                              value: "VERIFIED",
                            },
                            {
                              label: humanizeString("VETTED_VERIFIED"),
                              value: "VETTED_VERIFIED",
                            },
                          ]}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue(
                                "identityStatus",
                                e.value
                              );
                            } else {
                              validation.setFieldValue("identityStatus", "");
                            }
                          }}
                          value={
                            validation.values.identityStatus
                              ? {
                                  label: humanizeString(
                                    validation.values.identityStatus
                                  ),
                                  value: validation.values.identityStatus,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="referenceId">
                          Reference ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceId"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "referenceId",
                              e.target.value
                            );
                          }}
                          value={validation.values.referenceId}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="createdAt">Created At</Form.Label>
                        <Flatpickr
                          className="form-control"
                          name="createdAt"
                          placeholder="Created At"
                          options={{
                            enableTime: false,
                            onOpen: () => {
                              setDatePickerModal(true);
                            },
                            onClose: () => {
                              setDatePickerModal(false);
                            },
                            altFormat: "F j, Y",
                            dateFormat: "m-d-Y",
                            mode: "range",
                            onChange: (
                              value: any,
                              dateStr: string,
                              instance: any
                            ) => {
                              validation.setFieldValue(
                                "createdAt",
                                dateStr || ""
                              );
                              value?.[0] &&
                                validation.setFieldValue(
                                  "startDate",
                                  moment(value?.[0]).format("MM-DD-YYYY")
                                );
                              value?.[1] &&
                                validation.setFieldValue(
                                  "endDate",
                                  moment(value?.[1]).format("MM-DD-YYYY")
                                );
                            },
                          }}
                          value={validation.values.createdAt || ""}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDatePickerModal(false);
                          handleGetData(currPage, rowsPerPage);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new brand"
            createLink="/brands/create"
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(brandsData?.records)
                        ? brandsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={brandsData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandsList);
