import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState } from "react";
import Tooltip from "rc-tooltip";
import { Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  createWebhookThunk,
  deleteWebhookThunk,
  editWebhookThunk,
  getWebhooksListThunk,
  openModal,
} from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "helpers/common";

const WebhooksList = (props: any) => {
  document.title = "Signal House Portal Web Hooks";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selector = createSelector(
    (state: any) => state.Webhooks,
    (webhooks) => ({
      loading: webhooks.loading,
      webhooksData: webhooks.webhooksList,
      error: webhooks.error,
    })
  );

  const { loading, webhooksData } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      selector: (row: { createdDate: string }) =>
        new Date(row.createdDate).getTime(),
      cell: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Webhook Name
        </span>
      ),
      selector: (row: { webhookName: string }) => row.webhookName,
      sortable: true,
    },
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Webhook Id
        </span>
      ),
      selector: (row: { webhookId: string }) => row.webhookId,
      cell: (row: { webhookId: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.webhookId}</p>}
        >
          <div onClick={() => handleRowClick(row)}>{row.webhookId}</div>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (row: { disable: boolean }) => row.disable,
      cell: (row: { disable: boolean }) => (
        <span
          data-tag="allowRowEvents"
          className={`badge ${
            !row.disable
              ? "bg-body-secondary border border-secondary text-secondary"
              : "bg-body-primary border border-primary text-primary"
          }`}
        >
          {!row.disable ? "Enabled" : "Disabled"}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { webhookId: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle me-3 text-muted fs-xl"
              onClick={() => navigate(`/webhooks-details/${cell.webhookId}`)}
            />
            <i
              className="ri-pencil-fill align-middle me-3 text-muted fs-xl"
              onClick={() => {
                dispatch(
                  openModal({
                    path: "AddEditWebhook",
                    data: {
                      title: "Edit Webhook",
                      editWebhookSubmit,
                      webhookId: cell.webhookId,
                      footer: false,
                    },
                  })
                );
              }}
            />
            <i
              className="ri-delete-bin-fill align-middle text-muted fs-xl"
              onClick={() => {
                dispatch(
                  openModal({
                    modalbody:
                      "Are you sure you want to delete this webhook? This action is irrevocable.",
                    data: {
                      title: "Delete Webhook",
                      footer: true,
                      cancelBtn: true,
                      buttonText: {
                        submit: "Delete",
                      },
                      onSubmit: ({ onClose }: any) => {
                        const cb = () => {
                          onClose();
                          handleGetData(currPage, rowsPerPage);
                        };
                        submitLoadingSubject.next(true);
                        dispatch(deleteWebhookThunk(cell.webhookId, cb)).then(
                          () => {
                            submitLoadingSubject.next(false);
                          }
                        );
                      },
                    },
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getWebhooksListThunk(page, perPage, otherParams || {}));
  };

  const handleRowClick = (row: { webhookId: string }) => {
    props.router.navigate(`/webhooks-details/${row.webhookId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const addWebhookSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
      handleGetData(currPage, rowsPerPage);
    };

    submitLoadingSubject.next(true);
    dispatch(createWebhookThunk(values, cb)).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const editWebhookSubmit = ({ webhookId, values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
      handleGetData(currPage, rowsPerPage);
    };

    submitLoadingSubject.next(true);
    dispatch(editWebhookThunk(webhookId, values, cb)).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Webhooks"
          isCreate={true}
          createLabel="New Webhook"
          createClick={() => {
            dispatch(
              openModal({
                path: "AddEditWebhook",
                data: {
                  title: "Add Webhook",
                  addWebhookSubmit,
                  footer: false,
                },
              })
            );
          }}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {loading ? (
                <div className={``}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                  />
                </div>
              ) : (
                <Datatable
                  data={
                    Array.isArray(webhooksData?.records)
                      ? webhooksData?.records
                      : []
                  }
                  columns={columns}
                  handleRowClick={handleRowClick}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  currPage={currPage}
                  rowsPerPage={rowsPerPage}
                  totalRecords={webhooksData?.totalRecords}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(WebhooksList);
