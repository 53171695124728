import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPaymentHistoryThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import Select from "react-select";

const PaymentHistory = (props: any) => {
  document.title = "Signal House Portal Payment History";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectProfile = createSelector(
    (state: any) => state.Billing,
    (billing) => ({
      loading: billing.loading,
      paymentHistoryData: billing.paymentHistoryData,
      error: billing.error,
    })
  );

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const { loading, paymentHistoryData } = useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Transaction Id</span>,
      selector: (row: { transactionId: string }) => row.transactionId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { time: string }) => new Date(row.time).getTime(),
      sortable: true,
      cell: (row: { time: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.time)}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Payment Details</span>,
      selector: (row: {
        status: string;
        paymentDetails: { brand: string; last4: string };
      }) =>
        row?.status === "succeeded"
          ? `${row?.paymentDetails?.brand} ${row?.paymentDetails?.last4}`
          : "-",
      sortable: true,
      cell: (row: {
        status: string;
        paymentDetails: { brand: string; last4: string };
      }) =>
        row?.status === "succeeded" ? (
          <span className="d-flex align-items-center">
            <i
              className={`ri ri-${row?.paymentDetails?.brand}-fill fs-4xl me-2`}
            />
            **** **** **** {row?.paymentDetails?.last4}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Amount</span>,
      selector: (row: { amount: number | string; currency: string }) =>
        Number(row.amount),
      sortable: true,
      cell: (row: { amount: number | string; currency: string }) =>
        `${row.amount} ${row.currency.toUpperCase()}`,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (cell: { status: string }) => cell.status.toLowerCase(),
      sortable: true,
      cell: (cell: { status: string }) => {
        switch (cell.status.toLowerCase()) {
          case "succeeded":
            return (
              <span className="badge bg-success-subtle text-success fs-sm">
                {capitalizeString(cell.status)}
              </span>
            );
          case "incomplete":
            return (
              <span className="badge bg-warning-subtle text-warning fs-sm">
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span className="badge bg-primary-subtle text-primary fs-sm">
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
    },
  ];

  const handleGetData = (page: number, perPage: number) => {
    dispatch(getPaymentHistoryThunk(page, perPage));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Payment History"
            isBack={true}
            backClick={() => props.router.navigate("/settings/billing")}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table2">
                <p>All billing dates and times are in UTC.</p>
                <div className="mb-4 d-flex flex-row justify-content-between">
                  <div>
                    <Select
                      name="entityType"
                      styles={styleObj(false)}
                      theme={customSelectTheme}
                      options={[{ label: "Feb 2024", value: "Feb 2024" }]}
                      onChange={(e: { value: string }) => {
                        // e.value
                      }}
                      value={{
                        label: "Feb 2024",
                        value: "Feb 2024",
                      }}
                    />
                  </div>
                  <div>
                    <p className="mb-0">
                      Total this month: <b>$20.00</b>
                    </p>
                    <a href="#">
                      View print receipt
                      <i className="mdi mdi-open-in-new" />
                    </a>
                  </div>
                </div>
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(paymentHistoryData?.records)
                        ? paymentHistoryData?.records
                        : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={paymentHistoryData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PaymentHistory);
