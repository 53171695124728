import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-dark.png";
import logoLight from "assets/images/logo-light.png";

//Import Components
import VerticalLayout from "./VerticalLayouts/index";
import TwoColumnLayout from "./TwoColumnLayout";
import { Button, Container, Dropdown } from "react-bootstrap";
import HorizontalLayout from "./HorizontalLayout";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getInitials } from "helpers/common";
import { getFileThunk } from "slices/thunk";

const Sidebar = ({ layoutType }: any) => {
  const dispatch = useDispatch<any>();
  const selectUser = createSelector(
    (state: any) => state.AuthUser,
    (user) => user?.authUser
  );
  const user = useSelector(selectUser);

  const init = getInitials(`${user?.firstName} ${user?.lastName}`);
  const [profileUrl, setProfileUrl] = useState(user?.profilePictureLink || "");

  useEffect(() => {
    if (user?.profilePictureLink) {
      const cb = (img: Blob | MediaSource) => {
        const temp = URL.createObjectURL(img);
        setProfileUrl(temp);
      };
      dispatch(getFileThunk(user?.profilePictureLink, cb));
    }
  }, [user?.profilePictureLink]);

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  const handleNavigation = () => {
    window.open(
      `${
        process.env.REACT_APP_BACKEND_URL || "https://devapi.signalhouse.io"
      }/apiDocs/#/`,
      "_blank"
    );
  };

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="22" />
            </span>
          </Link>

          <Link to={process.env.PUBLIC_URL + "/"} className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="50" />
            </span>
          </Link>
          <Button
            variant="link"
            size="sm"
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn p-0 fs-3xl header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </Button>

          <div className="my-3">
            <Dropdown align="end">
              <Dropdown.Toggle
                className="btn bg-transparent border-0 arrow-none p-0 w-100 user-side-profile"
                id="page-header-user-dropdown"
              >
                <span className="d-flex align-items-center">
                  {profileUrl ? (
                    <img
                      className="rounded-circle header-profile-user img"
                      src={profileUrl}
                      onError={() => {
                        setProfileUrl("");
                      }}
                      alt="Header Avatar"
                    />
                  ) : (
                    <div className="avatar-xs img">
                      <div className="avatar-title rounded-circle bg-white text-secondary">
                        {init}
                      </div>
                    </div>
                  )}
                  <span className="text-start ms-2">
                    <span className="d-inline-block ms-1 fw-medium user-name-text2">
                      {user?.firstName} {user?.lastName}
                    </span>
                    <span className="d-block ms-1 fs-sm user-name-sub-text">
                      {user?.companyName}
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end w-100">
                <h6 className="dropdown-header">
                  Welcome {user?.firstName} {user?.lastName}!
                </h6>
                <Dropdown.Item
                  href={process.env.PUBLIC_URL + "/settings/general"}
                >
                  <i className="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle">Edit Profile</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleNavigation}>
                  <i className=" ri-file-list-3-line text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle">Documentation</span>
                </Dropdown.Item>
                <Dropdown.Item href="/all-notifications">
                  <i className="mdi mdi-bell-outline text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle">Notifications</span>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/logout"}>
                  <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="user-side-profile balance">
              <i className="mdi mdi-wallet fs-lg align-middle me-1"></i>{" "}
              <small>Balance</small>:&nbsp;
              <b style={{ whiteSpace: "nowrap" }}>
                ${user?.balance ? (+user?.balance)?.toFixed(2) : 0} USD
              </b>
            </div>
          </div>
        </div>

        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout layoutType={layoutType} />
              </ul>
            </Container>
          </div>
        ) : layoutType === "twocolumn" ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100 simplebar-scrollbar1">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
