import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const NavSettingdata = () => {
  const selector = createSelector(
    (state: any) => state.Groups,
    (state: any) => state.AuthUser,
    (groups, authUser) => ({
      getGroupsData: groups.AllGroups,
      authUserPermissions: authUser.authUserPermissions,
    })
  );

  const { getGroupsData, authUserPermissions } = useSelector(selector);

  const hasPermission = (uid: string) => {
    if (!authUserPermissions || !uid) return false;
    const permission = _.get(authUserPermissions, uid);
    return permission && permission.read === true;
  };

  const menuItems: any = [
    {
      label: "Account",
      isHeader: true,
    },
    {
      id: "general",
      label: "General",
      icon: "ph-user",
      link: "/settings/general",
      uid: "settings.general",
    },
    {
      id: "security",
      label: "Security",
      icon: "ph-lock-simple",
      link: "/settings/security",
      uid: "settings.security",
    },
    {
      id: "users",
      label: "Users",
      icon: "ph-users",
      link: "/settings/users",
      uid: "settings.users",
    },
    {
      id: "groups",
      label: "Sub Groups",
      icon: "ph-users-three",
      link: `/settings/groups/${getGroupsData?.records?.[0]?.group_id}/subgroups`,
      uid: "settings.groups",
    },
    // {
    //   id: "webhook",
    //   label: "Webhooks",
    //   icon: "ph-list-bullets",
    //   link: "/settings/webhooks",
    // },
    {
      id: "notifications",
      label: "Notifications",
      icon: "ph-bell",
      link: "/settings/notification-events",
      uid: "settings.notifications",
    },
    {
      label: "Company",
      isHeader: true,
    },
    {
      id: "billing",
      label: "Billing",
      icon: "ph-money",
      link: "/settings/billing",
      uid: "settings.billing",
    },
    {
      id: "account-pricing",
      label: "Account Pricing",
      icon: "ph-money",
      link: "/settings/account-pricing",
      uid: "settings.account-pricing",
    },
    {
      id: "usage-transactions",
      label: "Usage Transactions",
      icon: "ph-activity",
      link: "/settings/usage-transactions",
      uid: "settings.usage-transactions",
    },
  ];

  const filteredMenuItems = menuItems.reduce(
    (acc: any[], item: any, index: number) => {
      if (item.isHeader) {
        // Check if there are any visible items after this header
        const hasVisibleItems = menuItems
          .slice(index + 1)
          .some(
            (nextItem: any) =>
              !nextItem.isHeader &&
              (!nextItem.uid || hasPermission(nextItem.uid))
          );

        if (hasVisibleItems) {
          acc.push(item);
        }
      } else if (!item.uid || hasPermission(item.uid)) {
        acc.push(item);
      }
      return acc;
    },
    []
  );

  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};

export default NavSettingdata;
