import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { capitalizeString, removeKeyFromObj } from "helpers/common";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useNavigate } from "react-router-dom";
import { getCampaignListThunk } from "slices/thunk";

const widgetsData = [
  {
    bgcolor: "bg-primary-subtle",
    textcolor: "text-primary",
    progressbar: "progress-bar bg-primary",
  },
];

const orderedKeys = ["campaign"];

const generateRandomNumber = () => {
  const min = 20;
  const max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getIcon = (data: string) => {
  switch (data) {
    case "Number":
      return "ph-file-text";
    case "SMS":
      return "ph-chat-circle-text";
    case "MMS":
      return " ph-image-light";
    case "brand":
      return "ph-storefront";
    case "campaign":
      return "ph-megaphone";
    default:
      return "ph-activity";
  }
};

const Widgets = ({ month }: any) => {
  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (state: any) => state.Campaigns,
    (usageSummary, user, billing, analytics, groups, campaigns) => ({
      loading: usageSummary.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
      authUser: user?.authUser,
      loading2: billing.loading,
      error2: billing.error,
      analyticsSmsV1: analytics.analyticsSmsV1 || {},
      analyticsMmsV1: analytics.analyticsMmsV1 || {},
      analyticsAllV1: analytics.analyticsAllV1 || {},
      groupDetails: groups?.AllGroups,
      campaignsData: campaigns.campaignsData,
    })
  );

  const {
    loading,
    usageSummaryObj,
    analyticsSmsV1,
    analyticsMmsV1,
    analyticsAllV1,
    campaignsData,
  } = useSelector(selectProfile);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [summaryObj, setSummaryObj] = useState<any>({});
  const [cardObj, setCardObj] = useState<any>({});

  const navigateToCampaigns = (status: string) => {
    // Navigate to /campaigns with status as query parameter
    navigate(`/campaigns?status=${status}`);
  };

  const calculateResponseRate = () => {
    const groupDataByPhoneNumber = (
      data: Record<string, Record<string, Record<string, number>>>
    ) => {
      return Object.keys(data).reduce(
        (acc: Record<string, Record<string, number>>, date) => {
          const dateData = data[date];
          Object.keys(dateData).forEach((phoneNumber) => {
            if (!acc[phoneNumber]) {
              acc[phoneNumber] = {};
            }
            Object.keys(dateData[phoneNumber]).forEach((key) => {
              acc[phoneNumber][key] =
                (acc[phoneNumber][key] || 0) + dateData[phoneNumber][key];
            });
          });
          return acc;
        },
        {}
      );
    };

    const smsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.SMS);
    const mmsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.MMS);
    const allDataByPhoneNumber: any = Object.keys({
      ...smsDataByPhoneNumber,
      ...mmsDataByPhoneNumber,
    }).reduce((acc: any, phoneNumber: any) => {
      acc[phoneNumber] = {};
      const smsData = smsDataByPhoneNumber[phoneNumber] || {};
      const mmsData = mmsDataByPhoneNumber[phoneNumber] || {};
      const allKeys = new Set([
        ...Object.keys(smsData),
        ...Object.keys(mmsData),
      ]);
      allKeys.forEach((key) => {
        acc[phoneNumber][key] = (smsData[key] || 0) + (mmsData[key] || 0);
      });
      return acc;
    }, {});

    const calculateRateForType = (
      data: Record<string, Record<string, number>>,
      total: any
    ) => {
      let respondedNumbers = 0;

      Object.keys(data).forEach((phoneNumber) => {
        const phoneData = data[phoneNumber];

        // Check if the number has any inbound messages
        if (phoneData.totalInbound > 0 && phoneData.delivered > 0) {
          // Check if all inbound messages are opt-outs
          if (phoneData.totalInbound > phoneData.totalOptOut) {
            respondedNumbers++;
          }
        }
      });

      return total > 0 ? (respondedNumbers / total) * 100 : 0;
    };

    const smsResponseRate = calculateRateForType(
      smsDataByPhoneNumber,
      smsTotal?.delivered
    );

    const mmsResponseRate = calculateRateForType(
      mmsDataByPhoneNumber,
      mmsTotal?.delivered
    );

    // Calculate total response rate

    const totalResponseRate = calculateRateForType(
      allDataByPhoneNumber,
      smsTotal?.delivered + mmsTotal?.delivered
    );

    // Update the cardObj with new response rates
    return {
      smsrr: smsResponseRate,
      mmsrr: mmsResponseRate,
      allrr: totalResponseRate,
    };
  };

  let date = moment(month.endDate, "MM-DD-YYYY")?.diff(
    moment(month.startDate, "MM-DD-YYYY"),
    "days"
  );
  let dateDiff =
    moment(month.startDate, "MM-DD-YYYY") && moment(month.endDate, "MM-DD-YYYY")
      ? isNaN(date)
        ? 1
        : date
      : 1;

  const smsTotal = useMemo(() => {
    if (analyticsSmsV1.data) {
      return Object.keys(analyticsSmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsSmsV1.data[date];
        Object.keys(dateData).map((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).map((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
    }
    return {};
  }, [analyticsSmsV1]);

  const mmsTotal = useMemo(() => {
    if (analyticsMmsV1.data)
      return Object.keys(analyticsMmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsMmsV1.data[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
    return {};
  }, [analyticsMmsV1]);

  // const totalMessagingData = useMemo(() => {
  //   const combinedTotals: any = {};
  //   if (smsTotal) {
  //     Object.keys(smsTotal).map((key) => {
  //       combinedTotals[key] = (combinedTotals[key] || 0) + smsTotal[key];
  //     });
  //   }
  //   if (mmsTotal) {
  //     Object.keys(mmsTotal).map((key) => {
  //       combinedTotals[key] = (combinedTotals[key] || 0) + mmsTotal[key];
  //     });
  //   }
  //   return combinedTotals;
  // }, [smsTotal, mmsTotal]);

  const setCardData = () => {
    const responseRateData: any = calculateResponseRate();
    const totalSmsSent =
      (smsTotal?.delivered || 0) +
      (smsTotal?.sent || 0) +
      (smsTotal?.failed || 0);

    smsTotal["deliveryRate"] =
      (100 * smsTotal?.delivered) / (totalSmsSent || 1);
    smsTotal["failedRate"] = (100 * smsTotal?.failed) / (totalSmsSent || 1);
    smsTotal["sentRate"] = (100 * smsTotal?.sent) / (totalSmsSent || 1);
    smsTotal["totalMessagesSent"] = totalSmsSent;
    smsTotal["averageMessagesSent"] = totalSmsSent / dateDiff;
    smsTotal["averageMessagesReceived"] = smsTotal?.totalInbound / dateDiff;
    smsTotal["averageSegmentsSent"] = smsTotal?.segmentSent / dateDiff;
    smsTotal["optOutRate"] =
      (100 * smsTotal?.totalOptOut) / (smsTotal?.totalInbound || 1);
    smsTotal["averageSegmentReceived"] = smsTotal?.segmentRecieved / dateDiff;
    smsTotal["responseRate"] = responseRateData?.smsrr;
    smsTotal["nonOptOutRate"] =
      (100 * smsTotal?.delivered - smsTotal?.totalOptOut) / (totalSmsSent || 1);

    const totalMmsSent =
      (mmsTotal?.delivered || 0) +
      (mmsTotal?.sent || 0) +
      (mmsTotal?.failed || 0);

    mmsTotal["deliveryRate"] =
      (100 * mmsTotal?.delivered) / (totalMmsSent || 1);
    mmsTotal["failedRate"] = (100 * mmsTotal?.failed) / (totalMmsSent || 1);
    mmsTotal["sentRate"] = (100 * mmsTotal?.sent) / (totalMmsSent || 1);
    mmsTotal["totalMessagesSent"] = totalMmsSent;
    mmsTotal["averageMessagesSent"] = totalMmsSent / dateDiff;
    mmsTotal["averageMessagesReceived"] = mmsTotal?.totalInbound / dateDiff;
    mmsTotal["averageSegmentsSent"] = mmsTotal?.segmentSent / dateDiff;
    mmsTotal["optOutRate"] =
      (100 * mmsTotal?.totalOptOut) / (mmsTotal?.totalInbound || 1);
    mmsTotal["averageSegmentReceived"] = mmsTotal?.segmentRecieved / dateDiff;
    mmsTotal["responseRate"] = responseRateData?.mmsrr;
    mmsTotal["nonOptOutRate"] =
      (100 * mmsTotal?.delivered - mmsTotal?.totalOptOut) / (totalMmsSent || 1);

    const allTotal = Object.keys(smsTotal).reduce((acc: any, key: any) => {
      acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
      return acc;
    }, {});

    const totalAllSent =
      (allTotal?.delivered || 0) +
      (allTotal?.sent || 0) +
      (allTotal?.failed || 0);

    allTotal["deliveryRate"] =
      (100 * allTotal?.delivered) / (totalAllSent || 1);
    allTotal["failedRate"] = (100 * allTotal?.failed) / (totalAllSent || 1);
    allTotal["sentRate"] = (100 * allTotal?.sent) / (totalAllSent || 1);
    allTotal["totalMessagesSent"] = totalAllSent;
    allTotal["averageMessagesSent"] = totalAllSent / dateDiff;
    allTotal["averageMessagesReceived"] = allTotal?.totalInbound / dateDiff;
    allTotal["averageSegmentsSent"] = allTotal?.segmentSent / dateDiff;
    allTotal["optOutRate"] =
      (100 * allTotal?.totalOptOut) / (allTotal?.totalInbound || 1);
    allTotal["averageSegmentReceived"] = allTotal?.segmentRecieved / dateDiff;
    allTotal["responseRate"] = responseRateData?.allrr;
    allTotal["nonOptOutRate"] =
      (100 * allTotal?.delivered - allTotal?.totalOptOut) / (totalAllSent || 1);

    const tempObj = {
      SMS: { ...smsTotal },
      MMS: { ...mmsTotal },
      All: { ...allTotal },
    };
    setCardObj(tempObj);
  };

  useEffect(() => {
    if (Object.keys(smsTotal)?.length && Object.keys(mmsTotal)?.length) {
      setCardData();
    }
  }, [smsTotal, mmsTotal]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  const dRate = cardObj?.All?.deliveryRate || 0;

  const handleGetData1 = () => {
    dispatch(
      getCampaignListThunk(1, 10, {
        campaignType: "own",
      })
    );
  };

  useEffect(() => {
    handleGetData1();
  }, []);

  return (
    <React.Fragment>
      <Row>
        {loading ? (
          <div style={{ height: 60 }}>
            <img
              src={Loader}
              className="position-absolute top-50 start-50 translate-middle"
            />
          </div>
        ) : (
          <>
            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-primary-subtle text-white fs-2xl rounded subContent"
                      }
                      onClick={() => navigate("/analytics?type=all")}
                    >
                      <i className="ri-question-answer-line text-primary "></i>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/analytics?type=all")}
                    className="text-muted mb-3 subContent"
                  >
                    Messaging
                  </p>
                  <h4>
                    <span className="counter-value">
                      <CountUp
                        start={0}
                        end={
                          usageSummaryObj?.total?.SMS?.transactions +
                          usageSummaryObj?.total?.MMS?.transactions 
                        }
                      />
                    </span>
                  </h4>
                  <p
                    onClick={() => navigate("/analytics?type=sms_outbound")}
                    className="text-muted mb-1 subContent"
                  >
                    SMS outbound:{" "}
                    {usageSummaryObj?.total?.SMS?.outbound?.transactions || "0"}
                  </p>
                  <p
                    onClick={() => navigate("/analytics?type=sms_inbound")}
                    className="text-muted mb-1 subContent"
                  >
                    SMS inbound:{" "}
                    {usageSummaryObj?.total?.SMS?.inbound?.transactions || "0"}
                  </p>
                  <p
                    onClick={() => navigate("/analytics?type=mms_outbound")}
                    className="text-muted mb-1 subContent"
                  >
                    MMS outbound:{" "}
                    {usageSummaryObj?.total?.MMS?.outbound?.transactions || "0"}
                  </p>
                  <p
                    onClick={() => navigate("/analytics?type=mms_inbound")}
                    className="text-muted mb-1 subContent"
                  >
                    MMS inbound:{" "}
                    {usageSummaryObj?.total?.MMS?.inbound?.transactions || "0"}
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-primary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>
            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-secondary-subtle text-white fs-2xl rounded subContent"
                      }
                      onClick={() => navigate("/analytics?type=all-d-rate")}
                    >
                      <i className=" ri-arrow-right-down-line text-secondary "></i>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/analytics?type=all-d-rate")}
                    className="text-muted mb-3 subContent"
                  >
                    Delivery Rate
                  </p>
                  <h4>
                    <span className="counter-value">
                      <CountUp suffix="%" decimals={2} start={0} end={dRate} />
                    </span>
                  </h4>{" "}
                  <p
                    onClick={() =>
                      navigate("/analytics?type=sms_outbound_d-rate")
                    }
                    className="text-muted mb-1 subContent"
                  >
                    SMS: {(cardObj?.SMS?.deliveryRate || 0).toFixed(2) || "0.00"}%
                  </p>
                  <p
                    onClick={() =>
                      navigate("/analytics?type=mms_outbound_d-rate")
                    }
                    className="text-muted mb-1 subContent"
                  >
                    MMS: {(cardObj?.MMS?.deliveryRate || 0).toFixed(2) || "0.00"}%
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-secondary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>

            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-primary-subtle text-white fs-2xl rounded subContent"
                      }
                      onClick={() => navigate("/analytics?type=non_optout_rate")}
                    >
                      <i className="ri-line-chart-line text-primary "></i>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/analytics?type=non_optout_rate")}
                    className="text-muted mb-3 subContent"
                  >
                    Non Opt-Out Response Rate{" "}
                  </p>
                  <h4>
                    <span className="counter-value">
                      <CountUp
                        suffix="%"
                        decimals={2}
                        start={0}
                        end={cardObj?.All?.nonOptOutRate}
                      />
                    </span>
                  </h4>{" "}
                  <p
                    onClick={() =>
                      navigate("/analytics?type=sms_non_optout_rate")
                    }
                    className="text-muted mb-1 subContent"
                  >
                    SMS: {(cardObj?.SMS?.nonOptOutRate || 0).toFixed(2)|| "0.00"}%
                  </p>
                  <p
                    onClick={() =>
                      navigate("/analytics?type=mms_non_optout_rate")
                    }
                    className="text-muted mb-1 subContent"
                  >
                    MMS: {(cardObj?.MMS?.nonOptOutRate || 0).toFixed(2)|| "0.00"}%
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-primary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>

            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-secondary-subtle text-white fs-2xl rounded subContent"
                      }
                      onClick={() => navigate("/analytics?type=optout-rate")}
                    >
                      <i className="bi bi-send text-secondary "></i>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/analytics?type=optout-rate")}
                    className="text-muted mb-3 subContent"
                  >
                    Opt-Out Rate
                  </p>
                  <h4>
                    <span className="counter-value">
                      <CountUp
                        suffix="%"
                        decimals={2}
                        start={0}
                        end={(cardObj?.All?.optOutRate || 0).toFixed(2)}
                      />
                    </span>
                  </h4>
                  <p
                    onClick={() => navigate("/analytics?type=sms_optout-rate")}
                    className="text-muted mb-1 subContent"
                  >
                    SMS: {(cardObj?.Sms?.optOutRate || 0).toFixed(2)|| "0.00"}%
                  </p>
                  <p
                    onClick={() => navigate("/analytics?type=mms_optout-rate")}
                    className="text-muted mb-1 subContent"
                  >
                    MMS: {(cardObj?.Mms?.optOutRate || 0).toFixed(2)|| "0.00"}%
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-secondary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>

            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title " +
                        widgetsData[0].bgcolor +
                        " " +
                        widgetsData[0].textcolor +
                        " fs-3xl rounded subContent "
                      }
                      onClick={() => navigate("/campaigns")}
                    >
                      <i className={getIcon("campaign")}></i>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/campaigns")}
                    className="text-muted mb-1 subContent"
                  >
                    Campaign Status
                  </p>
                  <h4>
                    <span className="counter-value" data-target="368.24">
                      <CountUp
                        start={0}
                        end={campaignsData.totalRecords || 0}
                      />
                    </span>
                  </h4>
                  <p
                    className="text-muted mb-1 subContent"
                    onClick={() => navigateToCampaigns("ACTIVE")}
                  >
                    Active: {campaignsData.active || 0}
                  </p>
                  <p
                    className="text-muted mb-1 subContent"
                    onClick={() => navigateToCampaigns("PENDING")}
                  >
                    Pending: {campaignsData.pending || 0}
                  </p>
                  <p
                    className="text-muted mb-1 subContent"
                    onClick={() => navigateToCampaigns("EXPIRED")}
                  >
                    Expired: {campaignsData.expired || 0}
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0"
                  role="progressbar"
                >
                  <div
                    className={widgetsData[0].progressbar}
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Widgets;
