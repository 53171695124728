import { Col, Form, Row, Tab } from "react-bootstrap";
import Select from "react-select";
import Radiobox from "Common/Radiobox";
import Tooltip from "rc-tooltip";
import { customSelectTheme, styleObj } from "helpers/common";

const toopTipData: { [key: string]: string } = {
  "2FA": "Any authentication, verification, or one-time passcode",
  ACCOUNT_NOTIFICATION:
    "Standard notifications for account holders, relating to and being about an account",
  AGENTS_FRANCHISES:
    "Brands that have multiple agents, franchises or offices in the same brand vertical, but require individual localized numbers per agent/location/office! Post-registration approval by MNO is required for this Use Case, 5000 number limit",
  CARRIER_EXEMPT:
    "Exemption by Carrier! Post-registration approval by MNO is required for this Use Case",
  CHARITY:
    "Communications from a registered charity aimed at providing help and raising money for those in need. 501c3 Tax-Exempt Organizations only.",
  CUSTOMER_CARE:
    "All customer care interaction, including but not limited to account management and customer support",
  DELIVERY_NOTIFICATION:
    "Notification about the status of the delivery of a product or service",
  EMERGENCY:
    "Notification services designed to support public safety/health during natural disasters, armed conflicts, pandemics, and other national or regional emergencies.! Post-registration approval by MNO is required for this Use Case",
  FRAUD_ALERT:
    "Notifications regarding potential fraudulent activity on a user's account",
  HIGHER_EDUCATION:
    "Messaging created on behalf of Colleges or Universities, including School Districts and education institutions. This use case is NOT for the `free to the consumer` messaging model",
  K12_EDUCATION:
    "Campaigns created for messaging platforms that support schools from grades K - 12, and distance learning centers. This is not for Post-Secondary schools.! Post-registration approval by MNO is required for this Use Case",
  LOW_VOLUME:
    "For Brands that have multiple use cases and only need very low messaging throughput. Examples include: test or demo accounts, small businesses (single Doctor's office, single Pizza shop), etc. Maximum of 5 sub Standard use cases.",
  M2M: "Machine-to-Machine (M2M) is a process that implies wireless communication between two or more physical assets. There is no human interaction in the Machine-to-Machine campaign. Subscriber-facing campaigns are prohibited. This is a dedicated use case",
  MARKETING:
    "Any communication that includes marketing and/or promotional content",
  MIXED:
    "For brands that have multiple use cases and want to run them on the same campaign. Minimum of 2 sub use cases and a maximum of 5 sub use cases.",
  POLITICAL:
    "Part of organized effort to influence decision making of specific groups. Available only to Non Profit entities with a Campaign Verify token or Aegis Political Vet, or Non Profit entities with a verified 501(c)(3/4/5/6) tax exempt status.! Register at www.campaignverify.org and select 'Import Vetting' on the Brand Details page to unlock the Political Use Case, or apply for Aegis Political Vetting on the Brand Details page.",
  POLLING_VOTING: "The sending of surveys and polling/voting campaigns.",
  PROXY:
    "Peer-to-peer, app-based group messaging with proxy/pooled numbers. Supporting personalized services and non-exposure of personal numbers for enterprise or A2P communications.! Post-registration approval by MNO is required for this Use Case",
  PUBLIC_SERVICE_ANNOUNCEMENT:
    "Informational messaging to raise an audience's awareness about important issues",
  SECURITY_ALERT:
    "A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take",
  SOCIAL:
    "Communication between public figures/influencers and their communities. Examples include: YouTube Influencers' alerts or Celebrity alerts! Only MNO pre-approved CSPs are eligible for this Use Case",
  SOLE_PROPRIETOR:
    "Limited to US or Canadian individuals or small businesses without an EIN / Tax ID, and requires a separate contract. Compliance and reporting Policy HERE! Only pre-approved CSPs are eligible for this Use Case",
  SWEEPSTAKE:
    "All sweepstakes messaging! Post-registration approval by MNO is required for this Use Case",
  TRIAL:
    "Platform Free Trial offers, with strict controls and MNO audit rights (MO opt in). Separate contract required.! Only pre-approved CSPs are eligible for this Use Case",
  UCAAS_HIGH:
    "UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This is for UCaaS campaigns that require higher volume.This use case is only available to approved UCaaS businesses.! Only pre-approved CSPs are eligible for this Use Case",
  UCAAS_LOW:
    "UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This use case is only available to approved UCaaS businesses. This use case has the same volume restrictions as the Low Volume Mixed campaign type.! Only pre-approved CSPs are eligible for this Use Case",
};

const Step1 = (props: any) => {
  const {
    brandsData,
    validation1,
    standardType,
    specialType,
    usecaseObj,
    subUseCase,
    SetMinSubUsecases,
    SetMaxSubUsecases,
  } = props;

  return (
    <Tab.Pane
      eventKey="0"
      id="pills-gen-info"
      role="tabpanel"
      aria-labelledby="pills-gen-info-tab"
    >
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation1.handleSubmit();
        }}
      >
        <div>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="brandId">
                  Select Brand <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  name="brandId"
                  styles={styleObj(
                    validation1.touched.brandId && validation1.errors.brandId
                  )}
                  theme={customSelectTheme}
                  options={
                    Array.isArray(brandsData?.records) &&
                    brandsData?.records?.length > 0
                      ? brandsData?.records?.map(
                          (dt: { displayName?: string; brandId?: string }) => ({
                            label: `${dt?.displayName} - ${dt?.brandId}`,
                            value: dt?.brandId,
                          })
                        )
                      : []
                  }
                  onChange={(e: { value: string }) => {
                    validation1.setFieldValue("brandId", e.value);
                  }}
                  value={{
                    label: `${
                      (Array.isArray(brandsData?.records) &&
                        brandsData?.records?.find(
                          (dt: { brandId: number }) =>
                            dt?.brandId === validation1.values.brandId
                        )?.displayName) ||
                      ""
                    } ${
                      (Array.isArray(brandsData?.records) &&
                        brandsData?.records?.find(
                          (dt: { brandId: number }) =>
                            dt?.brandId === validation1.values.brandId
                        )?.brandId) ||
                      ""
                    }`,
                    value: validation1.values.brandId,
                  }}
                />
                {validation1.touched.brandId && validation1.errors.brandId ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.brandId}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-5 mb-4">
            <div>
              <h5 className="mb-1">Select a Use-Case</h5>
              <p className="text-muted mb-1">
                (Please hover over use case names for a description)
              </p>
              {validation1.touched.usecase && validation1.errors.usecase ? (
                <p className="text-primary mb-1">
                  {validation1.errors.usecase}
                </p>
              ) : null}
              {validation1.touched.subUsecases &&
              validation1.errors.subUsecases ? (
                <p className="text-primary mb-1">
                  {validation1.errors.subUsecases}
                </p>
              ) : null}
            </div>
            <Row>
              <Col lg={6}>
                <div className="border rounded p-4">
                  <h6 className="text-center mb-4">Standard Campaign Type</h6>
                  <div>
                    <table className="table table-hover mb-0">
                      <thead className="bg-primary-subtle">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col" className="w-100">
                            Use Case
                          </th>
                          <th scope="col">TCR Monthly Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(standardType)?.length > 0
                          ? Object.keys(standardType)?.map(
                              (type: string, i: number) => {
                                const shouldDisable = standardType?.[type]
                                  ?.mnoMetadata
                                  ? Object.fromEntries(
                                      Object.entries(
                                        standardType?.[type]?.mnoMetadata
                                      ).filter(
                                        ([key, value]: any) => value?.qualify
                                      )
                                    )
                                  : {};

                                return (
                                  <tr key={i}>
                                    <td>
                                      <Radiobox
                                        id={type}
                                        name="usecase"
                                        value={type}
                                        disabled={
                                          !validation1.values.brandId ||
                                          Object.keys(shouldDisable).length ===
                                            0
                                        }
                                        checked={
                                          validation1.values.usecase === type
                                        }
                                        onChange={(e: any) => {
                                          validation1.setFieldValue(
                                            "usecase",
                                            e.target.value
                                          );
                                          validation1.setFieldValue(
                                            "subUsecases",
                                            []
                                          );
                                          SetMinSubUsecases(
                                            standardType?.[type]
                                              ?.minSubUsecases || 0
                                          );
                                          SetMaxSubUsecases(
                                            standardType?.[type]
                                              ?.maxSubUsecases || 0
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      className={
                                        !validation1.values.brandId ||
                                        Object.keys(shouldDisable).length === 0
                                          ? "text-muted"
                                          : ""
                                      }
                                    >
                                      <div>
                                        <Tooltip
                                          placement="right"
                                          overlay={toopTipData[type]}
                                        >
                                          <span>
                                            {standardType?.[type]?.displayName}
                                            {!standardType?.[type]
                                              ?.validSubUsecase &&
                                            standardType?.[type]
                                              ?.maxSubUsecases > 0 &&
                                            validation1.values.usecase ===
                                              type &&
                                            subUseCase?.length > 0
                                              ? ` (${standardType?.[type]?.minSubUsecases}/${standardType?.[type]?.maxSubUsecases})`
                                              : null}
                                          </span>
                                        </Tooltip>
                                      </div>
                                      {!standardType?.[type]?.validSubUsecase &&
                                      standardType?.[type]?.maxSubUsecases >
                                        0 &&
                                      validation1.values.usecase === type &&
                                      subUseCase?.length > 0 ? (
                                        <Row>
                                          {subUseCase?.map(
                                            (dt: string, i: number) => (
                                              <Col
                                                lg={6}
                                                key={i}
                                                className="mt-2"
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id="checkbox1"
                                                  checked={validation1.values.subUsecases.includes(
                                                    dt
                                                  )}
                                                  onChange={(e) => {
                                                    e.target.checked
                                                      ? validation1.setFieldValue(
                                                          "subUsecases",
                                                          [
                                                            ...validation1
                                                              .values
                                                              .subUsecases,
                                                            dt,
                                                          ]
                                                        )
                                                      : validation1.setFieldValue(
                                                          "subUsecases",
                                                          validation1.values.subUsecases.filter(
                                                            (item: string) =>
                                                              item !== dt
                                                          )
                                                        );
                                                  }}
                                                />
                                                {"  "}
                                                {usecaseObj?.[dt]?.displayName}
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                      ) : null}
                                    </td>
                                    <td
                                      className={
                                        !validation1.values.brandId ||
                                        Object.keys(shouldDisable).length === 0
                                          ? "text-muted"
                                          : ""
                                      }
                                    >
                                      {standardType?.[type]?.monthlyFee ||
                                      standardType?.[type]?.monthlyFee === 0
                                        ? `$${standardType?.[type]?.monthlyFee}`
                                        : "-"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="border rounded p-4">
                  <h6 className="text-center mb-4">Special Campaign Type</h6>
                  <div>
                    <table className="table table-hover mb-0">
                      <thead className="bg-primary-subtle">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col" className="w-100">
                            Use Case
                          </th>
                          <th scope="col">TCR Monthly Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(specialType)?.length > 0
                          ? Object.keys(specialType)?.map(
                              (type: string, i: number) => {
                                const shouldDisable = specialType?.[type]
                                  ?.mnoMetadata
                                  ? Object.fromEntries(
                                      Object.entries(
                                        specialType?.[type]?.mnoMetadata
                                      ).filter(
                                        ([key, value]: any) => value?.qualify
                                      )
                                    )
                                  : {};

                                return (
                                  <tr key={i}>
                                    <td>
                                      <Radiobox
                                        id={type}
                                        name="usecase"
                                        value={type}
                                        disabled={
                                          !validation1.values.brandId ||
                                          Object.keys(shouldDisable).length ===
                                            0
                                        }
                                        checked={
                                          validation1.values.usecase === type
                                        }
                                        onChange={(e: any) => {
                                          validation1.setFieldValue(
                                            "usecase",
                                            e.target.value
                                          );
                                          validation1.setFieldValue(
                                            "subUsecases",
                                            []
                                          );
                                          SetMinSubUsecases(
                                            specialType?.[type]
                                              ?.minSubUsecases || 0
                                          );
                                          SetMaxSubUsecases(
                                            specialType?.[type]
                                              ?.maxSubUsecases || 0
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      className={
                                        !validation1.values.brandId ||
                                        Object.keys(shouldDisable).length === 0
                                          ? "text-muted"
                                          : ""
                                      }
                                    >
                                      <div>
                                        <Tooltip
                                          placement="right"
                                          overlay={toopTipData[type]}
                                        >
                                          <span>
                                            {specialType?.[type]?.displayName}
                                            {!specialType?.[type]
                                              ?.validSubUsecase &&
                                            specialType?.[type]
                                              ?.maxSubUsecases > 0 &&
                                            validation1.values.usecase ===
                                              type &&
                                            subUseCase?.length > 0
                                              ? ` (${specialType?.[type]?.minSubUsecases}/${specialType?.[type]?.maxSubUsecases})`
                                              : null}
                                          </span>
                                        </Tooltip>
                                      </div>
                                      {!specialType?.[type]?.validSubUsecase &&
                                      specialType?.[type]?.maxSubUsecases > 0 &&
                                      validation1.values.usecase === type &&
                                      subUseCase?.length > 0 ? (
                                        <Row>
                                          {subUseCase?.map(
                                            (dt: string, i: number) => (
                                              <Col
                                                lg={6}
                                                key={i}
                                                className="mt-2"
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id="checkbox1"
                                                  checked={validation1.values.subUsecases.includes(
                                                    dt
                                                  )}
                                                  onChange={(e) => {
                                                    e.target.checked
                                                      ? validation1.setFieldValue(
                                                          "subUsecases",
                                                          [
                                                            ...validation1
                                                              .values
                                                              .subUsecases,
                                                            dt,
                                                          ]
                                                        )
                                                      : validation1.setFieldValue(
                                                          "subUsecases",
                                                          validation1.values.subUsecases.filter(
                                                            (item: string) =>
                                                              item !== dt
                                                          )
                                                        );
                                                  }}
                                                />
                                                {"  "}
                                                {usecaseObj?.[dt]?.displayName}
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                      ) : null}
                                    </td>
                                    <td
                                      className={
                                        !validation1.values.brandId ||
                                        Object.keys(shouldDisable).length === 0
                                          ? "text-muted"
                                          : ""
                                      }
                                    >
                                      {specialType?.[type]?.monthlyFee ||
                                      specialType?.[type]?.monthlyFee === 0
                                        ? `$${specialType?.[type]?.monthlyFee}`
                                        : "-"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="d-flex align-items-start gap-3 mt-4">
          <button
            type="submit"
            className="btn btn-secondary btn-label right m-auto nexttab nexttab"
          >
            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
            Next
          </button>
        </div>
      </Form>
    </Tab.Pane>
  );
};

export default Step1;
