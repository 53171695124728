import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Spinner,
  Container,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Datatable from "../../Common/Datatable";
import {
  getMessagesThunk,
  getMmsLogsThunk,
  getPersonalMessagesLogsThunk,
  resetMessageLogsList,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  capitalizeString,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Select from "react-select";
import Tooltip from "rc-tooltip";

const dateRangeArr = [
  {
    label: "24 hours",
    value: "24 hours",
  },
  {
    label: "7 days",
    value: "7 days",
  },
  {
    label: "30 days",
    value: "30 days",
  },
  {
    label: "120 days",
    value: "120 days",
  },
  {
    label: "Custom (full custom range)",
    value: "Custom",
  },
];

const phoneNumberWithPlus = /^\+\w+/;

enum LocationType {
  SMS_LOGS_PAGE = "/sms-logs",
  MMS_LOGS_PAGE = "/mms-logs",
  MY_NUMBERS_PAGE = "/mynumbers",
}

const SmsAndMmsLogs = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const CURRENT_LOCATION = props.router?.location.pathname;

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialFrom, setInitialFrom] = useState("");
  const [activeDir, setActiveDir] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState<any>(null);

  const customSort = (rows: any, selector: any, direction: string) => {
    return rows.sort((rowA: any, rowB: any) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const selectProfile = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,
    (messages, groups) => ({
      loading2: messages.loading,
      groupDetails: groups?.AllGroups,
      mmsLogs: messages.MmsLogs,
      messageLogs: messages.MessageLogs,
    })
  );

  const { loading2, groupDetails, mmsLogs, messageLogs } =
    useSelector(selectProfile);

  const detailData =
    CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE ? mmsLogs : messageLogs;

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { CreatedDate: string }) => row.CreatedDate,
      sortable: true,
      width: "220px",
      cell: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      sortable: true,
      selector: (cell: { direction: any }) => cell.direction,
      cell: (cell: { direction: any }) => {
        switch (cell.direction?.toLowerCase()) {
          case "mt":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-info text-info"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "inbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "outbound-api":
          case "outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-api", ""))}
              </span>
            );
          case "group-outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-", " "))}
              </span>
            );
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.direction) || "N/A"}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) => a.direction.localeCompare(b.direction),
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.sendersPhoneNumber.localeCompare(b.sendersPhoneNumber),
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      selector: (row: { recieversPhoneNumber?: number | string }) =>
        row.recieversPhoneNumber,
      sortable: true,
      cell: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];

        const maxInitialDisplay = 1;
        const hasMoreNumbers = phoneArray.length > maxInitialDisplay;

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phoneArray.slice(0, maxInitialDisplay).map((num, index) => (
              <span key={index}>{num}</span>
            ))}
            {hasMoreNumbers && (
              <>
                <span
                  className="btn btn-link p-0"
                  style={{
                    fontSize: "0.85rem",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  and {phoneArray.length - 1} more
                </span>
                {showModal && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.09)",
                        zIndex: 1040,
                      }}
                      onClick={() => setShowModal(false)}
                    />
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      backdrop={false}
                      style={{ zIndex: 1050 }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {phoneArray.map((num, index) => (
                          <div key={index}>{num}</div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
      sortFunction: (a: any, b: any) => {
        const aNumbers = a.recieversPhoneNumber?.toString().split(",")[0] || "";
        const bNumbers = b.recieversPhoneNumber?.toString().split(",")[0] || "";
        return aNumbers.localeCompare(bNumbers);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => row.messageBody,
      sortable: true,
      cell: (row: { messageBody: string }) => (
        <React.Fragment>
          <div
            className="d-flex align-items-start mb-2"
            style={{ width: 26, height: 14 }}
          >
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body>
                    <div className="mb-3">{row.messageBody}</div>
                  </Popover.Body>
                </Popover>
              }
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
            </OverlayTrigger>
          </div>
        </React.Fragment>
      ),
      sortFunction: (a: any, b: any) =>
        a.messageBody.localeCompare(b.messageBody),
    },
    {
      name: (
        <span className="font-weight-bold fs-sm d-flex align-items-center">
          Segments{" "}
          <Tooltip
            placement="bottomRight"
            overlay={
              "Segments are calculated when a message is Sent. If a message fails prior to entering the “Sent” state, the message segments are not calculated."
            }
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>
        </span>
      ),
      selector: (row: { segments?: string }) => row?.segments || "-",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aSegments = a.segments || "0";
        const bSegments = b.segments || "0";
        return parseInt(aSegments) - parseInt(bSegments);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { latestStatus: any }) => cell.latestStatus,
      cell: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus.replace("mo_message_", ""))}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) =>
        a.latestStatus.localeCompare(b.latestStatus),
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      signalHouseSID: "",
      from:
        CURRENT_LOCATION.split("/")[1] ===
        LocationType.MY_NUMBERS_PAGE.split("/")[1]
          ? initialFrom
          : "",
      to: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      const temp = {
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      };
      setOpen(false);
      handleGetData(1, rowsPerPage, removeEmptyAndNullValues(temp));
    },
  });

  useEffect(() => {
    dispatch(resetMessageLogsList());
  }, []);

  useEffect(() => {
    if (
      CURRENT_LOCATION.split("/")[1] ===
      LocationType.MY_NUMBERS_PAGE.split("/")[1]
    ) {
      setInitialFrom(props.phoneNumber);
    }
  }, [CURRENT_LOCATION, props.phoneNumber]);

  useEffect(() => {
    handleGetData(
      1,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  }, [CURRENT_LOCATION, activeDir]);

  const handleGetData = async (page: number, perPage: number, values?: any) => {
    setLoading(true);

    const dir = activeDir || "All";
    const formattedFrom = values.from
      ? phoneNumberWithPlus.test(values.from)
        ? `%2B${values.from.split("+")[1]}`
        : values.from
      : "";
    const formattedTo = values.to
      ? phoneNumberWithPlus.test(values.to)
        ? `%2B${values.to.split("+")[1]}`
        : values.to
      : "";

    const allParams = {
      ...values,
      direction: dir === "All" ? "" : dir.toLowerCase(),
      from: formattedFrom,
      to: formattedTo,
      createdAt: "",
    };

    try {
      await dispatch(
        CURRENT_LOCATION === LocationType.SMS_LOGS_PAGE
          ? getMessagesThunk(page, perPage, removeEmptyAndNullValues(allParams))
          : CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE
          ? getMmsLogsThunk(page, perPage, removeEmptyAndNullValues(allParams))
          : getPersonalMessagesLogsThunk(
              { page: page, recordsPerPage: perPage },
              props.phoneNumber,
              removeEmptyAndNullValues(allParams)
            )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row: { signalHouseSID: string }) => {
    if (CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE) {
      navigate(`/mms-logs-details/${row.signalHouseSID}`);
    } else if (CURRENT_LOCATION === LocationType.SMS_LOGS_PAGE) {
      navigate(`/sms-logs-details/${row.signalHouseSID}`);
    } else {
      navigate(`/programmable-sms-logs-details/${row.signalHouseSID}`);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div
        className={`mb-4 ${
          CURRENT_LOCATION.split("/")[1] !==
          LocationType.MY_NUMBERS_PAGE.split("/")[1]
            ? "page-content"
            : ""
        }`}
      >
        <Container fluid>
          <BreadCrumb
            title={
              CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE
                ? "MMS Messaging Logs"
                : "Programmable Messaging Logs"
            }
            isFilter={true}
            filterButton={
              <>
                <Dropdown className="card-header-dropdown flex-shrink-0 cursor-pointer">
                  <Dropdown.Toggle
                    as="a"
                    className="text-reset arrow-none mb-0"
                  >
                    <h5 className="mb-0">
                      {activeDir || "All"}{" "}
                      <i className="mdi mdi-chevron-down ms-1" />
                    </h5>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end">
                    {["All", "Inbound", "Outbound"]?.map((dt, i) => (
                      <li key={i}>
                        <Dropdown.Item onClick={() => setActiveDir(dt)}>
                          {dt}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  align="end"
                  className="filterDropDown2"
                  show={open}
                  onToggle={() => {
                    if (!datePickerModal) {
                      setOpen(!open);
                    }
                  }}
                >
                  <Dropdown.Toggle variant="secondary" className="ms-2">
                    <i className="bx bx-filter-alt me-1"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-md p-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">Search</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="Search by Message SID"
                            className="form-control"
                            {...validation.getFieldProps("signalHouseSID")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button className="btn btn-primary mb-3" type="submit">
                          Search
                        </Button>
                      </div>
                      <Row>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="from">From</Form.Label>
                          <input
                            type="number"
                            placeholder="From"
                            className="form-control"
                            {...validation.getFieldProps("from")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="to">To</Form.Label>
                          <input
                            type="number"
                            placeholder="To"
                            className="form-control"
                            {...validation.getFieldProps("to")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="subGroupId">
                            Sub Group
                          </Form.Label>
                          <AsyncPaginate
                            isDisabled={
                              !groupDetails?.records?.[0]?.group_id || ""
                            }
                            key={groupDetails?.records?.[0]?.group_id || ""}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.subGroupId &&
                                validation.errors?.subGroupId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.subGroupId?.length > 0
                                ? validation.values.subGroupId.map(
                                    (dt: string) => ({
                                      sub_group_id: dt,
                                    })
                                  )
                                : null
                            }
                            loadOptions={asyncSubgroupList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                              }),
                              "subGroupId"
                            )}
                            getOptionValue={(option: any) =>
                              option?.sub_group_id
                            }
                            getOptionLabel={(option: any) =>
                              option?.sub_group_id
                            }
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.map((dt: any) => dt.sub_group_id)
                                );
                                validation.setFieldValue("brandId", []);
                                validation.setFieldValue("campaignId", []);
                              } else {
                                validation.setFieldValue("subGroupId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="brandId">Brand</Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.subGroupId.toString() ||
                              groupDetails?.records?.[0]?.group_id ||
                              "brandId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.brandId &&
                                validation.errors?.brandId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.brandId?.length > 0
                                ? validation.values.brandId
                                : null
                            }
                            loadOptions={asyncBrandList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId:
                                  validation.values.subGroupId.length > 0
                                    ? validation.values.subGroupId
                                    : "",
                              }),
                              "brandId"
                            )}
                            getOptionValue={(option: any) => option?.brandId}
                            getOptionLabel={(option: any) => option?.brandId}
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.reduce(
                                    (acc: string[], dt: any) => {
                                      if (
                                        dt?.subGroupId &&
                                        Array.isArray(dt.subGroupId)
                                      ) {
                                        dt.subGroupId.forEach(
                                          (subGroup: string) => {
                                            if (
                                              !acc.includes(subGroup) &&
                                              !validation.values.subGroupId.includes(
                                                subGroup
                                              )
                                            ) {
                                              acc.push(subGroup);
                                            }
                                          }
                                        );
                                      }
                                      return acc;
                                    },
                                    [...validation.values.subGroupId]
                                  )
                                );
                                validation.setFieldValue(
                                  "brandId",
                                  option.map((dt: any) => ({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  }))
                                );
                                validation.setFieldValue("campaignId", []);
                              } else {
                                validation.setFieldValue("brandId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.brandId
                                ?.map((dt: any) => dt.brandId)
                                .toString() ||
                              validation.values.subGroupId.toString() ||
                              groupDetails?.records?.[0]?.group_id ||
                              "campaignId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.campaignId &&
                                validation.errors?.campaignId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.campaignId?.length > 0
                                ? validation.values.campaignId
                                : null
                            }
                            loadOptions={asyncCampaignList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId:
                                  validation.values.subGroupId.length > 0
                                    ? validation.values.subGroupId
                                    : "",
                                brandId:
                                  validation.values.brandId.length > 0
                                    ? validation.values.brandId?.map(
                                        (dt: any) => dt.brandId
                                      )
                                    : "",
                              }),
                              "campaignId"
                            )}
                            getOptionValue={(option: any) => option?.campaignId}
                            getOptionLabel={(option: any) => option?.campaignId}
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.reduce(
                                    (acc: string[], dt: any) => {
                                      if (
                                        dt?.subGroupId &&
                                        Array.isArray(dt.subGroupId)
                                      ) {
                                        dt.subGroupId.forEach(
                                          (subGroup: string) => {
                                            if (
                                              !acc.includes(subGroup) &&
                                              !validation.values.subGroupId.includes(
                                                subGroup
                                              )
                                            ) {
                                              acc.push(subGroup);
                                            }
                                          }
                                        );
                                      }
                                      return acc;
                                    },
                                    [...validation.values.subGroupId]
                                  )
                                );
                                validation.setFieldValue(
                                  "brandId",
                                  option.reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.brandId.find(
                                          (item: any) =>
                                            item.brandId === dt.brandId &&
                                            JSON.stringify(item.subGroupId) ===
                                              JSON.stringify(dt.subGroupId)
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          subGroupId: dt?.subGroupId || [],
                                          brandId: dt?.brandId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.brandId]
                                  )
                                );
                                validation.setFieldValue(
                                  "campaignId",
                                  option.map((dt: any) => ({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                    campaignId: dt?.campaignId || "",
                                  }))
                                );
                              } else {
                                validation.setFieldValue("campaignId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="dateRange">
                            Date Range
                          </Form.Label>
                          <Select
                            isClearable={true}
                            styles={styleObj(false)}
                            theme={customSelectTheme}
                            options={dateRangeArr}
                            onChange={(e: any) => {
                              setDateRangeTemp(e);
                              let startDate = "";
                              let endDate = moment()
                                .endOf("day")
                                .format("MM-DD-YYYY");

                              if (e?.value === "24 hours") {
                                startDate = moment()
                                  .subtract(1, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "7 days") {
                                startDate = moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "30 days") {
                                startDate = moment()
                                  .subtract(30, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "120 days") {
                                startDate = moment()
                                  .subtract(120, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else {
                                endDate = "";
                              }

                              validation.setFieldValue("createdAt", "");
                              validation.setFieldValue("startDate", startDate);
                              validation.setFieldValue("endDate", endDate);
                            }}
                            value={dateRangeTemp}
                          />
                        </Col>
                        {dateRangeTemp?.value === "Custom" ? (
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="createdAt">
                              Select Range
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="createdAt"
                              placeholder="Select Range"
                              options={{
                                enableTime: false,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                altFormat: "F j, Y",
                                dateFormat: "m-d-Y",
                                mode: "range",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  validation.setFieldValue(
                                    "createdAt",
                                    dateStr || ""
                                  );
                                  value?.[0] &&
                                    validation.setFieldValue(
                                      "startDate",
                                      moment(value?.[0]).format("MM-DD-YYYY")
                                    );
                                  value?.[1] &&
                                    validation.setFieldValue(
                                      "endDate",
                                      moment(value?.[1]).format("MM-DD-YYYY")
                                    );
                                },
                              }}
                              value={validation.values.createdAt || ""}
                            />
                          </Col>
                        ) : null}
                      </Row>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          disabled={loading2}
                          onClick={() => {
                            handleGetData(
                              currPage,
                              rowsPerPage,
                              removeEmptyAndNullValues({
                                ...validation.initialValues,
                                subGroupId: "",
                                brandId: "",
                                campaignId: "",
                              })
                            );
                            validation.resetForm();
                            setDatePickerModal(false);
                            setDateRangeTemp({
                              label: "",
                              value: "",
                            });
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading2}
                        >
                          {loading2 && <Spinner size="sm" animation="border" />}{" "}
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            }
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(detailData.records)
                        ? detailData.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={detailData?.totalRecords}
                    customSort={customSort}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(SmsAndMmsLogs);
