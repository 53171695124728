import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getSubgroupListThunk } from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import {
  asyncActivePhoneNumberList,
  asyncBrandList,
  asyncCampaignList,
  asyncPortRequestList,
  asyncSubgroupList,
  capitalizeString,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
// import maintain from "assets/images/auth/maintenance.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { subgroupListApi } from "helpers/apiroute_helper";

const SubGroupsList = (props: any) => {
  document.title = "Signal House Portal Sub Groups List";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading4: groups.loading4,
      subgroupsData: groups.subgroupsData,
      error: groups.error,
      groupDetails: groups.AllGroups,
    })
  );

  const { loading4, subgroupsData, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Sub Group ID</span>,
      selector: (row: { sub_group_id: string }) => row.sub_group_id,
      sortable: true,
      sortFunction: (
        a: { sub_group_id: string },
        b: { sub_group_id: string }
      ) => a.sub_group_id.localeCompare(b.sub_group_id),
    },
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { sub_group_name: string }) => row.sub_group_name,
      sortable: true,
      sortFunction: (
        a: { sub_group_name: string },
        b: { sub_group_name: string }
      ) => a.sub_group_name.localeCompare(b.sub_group_name),
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (row: { status: string }) => row.status,
      sortable: true,
      sortFunction: (a: { status: string }, b: { status: string }) =>
        a.status.localeCompare(b.status),
      cell: (row: { status: string }) => {
        switch (row.status) {
          case "Active":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-secondary-subtle text-secondary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-primary-subtle text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                {capitalizeString(String(row.status) || "Inactive")}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      selector: (row: { created_datetime: string }) => row.created_datetime,
      sortable: true,
      sortFunction: (
        a: { created_datetime: string },
        b: { created_datetime: string }
      ) =>
        new Date(a.created_datetime).getTime() -
        new Date(b.created_datetime).getTime(),
      cell: (row: { created_datetime: string }) => (
        <span className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.created_datetime)}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (row: { sub_group_id: string }) => {
        return (
          <>
            <Button
              className="btn btn-sm"
              variant="outline-secondary"
              onClick={() =>
                props.router.navigate(
                  `/settings/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}/edit`
                )
              }
            >
              Edit
            </Button>
          </>
        );
      },
      sortable: false,
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      subGroupName: [],
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      portingId: "",
      status: "",
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      handleGetData(
        1,
        rowsPerPage,
        removeEmptyAndNullValues({
          ...values,
          subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
          subGroupName:
            values.subGroupName.length > 0
              ? values.subGroupName?.map((dt: any) => dt.sub_group_name)
              : "",
          brandId:
            values.brandId.length > 0
              ? values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            values.campaignId.length > 0
              ? values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          phoneNumber:
            values.phoneNumber.length > 0
              ? values.phoneNumber?.map((dt: any) => dt.phoneNumber)
              : "",
          createdAt: "",
          startDate: values.startDate
            ? moment(values.startDate, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          endDate: values.endDate
            ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
            : "",
        })
      );
    },
  });

  useEffect(() => {
    if (
      props?.router?.params?.groupId &&
      props?.router?.params?.groupId !== "undefined"
    ) {
      handleGetData(currPage, rowsPerPage);
    }
  }, [props?.router?.params?.groupId]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getSubgroupListThunk(page, perPage, {
        groupId: props?.router?.params?.groupId,
        ...otherParams,
      })
    );
  };

  const handleRowClick = (row: { sub_group_id: string }) => {
    props.router.navigate(
      `/settings/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}`
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        subGroupName:
          validation.values.subGroupName.length > 0
            ? validation.values.subGroupName?.map(
                (dt: any) => dt.sub_group_name
              )
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        subGroupName:
          validation.values.subGroupName.length > 0
            ? validation.values.subGroupName?.map(
                (dt: any) => dt.sub_group_name
              )
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        createdAt: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const fetchSubGroupNames = async (ids: any) => {
    if (ids.length > 0) {
      try {
        let data: any = await subgroupListApi(
          1,
          100,
          removeEmptyAndNullValues({
            groupId: props?.router?.params?.groupId,
            subGroupId: ids.length > 0 ? ids : "",
          })
        );

        if (!data?.code && !data?.errorCode) {
          const subGroupNames = data?.records?.map((dt: any) => ({
            sub_group_name: dt?.sub_group_name || "",
            sub_group_id: dt?.sub_group_id || "",
          }));
          validation.setFieldValue("subGroupName", subGroupNames);
        }
      } catch (error) {
        console.error("Error fetching sub group names:", error);
      }
    } else {
      validation.setFieldValue("subGroupName", []);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={`${groupDetails?.records?.[0]?.group_name || ""} ${
            groupDetails?.records?.[0]?.group_id
              ? `(${groupDetails?.records?.[0]?.group_id})`
              : ""
          }`}
          isFilter={true}
          filterButton={
            <Dropdown
              align="end"
              className="ms-2 filterDropDown2"
              show={open}
              onToggle={() => {
                if (!datePickerModal) {
                  setOpen(!open);
                }
              }}
            >
              <Dropdown.Toggle variant="secondary">
                <i className="bx bx-filter-alt me-1" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-md p-4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="subGroupId">Sub Group Id</Form.Label>
                      <AsyncPaginate
                        isDisabled={!groupDetails?.records?.[0]?.group_id}
                        key={groupDetails?.records?.[0]?.group_id}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.subGroupId &&
                            validation?.errors?.subGroupId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.subGroupId?.length > 0
                            ? validation.values.subGroupId.map(
                                (dt: string) => ({
                                  sub_group_id: dt,
                                })
                              )
                            : null
                        }
                        name="subGroupId"
                        loadOptions={asyncSubgroupList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                          }),
                          "subGroupId"
                        )}
                        getOptionValue={(option: any) => option?.sub_group_id}
                        getOptionLabel={(option: any) => option?.sub_group_id}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.sub_group_id !== validation.values.subGroupId
                          ) {
                            validation.setFieldValue(
                              "subGroupId",
                              option.map((dt: any) => dt.sub_group_id)
                            );
                            fetchSubGroupNames(
                              option.map((dt: any) => dt.sub_group_id)
                            );
                            validation.setFieldValue("brandId", []);
                            validation.setFieldValue("campaignId", []);
                            validation.setFieldValue("phoneNumber", []);
                          } else if (!option) {
                            validation.setFieldValue("subGroupId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="subGroupName">
                        Sub Group Name
                      </Form.Label>
                      <AsyncPaginate
                        isDisabled={!groupDetails?.records?.[0]?.group_id}
                        key={groupDetails?.records?.[0]?.group_id}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.subGroupName &&
                            validation?.errors?.subGroupName
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.subGroupName?.length > 0
                            ? validation.values.subGroupName
                            : null
                        }
                        name="subGroupName"
                        loadOptions={asyncSubgroupList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                          }),
                          "subGroupName"
                        )}
                        getOptionValue={(option: any) => option?.sub_group_name}
                        getOptionLabel={(option: any) => option?.sub_group_name}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.sub_group_name !==
                              validation.values.subGroupName
                          ) {
                            validation.setFieldValue(
                              "subGroupId",
                              option.map((dt: any) => dt.sub_group_id)
                            );
                            validation.setFieldValue(
                              "subGroupName",
                              option?.map((dt: any) => ({
                                sub_group_name: dt?.sub_group_name || "",
                                sub_group_id: dt?.sub_group_id || "",
                              }))
                            );
                            validation.setFieldValue("brandId", []);
                            validation.setFieldValue("campaignId", []);
                            validation.setFieldValue("phoneNumber", []);
                          } else if (!option) {
                            validation.setFieldValue("subGroupName", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="brandId">Brand</Form.Label>
                      <AsyncPaginate
                        key={
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "brandId"
                        }
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.brandId &&
                            validation?.errors?.brandId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.brandId?.length > 0
                            ? validation.values.brandId
                            : null
                        }
                        loadOptions={asyncBrandList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                          }),
                          "brandId"
                        )}
                        getOptionValue={(option: any) => option?.brandId}
                        getOptionLabel={(option: any) => option?.brandId}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.brandId === validation.values.brandId
                          ) {
                            return;
                          }
                          if (option) {
                            const tempSubgrpIds = option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            );
                            validation.setFieldValue(
                              "subGroupId",
                              tempSubgrpIds
                            );
                            fetchSubGroupNames(tempSubgrpIds);
                            validation.setFieldValue(
                              "brandId",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                              }))
                            );
                            validation.setFieldValue("campaignId", []);
                            validation.setFieldValue("phoneNumber", []);
                          } else {
                            validation.setFieldValue("brandId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                      <AsyncPaginate
                        key={
                          validation.values.brandId
                            ?.map((dt: any) => dt.brandId)
                            .toString() ||
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "campaignId"
                        }
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.campaignId &&
                            validation?.errors?.campaignId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.campaignId?.length > 0
                            ? validation.values.campaignId
                            : null
                        }
                        loadOptions={asyncCampaignList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                            brandId:
                              validation.values.brandId.length > 0
                                ? validation.values.brandId?.map(
                                    (dt: any) => dt.brandId
                                  )
                                : "",
                          }),
                          "campaignId"
                        )}
                        getOptionValue={(option: any) => option?.campaignId}
                        getOptionLabel={(option: any) => option?.campaignId}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.campaignId === validation.values.campaignId
                          ) {
                            return;
                          }
                          if (option) {
                            const tempSubgrpIds = option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            );
                            validation.setFieldValue(
                              "subGroupId",
                              tempSubgrpIds
                            );
                            fetchSubGroupNames(tempSubgrpIds);
                            validation.setFieldValue(
                              "brandId",
                              option.reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.brandId.find(
                                      (item: any) =>
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.brandId]
                              )
                            );
                            validation.setFieldValue(
                              "campaignId",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                                campaignId: dt?.campaignId || "",
                              }))
                            );
                            validation.setFieldValue("phoneNumber", []);
                          } else {
                            validation.setFieldValue("campaignId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="phoneNumber">
                        Phone Number
                      </Form.Label>
                      <AsyncPaginate
                        key={
                          validation.values.campaignId
                            ?.map((dt: any) => dt.campaignId)
                            .toString() ||
                          validation.values.brandId
                            ?.map((dt: any) => dt.brandId)
                            .toString() ||
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "phoneNumber"
                        }
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.phoneNumber &&
                            validation?.errors?.phoneNumber
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.phoneNumber
                            ? validation.values.phoneNumber
                            : null
                        }
                        loadOptions={asyncActivePhoneNumberList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                            brandId:
                              validation.values.brandId.length > 0
                                ? validation.values.brandId?.map(
                                    (dt: any) => dt.brandId
                                  )
                                : "",
                            campaignId:
                              validation.values.campaignId.length > 0
                                ? validation.values.campaignId?.map(
                                    (dt: any) => dt.campaignId
                                  )
                                : "",
                          }),
                          "phoneNumber"
                        )}
                        getOptionValue={(option: any) => option?.phoneNumber}
                        getOptionLabel={(option: any) => option?.phoneNumber}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.phoneNumber === validation.values.phoneNumber
                          ) {
                            return;
                          }

                          if (option) {
                            const tempSubgrpIds = option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            );
                            validation.setFieldValue(
                              "subGroupId",
                              tempSubgrpIds
                            );
                            fetchSubGroupNames(tempSubgrpIds);
                            validation.setFieldValue(
                              "brandId",
                              option.reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.brandId.find(
                                      (item: any) =>
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.brandId]
                              )
                            );
                            validation.setFieldValue(
                              "campaignId",
                              option.reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.campaignId.find(
                                      (item: any) =>
                                        item.campaignId === dt.campaignId &&
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                      campaignId: dt.campaignId || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.campaignId]
                              )
                            );
                            validation.setFieldValue(
                              "phoneNumber",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                                campaignId: dt.campaignId || "",
                                phoneNumber: dt?.phoneNumber || "",
                              }))
                            );
                          } else {
                            validation.setFieldValue("phoneNumber", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="portingId">Porting Id</Form.Label>
                      <AsyncPaginate
                        key={
                          validation.values.phoneNumber
                            ?.map((dt: any) => dt.phoneNumber)
                            .toString() ||
                          validation.values.campaignId
                            ?.map((dt: any) => dt.campaignId)
                            .toString() ||
                          validation.values.brandId
                            ?.map((dt: any) => dt.brandId)
                            .toString() ||
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "portingId"
                        }
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.portingId &&
                            validation?.errors?.portingId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.portingId
                            ? { portingId: validation.values.portingId }
                            : null
                        }
                        loadOptions={asyncPortRequestList(
                          removeEmptyAndNullValues({
                            // groupId: groupDetails?.records?.[0]?.group_id || "",
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                            brandId:
                              validation.values.brandId.length > 0
                                ? validation.values.brandId?.map(
                                    (dt: any) => dt.brandId
                                  )
                                : "",
                            campaignId:
                              validation.values.campaignId.length > 0
                                ? validation.values.campaignId?.map(
                                    (dt: any) => dt.campaignId
                                  )
                                : "",
                            phoneNumber:
                              validation.values.phoneNumber.length > 0
                                ? validation.values.phoneNumber?.map(
                                    (dt: any) => dt.phoneNumber
                                  )
                                : "",
                          }),
                          "phoneNumber"
                        )}
                        getOptionValue={(option: any) => option?.portingId}
                        getOptionLabel={(option: any) => option?.portingId}
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.portingId === validation.values.portingId
                          ) {
                            return;
                          }

                          if (option) {
                            const tempSubgrpIds = [option].reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            );
                            validation.setFieldValue(
                              "subGroupId",
                              tempSubgrpIds
                            );
                            fetchSubGroupNames(tempSubgrpIds);
                            validation.setFieldValue(
                              "brandId",
                              [option].reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.brandId.find(
                                      (item: any) =>
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.brandId]
                              )
                            );
                            validation.setFieldValue(
                              "campaignId",
                              [option].reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.campaignId.find(
                                      (item: any) =>
                                        item.campaignId === dt.campaignId &&
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                      campaignId: dt.campaignId || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.campaignId]
                              )
                            );
                            // validation.setFieldValue(
                            //   "phoneNumber",
                            //   option.phoneNumber
                            // );
                            validation.setFieldValue(
                              "portingId",
                              option.portingId
                            );
                          } else {
                            validation.setFieldValue("portingId", "");
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="status">Status</Form.Label>
                      <Select
                        name="status"
                        isClearable={true}
                        styles={styleObj(
                          validation?.touched?.status &&
                            validation?.errors?.status
                        )}
                        theme={customSelectTheme}
                        options={[
                          {
                            label: "Active",
                            value: "Active",
                          },
                          {
                            label: "Inactive",
                            value: "Inactive",
                          },
                        ]}
                        onChange={(e: { value: string }) => {
                          if (e) {
                            validation.setFieldValue("status", e.value);
                          } else {
                            validation.setFieldValue("status", "");
                          }
                        }}
                        value={
                          validation.values.status
                            ? {
                                label: validation.values.status,
                                value: validation.values.status,
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="createdAt">Created At</Form.Label>
                      <Flatpickr
                        className="form-control"
                        name="createdAt"
                        placeholder="Created At"
                        options={{
                          enableTime: false,
                          onOpen: () => {
                            setDatePickerModal(true);
                          },
                          onClose: () => {
                            setDatePickerModal(false);
                          },
                          altFormat: "F j, Y",
                          dateFormat: "m-d-Y",
                          mode: "range",
                          onChange: (
                            value: any,
                            dateStr: string,
                            instance: any
                          ) => {
                            validation.setFieldValue(
                              "createdAt",
                              dateStr || ""
                            );
                            value?.[0] &&
                              validation.setFieldValue(
                                "startDate",
                                moment(value?.[0]).format("MM-DD-YYYY")
                              );
                            value?.[1] &&
                              validation.setFieldValue(
                                "endDate",
                                moment(value?.[1]).format("MM-DD-YYYY")
                              );
                          },
                        }}
                        value={validation.values.createdAt || ""}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      className="btn btn-secondary me-2"
                      type="button"
                      disabled={loading4}
                      onClick={() => {
                        handleGetData(1, rowsPerPage);
                        validation.resetForm();
                        setDatePickerModal(false);
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading4}
                    >
                      {loading4 && <Spinner size="sm" animation="border" />}{" "}
                      Apply
                    </Button>
                  </div>
                </Form>
              </Dropdown.Menu>
            </Dropdown>
          }
          isCreate={
            props?.router?.params?.groupId &&
            props?.router?.params?.groupId !== "undefined"
              ? true
              : false
          }
          createLabel="Create Sub Group"
          createLink={`/settings/groups/${props?.router?.params?.groupId}/subgroups/create`}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {groupDetails?.records?.[0]?.group_id &&
              props?.router?.params?.groupId !== "undefined" ? (
                loading4 ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(subgroupsData?.records)
                        ? subgroupsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={subgroupsData?.totalRecords}
                  />
                )
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
                  {/* <div className="">
                    <img src={maintain} alt="" height={100} />
                  </div> */}
                  <div
                  // className="mt-4 pt-3"
                  >
                    <h5>Please complete profile to see sub groups.</h5>
                    <Link to={"/settings/general"}>Go to profile page</Link>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(SubGroupsList);
