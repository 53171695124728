import React from "react";
import { Card, Col, OverlayTrigger, Popover, Container } from "react-bootstrap";
import Tooltip from "rc-tooltip";
import Datatable from "../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, dateFormat } from "helpers/common";

const SmsAndMmsLogs = ({
  loading,
  rowsPerPage,
  currPage,
  logsData,
  handleRowClick,
  handlePageChange,
  handleRowsPerPageChange,
}: any) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date and Time</span>,
      selector: (row: { CreatedDate: string }) => row.CreatedDate,
      sortable: true,
      width: "220px",
      cell: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      selector: (cell: { direction: any }) => cell.direction,
      sortable: true,
      cell: (cell: { direction: any }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-primary text-primary"
        >
          {capitalizeString(cell.direction) || "N/A"}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      selector: (row: { recieversPhoneNumber?: number | string }) =>
        row.recieversPhoneNumber,
      sortable: true,
      cell: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];
        // Format the output
        let formattedNumbers =
          phoneArray.length > 1
            ? `${phoneArray[0]} and ${phoneArray.length - 1} more`
            : phoneArray[0];

        // Tooltip content
        const tooltipContent = phoneArray.join(", ");

        return (
          <Tooltip placement="top" overlay={tooltipContent}>
            <span className="phone-numbers" style={{ cursor: "pointer" }}>
              {formattedNumbers}
            </span>
          </Tooltip>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => row.messageBody,
      cell: (row: { messageBody: string }) => (
        <React.Fragment>
          <div
            className="d-flex align-items-start mb-2"
            style={{ width: 26, height: 14 }}
          >
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body>
                    <div>
                      <Col>
                        {row.messageBody}
                        <span className="text-primary">*</span>
                      </Col>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
            </OverlayTrigger>
          </div>
        </React.Fragment>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Segments</span>,
      selector: (row: { segments?: string }) => row?.segments ?? "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { latestStatus: any }) => cell.latestStatus,
      cell: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                <b>{capitalizeString(cell.latestStatus.replace("mo_message_", ""))}</b>
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) =>
        a.latestStatus.localeCompare(b.latestStatus),
    },
  ];

  return (
    <div className="position-relative">
      {loading ? (
        <div style={{ height: 60 }}>
          <img
            src={Loader}
            className={`position-absolute top-50 start-50 translate-middle`}
            alt="loading"
          />
        </div>
      ) : (
        <Datatable
          data={Array.isArray(logsData?.records) ? logsData?.records : []}
          columns={columns}
          handleRowClick={handleRowClick}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          currPage={currPage}
          rowsPerPage={rowsPerPage}
          totalRecords={logsData?.totalRecords}
        />
      )}
    </div>
  );
};
export default SmsAndMmsLogs;
