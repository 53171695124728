import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import CountUp from "react-countup";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
  getSmsStatusLogsThunk,
  getMmsStatusLogsThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import { removeEmptyAndNullValues } from "helpers/common";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import StackedColumn2 from "./StackedColumn2";
import _ from "lodash";
import LineChart from "./LineChart";
import SmsAndMmsLogs from "./SmsAndMmsLogs";
import withRouter from "Common/withRouter";
import AnalyticsHeader from "./AnalyticsHeader";
import { useSearchParams } from "react-router-dom";

const dateRangeArr = [
  {
    label: "24 hours",
    value: "24 hours",
  },
  {
    label: "7 days",
    value: "7 days",
  },
  {
    label: "30 days",
    value: "30 days",
  },
  {
    label: "120 days",
    value: "120 days",
  },
  {
    label: "Custom (full custom range)",
    value: "Custom",
  },
];

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound", "Inbound"];

function convertSeconds(milliseconds: number, showString?: boolean) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return showString
    ? hours
      ? `${hours} hour, ${minutes} minutes, and ${seconds} seconds`
      : `${minutes} minutes, and ${seconds} seconds`
    : `${hours}h:${minutes}m:${seconds}s`;
}

const Analytics = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  document.title = "Signal House Portal Analytics";

  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      loading: analytics.loading,
      loading2: analytics.loading2,
      analyticsAllV1: analytics.analyticsAllV1,
      smsStatusLogs: analytics.smsStatusLogs,
      mmsStatusLogs: analytics.mmsStatusLogs,
      error: analytics.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    loading2,
    analyticsAllV1,
    smsStatusLogs,
    mmsStatusLogs,
    groupDetails,
  } = useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[1]);
  const [selectedSubType, setSelectedSubType] = useState("totalMessagesSent");
  const [selectedSubTypeLabel, setSelectedSubTypeLabel] = useState(
    "Total messages sent"
  );
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [cardObj, setCardObj] = useState<any>({});
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [numberGraphObj, setNumberGraphObj] = useState<any>({});
  const [graphType1, setGraphType1] = useState("Column");
  const [graphType2, setGraphType2] = useState("Column");
  const [currPageSms, setCurrPageSms] = useState(1);
  const [rowsPerPageSms, setRowsPerPageSms] = useState(10);
  const [currPageMms, setCurrPageMms] = useState(1);
  const [rowsPerPageMms, setRowsPerPageMms] = useState(10);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      tag: [],
      createdAt: "",
      startDate: moment().subtract(7, "days").startOf("day").toISOString(),
      endDate: moment().endOf("day").format("MM-DD-YYYY"),
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      const temp = {
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          values.phoneNumber.length > 0
            ? values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag: values.tag.length > 0 ? values.tag?.map((dt: any) => dt.tag) : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      };
      setOpen(!open);
      handleGetData(temp);
    },
  });

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const createdAt = `${moment()
        .subtract(7, "days")
        .startOf("day")
        .format("MM-DD-YYYY")} to ${moment()
        .endOf("day")
        .format("MM-DD-YYYY")}`;
      const startDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .toISOString();
      const endDate = moment().endOf("day").format("MM-DD-YYYY");

      handleGetData({
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        endDate: endDate
          ? moment(endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      });
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("createdAt", createdAt);
      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getCardData();
      getDateGraphData();
      getNumberGraphData();
    }
  }, [analyticsAllV1]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getDateGraphData();
      getNumberGraphData();
    }
  }, [selectedType]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const params = {
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        endDate: moment(validation.values.endDate, "MM-DD-YYYY")
          .endOf("day")
          .toISOString(),
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      };
      setCurrPageSms(1);
      setRowsPerPageSms(10);
      setCurrPageMms(1);
      setRowsPerPageMms(10);
      handleSmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
      handleMmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    }
  }, [groupDetails?.records?.[0]?.group_id, selectedSubType]);

  const getCardData = () => {
    let date = moment(validation.values?.endDate, "MM-DD-YYYY")?.diff(
      moment(validation.values?.startDate, "MM-DD-YYYY"),
      "days"
    );
    let dateDiff =
      validation.values?.endDate && validation.values?.startDate
        ? isNaN(date)
          ? 1
          : date
        : 1;

    const smsTotal = Object.keys(analyticsAllV1.SMS).reduce(
      (acc: any, date: any) => {
        const dateData = analyticsAllV1.SMS[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      },
      {}
    );

    const mmsTotal = Object.keys(analyticsAllV1.MMS).reduce(
      (acc: any, date: any) => {
        const dateData = analyticsAllV1.MMS[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      },
      {}
    );

    const calculateResponseRate = () => {
      const groupDataByPhoneNumber = (
        data: Record<string, Record<string, Record<string, number>>>
      ) => {
        return Object.keys(data).reduce(
          (acc: Record<string, Record<string, number>>, date) => {
            const dateData = data[date];
            Object.keys(dateData).forEach((phoneNumber) => {
              if (!acc[phoneNumber]) {
                acc[phoneNumber] = {};
              }
              Object.keys(dateData[phoneNumber]).forEach((key) => {
                acc[phoneNumber][key] =
                  (acc[phoneNumber][key] || 0) + dateData[phoneNumber][key];
              });
            });
            return acc;
          },
          {}
        );
      };

      const smsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.SMS);
      const mmsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.MMS);
      const allDataByPhoneNumber: any = Object.keys({
        ...smsDataByPhoneNumber,
        ...mmsDataByPhoneNumber,
      }).reduce((acc: any, phoneNumber: any) => {
        acc[phoneNumber] = {};
        const smsData = smsDataByPhoneNumber[phoneNumber] || {};
        const mmsData = mmsDataByPhoneNumber[phoneNumber] || {};
        const allKeys = new Set([
          ...Object.keys(smsData),
          ...Object.keys(mmsData),
        ]);
        allKeys.forEach((key) => {
          acc[phoneNumber][key] = (smsData[key] || 0) + (mmsData[key] || 0);
        });
        return acc;
      }, {});

      const calculateRateForType = (
        data: Record<string, Record<string, number>>,
        total: any
      ) => {
        let respondedNumbers = 0;

        Object.keys(data).forEach((phoneNumber) => {
          const phoneData = data[phoneNumber];

          // Check if the number has any inbound messages
          if (phoneData.totalInbound > 0 && phoneData.delivered > 0) {
            // Check if all inbound messages are opt-outs
            if (phoneData.totalInbound > phoneData.totalOptOut) {
              respondedNumbers++;
            }
          }
        });

        return total > 0 ? (respondedNumbers / total) * 100 : 0;
      };

      const smsResponseRate = calculateRateForType(
        smsDataByPhoneNumber,
        smsTotal?.delivered
      );

      const mmsResponseRate = calculateRateForType(
        mmsDataByPhoneNumber,
        mmsTotal?.delivered
      );

      // Calculate total response rate

      const totalResponseRate = calculateRateForType(
        allDataByPhoneNumber,
        smsTotal?.delivered + mmsTotal?.delivered
      );

      // Update the cardObj with new response rates
      return {
        smsrr: smsResponseRate,
        mmsrr: mmsResponseRate,
        allrr: totalResponseRate,
      };
    };

    const responseRateData = calculateResponseRate();

    const totalSmsSent =
      (smsTotal?.delivered || 0) +
      (smsTotal?.sent || 0) +
      (smsTotal?.failed || 0);

    smsTotal["deliveryRate"] =
      (100 * smsTotal?.delivered) / (totalSmsSent || 1);
    smsTotal["failedRate"] = (100 * smsTotal?.failed) / (totalSmsSent || 1);
    smsTotal["sentRate"] = (100 * smsTotal?.sent) / (totalSmsSent || 1);
    smsTotal["totalMessagesSent"] = totalSmsSent;
    smsTotal["averageMessagesSent"] = totalSmsSent / dateDiff;
    smsTotal["averageMessagesReceived"] = smsTotal?.totalInbound / dateDiff;
    smsTotal["averageSegmentsSent"] = smsTotal?.segmentSent / dateDiff;
    smsTotal["optOutRate"] =
      (100 * smsTotal?.totalOptOut) / (smsTotal?.totalInbound || 1);
    smsTotal["averageSegmentReceived"] = smsTotal?.segmentRecieved / dateDiff;
    smsTotal["responseRate"] = responseRateData?.smsrr;

    const totalMmsSent =
      (mmsTotal?.delivered || 0) +
      (mmsTotal?.sent || 0) +
      (mmsTotal?.failed || 0);

    mmsTotal["deliveryRate"] =
      (100 * mmsTotal?.delivered) / (totalMmsSent || 1);
    mmsTotal["failedRate"] = (100 * mmsTotal?.failed) / (totalMmsSent || 1);
    mmsTotal["sentRate"] = (100 * mmsTotal?.sent) / (totalMmsSent || 1);
    mmsTotal["totalMessagesSent"] = totalMmsSent;
    mmsTotal["averageMessagesSent"] = totalMmsSent / dateDiff;
    mmsTotal["averageMessagesReceived"] = mmsTotal?.totalInbound / dateDiff;
    mmsTotal["averageSegmentsSent"] = mmsTotal?.segmentSent / dateDiff;
    mmsTotal["optOutRate"] =
      (100 * mmsTotal?.totalOptOut) / (mmsTotal?.totalInbound || 1);
    mmsTotal["averageSegmentReceived"] = mmsTotal?.segmentRecieved / dateDiff;
    mmsTotal["responseRate"] = responseRateData?.mmsrr;

    const allTotal = Object.keys(smsTotal).reduce((acc: any, key: any) => {
      acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
      return acc;
    }, {});

    const totalAllSent =
      (allTotal?.delivered || 0) +
      (allTotal?.sent || 0) +
      (allTotal?.failed || 0);

    allTotal["deliveryRate"] =
      (100 * allTotal?.delivered) / (totalAllSent || 1);
    allTotal["failedRate"] = (100 * allTotal?.failed) / (totalAllSent || 1);
    allTotal["sentRate"] = (100 * allTotal?.sent) / (totalAllSent || 1);
    allTotal["totalMessagesSent"] = totalAllSent;
    allTotal["averageMessagesSent"] = totalAllSent / dateDiff;
    allTotal["averageMessagesReceived"] = allTotal?.totalInbound / dateDiff;
    allTotal["averageSegmentsSent"] = allTotal?.segmentSent / dateDiff;
    allTotal["optOutRate"] =
      (100 * allTotal?.totalOptOut) / (allTotal?.totalInbound || 1);
    allTotal["averageSegmentReceived"] = allTotal?.segmentRecieved / dateDiff;
    allTotal["responseRate"] = responseRateData?.allrr;

    const tempObj = {
      SMS: { ...smsTotal },
      MMS: { ...mmsTotal },
      All: { ...allTotal },
    };
    setCardObj({ ...tempObj });
  };

  const getDateGraphData = () => {
    const mergeData = (data1: any, data2?: any) => {
      const mergedData = _.mergeWith({}, data1, data2, (objValue, srcValue) => {
        if (_.isObject(objValue) && _.isObject(srcValue)) {
          return _.mergeWith(
            {},
            objValue,
            srcValue,
            (innerObjValue, innerSrcValue) => {
              if (_.isNumber(innerObjValue) && _.isNumber(innerSrcValue)) {
                return innerObjValue + innerSrcValue;
              }
            }
          );
        }
      });

      // Merge data phone number wise

      // Calculate response rate based on per-number non-opt out responses
      // let totalNumbers = 0;
      // let respondedNumbers = 0;

      // Object.keys(mergedPhoneData).forEach((phoneNumber) => {
      //   const phoneStats = mergedPhoneData[phoneNumber];
      //   totalNumbers++;

      //   if (phoneStats.totalInbound > 0 && phoneStats.totalOptOut === 0) {
      //     respondedNumbers++;
      //   }
      // });

      // const responseRate =
      //   totalNumbers > 0
      //     ? Math.min((respondedNumbers / totalNumbers) * 100, 100)
      //     : 0;

      // Add the calculated response rate to the merged data
      // Object.keys(mergedData).forEach((date) => {
      //   mergedData[date].responseRate = responseRate;
      // });

      // Merge phone number data for each date and calculate new fields
      Object.keys(mergedData).forEach((date) => {
        const phoneData = mergedData[date];
        mergedData[date] = Object.values(phoneData).reduce(
          (acc: any, curr: any) => {
            Object.keys(curr).forEach((key) => {
              acc[key] = (acc[key] || 0) + curr[key];
            });
            return acc;
          },
          {}
        );

        // Calculate response rate for this date
        let totalNumbers = 0;
        let respondedNumbers = 0;

        Object.keys(phoneData).forEach((phoneNumber) => {
          const phoneStats = phoneData[phoneNumber];
          totalNumbers++;

          if (
            phoneStats.totalInbound > 0 &&
            phoneStats.delivered > 0 &&
            phoneStats.totalInbound > phoneStats.totalOptOut
          ) {
            respondedNumbers++;
          }
        });

        // Calculate new fields
        const totalSent =
          mergedData[date].delivered +
          mergedData[date].sent +
          mergedData[date].failed;

        mergedData[date].deliveryRate = totalSent
          ? Number((mergedData[date].delivered / totalSent) * 100)
          : 0;
        mergedData[date].failedRate = totalSent
          ? Number((mergedData[date].failed / totalSent) * 100)
          : 0;
        mergedData[date].sentRate = totalSent
          ? Number((mergedData[date].sent / totalSent) * 100)
          : 0;
        mergedData[date].totalMessagesSent = totalSent;
        mergedData[date].optOutRate = mergedData[date].totalInbound
          ? Number(
              (mergedData[date].totalOptOut / mergedData[date].totalInbound) *
                100
            )
          : 0;
        mergedData[date].responseRate =
          mergedData[date].delivered > 0
            ? Math.min(
                (respondedNumbers / mergedData[date].delivered) * 100,
                100
              )
            : 0;

        // mergedData[date].responseRate = mergedData[date].responseRate;
      });

      return mergedData;
    };

    const result =
      selectedType === "All"
        ? mergeData(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
        : selectedType === "SMS"
        ? mergeData(analyticsAllV1?.SMS)
        : selectedType === "MMS"
        ? mergeData(analyticsAllV1?.MMS)
        : {};

    setDateGraphObj(result);
  };

  const getNumberGraphData = () => {
    const mergeDataByPhoneNumber = (data1: any, data2?: any) => {
      const mergedData: any = {};

      // Helper function to merge data for a single phone number
      const mergePhoneData = (phoneData: any, date: any, phoneNumber: any) => {
        if (!mergedData[phoneNumber]) {
          mergedData[phoneNumber] = {};
        }
        // Ensure metrics is defined before using it
        const metrics = [
          "delivered",
          "sent",
          "dequeued",
          "enqueued",
          "created",
          "failed",
          "segmentSent",
          "totalInbound",
          "segmentRecieved",
          "totalOptOut",
          "averageResponseTime",
        ];

        metrics.forEach((metric) => {
          if (!mergedData[phoneNumber][metric]) {
            mergedData[phoneNumber][metric] = 0;
          }
          mergedData[phoneNumber][metric] += phoneData[metric] || 0;
        });
      };

      // Merge data from both input objects
      [data1, data2].forEach((data) => {
        data &&
          Object.keys(data).forEach((date) => {
            data[date] &&
              Object.keys(data[date]).forEach((phoneNumber) => {
                mergePhoneData(data[date][phoneNumber], date, phoneNumber);
              });
          });
      });

      // Calculate new fields for each phone number
      Object.keys(mergedData).forEach((phoneNumber) => {
        const totalSent =
          mergedData[phoneNumber].delivered +
          mergedData[phoneNumber].sent +
          mergedData[phoneNumber].failed;

        mergedData[phoneNumber].deliveryRate = totalSent
          ? Number((mergedData[phoneNumber].delivered / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].failedRate = totalSent
          ? Number((mergedData[phoneNumber].failed / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].sentRate = totalSent
          ? Number((mergedData[phoneNumber].sent / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].totalMessagesSent = totalSent;
        mergedData[phoneNumber].optOutRate = mergedData[phoneNumber]
          .totalInbound
          ? Number(
              (mergedData[phoneNumber].totalOptOut /
                mergedData[phoneNumber].totalInbound) *
                100
            )
          : 0;

        mergedData[phoneNumber].responseRate = mergedData[phoneNumber].delivered
          ? Math.min(
              100,
              Number(
                ((mergedData[phoneNumber].totalInbound -
                  mergedData[phoneNumber].totalOptOut) /
                  mergedData[phoneNumber].delivered) *
                  100
              )
            )
          : 0;
      });

      return mergedData;
    };

    const result =
      selectedType === "All"
        ? mergeDataByPhoneNumber(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
        : selectedType === "SMS"
        ? mergeDataByPhoneNumber(analyticsAllV1?.SMS)
        : selectedType === "MMS"
        ? mergeDataByPhoneNumber(analyticsAllV1?.MMS)
        : {};

    setNumberGraphObj(result);
  };

  const handleGetData = (params?: any) => {
    const cb = () => {
      dispatch(
        getAnalyticsMmsV1Thunk(
          removeEmptyAndNullValues({ ...params, createdAt: "" })
        )
      );
      setCurrPageSms(1);
      setRowsPerPageSms(10);
      setCurrPageMms(1);
      setRowsPerPageMms(10);
      handleSmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
      handleMmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    };

    dispatch(
      getAnalyticsSmsV1Thunk(
        removeEmptyAndNullValues({ ...params, createdAt: "" }),
        cb
      )
    );
  };

  const handleSmsGetData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(
      getSmsStatusLogsThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          createdAt: "",
          direction:
            selectedSubType === "totalInbound" ||
            selectedSubType === "averageMessagesReceived" ||
            selectedSubType === "segmentReceived" ||
            selectedSubType === "averageSegmentReceived" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "averageResponseTime"
              ? "inbound"
              : "outbound",
          hasOptOut:
            selectedSubType === "totalOptOut" ||
            selectedSubType === "optOutRate"
              ? "true"
              : "",
        })
      )
    );
  };

  const handleMmsGetData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(
      getMmsStatusLogsThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          createdAt: "",
          direction:
            selectedSubType === "totalInbound" ||
            selectedSubType === "averageMessagesReceived" ||
            selectedSubType === "segmentReceived" ||
            selectedSubType === "averageSegmentReceived" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "averageResponseTime"
              ? "inbound"
              : "outbound",
          hasOptOut:
            selectedSubType === "totalOptOut" ||
            selectedSubType === "optOutRate"
              ? "true"
              : "",
        })
      )
    );
  };

  const handleSmsRowClick = (row: { signalHouseSID: string }) => {
    props.router.navigate(`/sms-logs-details/${row.signalHouseSID}`);
  };

  const handleMmsRowClick = (row: { signalHouseSID: string }) => {
    props.router.navigate(`/mms-logs-details/${row.signalHouseSID}`);
  };

  const handleSmsPageChange = (page: number) => {
    setCurrPageSms(page);
    handleSmsGetData(
      page,
      rowsPerPageSms,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleMmsPageChange = (page: number) => {
    setCurrPageMms(page);
    handleMmsGetData(
      page,
      rowsPerPageMms,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleSmsRowsPerPageChange = (rows: number) => {
    setCurrPageSms(1);
    setRowsPerPageSms(rows);
    handleSmsGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleMmsRowsPerPageChange = (rows: number) => {
    setCurrPageMms(1);
    setRowsPerPageMms(rows);
    handleMmsGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  useEffect(() => {
    if (type) {
      const typeMapping: Record<
        string,
        { selectedType: string; selectedType2: string; selectedSubType: string }
      > = {
        all: {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[0],
          selectedSubType: "totalMessagesSent",
        },
        sms_outbound: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "totalMessagesSent",
        },
        sms_inbound: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "totalMessagesSent",
        },
        mms_outbound: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "totalMessagesSent",
        },
        mms_inbound: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "totalMessagesSent",
        },
        "all-d-rate": {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[0],
          selectedSubType: "deliveryRate",
        },
        "sms_outbound_d-rate": {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[0],
          selectedSubType: "deliveryRate",
        },
        "mms_outbound_d-rate": {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[0],
          selectedSubType: "deliveryRate",
        },
        non_optout_rate: {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
        sms_non_optout_rate: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
        mms_non_optout_rate: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
        "optout-rate": {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
        "sms_optout-rate": {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
        "mms_optout-rate": {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
      };
      if (type in typeMapping) {
        const { selectedType, selectedType2, selectedSubType } =
          typeMapping[type];
        setSelectedType(selectedType);
        setSelectedType2(selectedType2);
        setSelectedSubType(selectedSubType);
      }
    }
  }, [type]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AnalyticsHeader
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedType2={selectedType2}
            setSelectedType2={setSelectedType2}
            selectedSubType={selectedSubType}
            setSelectedSubType={setSelectedSubType}
            setSelectedSubTypeLabel={setSelectedSubTypeLabel}
            validation={validation}
            dateRangeTemp={dateRangeTemp}
            setDateRangeTemp={setDateRangeTemp}
            datePickerModal={datePickerModal}
            setDatePickerModal={setDatePickerModal}
            setOpen={setOpen}
            open={open}
            loading={loading}
            handleGetData={handleGetData}
            groupDetails={groupDetails}
          />
          {loading ? (
            <div className="position-relative wrapper listing-table">
              <img
                src={Loader}
                className="position-absolute top-50 start-50 translate-middle"
              />
            </div>
          ) : (
            <>
              {(selectedType2 == "All" || selectedType2 == "Outbound") && (
                <>
                  <h4 className="mb-3">Outbound</h4>
                  <Card>
                    <Card.Body>
                      <Row className="g-4">
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "deliveryRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("deliveryRate");
                            setSelectedSubTypeLabel("Delivery Rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check-all fs-2xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Delivery Rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.deliveryRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 custom-col border-end-md analytics-card ${
                            selectedSubType === "totalMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalMessagesSent");
                            setSelectedSubTypeLabel("Total messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check fs-3xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              start={0}
                              end={
                                cardObj?.[selectedType]?.totalMessagesSent || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalMessagesSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalMessagesSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "averageMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesSent");
                            setSelectedSubTypeLabel("Average messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-chevron-bar-expand fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageMessagesSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "segmentSent" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentSent");
                            setSelectedSubTypeLabel("Total segments sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-clipboard2-check fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segments sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.segmentSent || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentsSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentsSent");
                            setSelectedSubTypeLabel("Average segment sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-boxes fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segment sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentsSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failed" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failed");
                            setSelectedSubTypeLabel("Failed");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failed</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.failed || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}: {cardObj?.[dt]?.failed || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.failed || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failedRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failedRate");
                            setSelectedSubTypeLabel("Failure rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failure rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.failedRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalOptOut" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalOptOut");
                            setSelectedSubTypeLabel("Total Opt Out");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Total Opt Out</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalOptOut || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalOptOut || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalOptOut || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "optOutRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("optOutRate");
                            setSelectedSubTypeLabel("Opt Out rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Opt Out rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.optOutRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              {(selectedType2 == "All" || selectedType2 == "Inbound") && (
                <>
                  <h4
                    className={`${selectedType2 == "All" ? "mt-4" : ""} mb-3`}
                  >
                    Inbound
                  </h4>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalInbound" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalInbound");
                            setSelectedSubTypeLabel("Total messages received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalInbound || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalInbound || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalInbound || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageMessagesReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesReceived");
                            setSelectedSubTypeLabel(
                              "Average messages received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]
                                          ?.averageMessagesReceived || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "segmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentReceived");
                            setSelectedSubTypeLabel("Total segment received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segment received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={
                                cardObj?.[selectedType]?.segmentReceived || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentReceived || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentReceived || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentReceived");
                            setSelectedSubTypeLabel(
                              "Average segments received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segments received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentReceived ||
                                        0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "responseRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("responseRate");
                            setSelectedSubTypeLabel("Response rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Response rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.responseRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageResponseTime"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageResponseTime");
                            setSelectedSubTypeLabel("Average time to response");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stopwatch-fill fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average time to response
                              </p>
                            </div>
                          </div>
                          <h3>
                            {convertSeconds(
                              cardObj?.[selectedType]?.averageResponseTime,
                              true
                            )}
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {convertSeconds(
                                        cardObj?.[dt]?.averageResponseTime,
                                        true
                                      )}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {convertSeconds(
                                cardObj?.[selectedType]?.averageResponseTime,
                                true
                              )}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType1 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType1 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType1 === "Column" ? (
                      <StackedColumn2
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    ) : (
                      <LineChart
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType2 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType2 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType2 === "Column" ? (
                      <StackedColumn2
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    ) : (
                      <LineChart
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Header>
                  <h6 className="card-title mb-0">
                    {selectedSubTypeLabel || ""} logs
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Tab.Container
                    key={selectedType}
                    // activeKey={selectedType === "MMS" ? "mms" : "sms"}
                    defaultActiveKey={selectedType === "MMS" ? "mms" : "sms"}
                  >
                    <Nav
                      as="ul"
                      variant="tabs"
                      className="nav-tabs-custom nav-primary mb-4"
                    >
                      {(selectedType === "All" || selectedType === "SMS") && (
                        <Nav.Item as="li">
                          <Nav.Link eventKey="sms">SMS</Nav.Link>
                        </Nav.Item>
                      )}
                      {(selectedType === "All" || selectedType === "MMS") && (
                        <Nav.Item as="li">
                          <Nav.Link eventKey="mms">MMS</Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    <Tab.Content>
                      {(selectedType === "All" || selectedType === "SMS") && (
                        <Tab.Pane eventKey="sms">
                          <SmsAndMmsLogs
                            key={selectedType}
                            loading={loading2}
                            rowsPerPage={rowsPerPageSms}
                            currPage={currPageSms}
                            logsData={smsStatusLogs}
                            handleRowClick={handleSmsRowClick}
                            handlePageChange={handleSmsPageChange}
                            handleRowsPerPageChange={handleSmsRowsPerPageChange}
                          />
                        </Tab.Pane>
                      )}
                      {(selectedType === "All" || selectedType === "MMS") && (
                        <Tab.Pane eventKey="mms">
                          <SmsAndMmsLogs
                            key={selectedType}
                            loading={loading2}
                            rowsPerPage={rowsPerPageMms}
                            currPage={currPageMms}
                            logsData={mmsStatusLogs}
                            handleRowClick={handleMmsRowClick}
                            handlePageChange={handleMmsPageChange}
                            handleRowsPerPageChange={handleMmsRowsPerPageChange}
                          />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Analytics);
