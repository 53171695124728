import React from "react";
// import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface BreadCrumbProps {
  title: any;
  // pageTitle?: string;
  style?: any;
  isCreate?: boolean;
  createLabel?: string;
  createLink?: string;
  createClick?: any;
  isBack?: boolean;
  backClick?: any;
  isFilter?: boolean;
  filterButton?: any;
}
const BreadCrumb = ({
  title,
  // pageTitle,
  style = {},
  isCreate = false,
  createLabel = "Add",
  createLink = "",
  createClick = () => {},
  isBack = false,
  backClick = () => {},
  isFilter = false,
  filterButton = null,
}: BreadCrumbProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 d-flex align-items-center">{title}</h4>
            <div
              className="page-title-right d-flex align-items-center"
              style={style}
            >
              {isFilter ? filterButton : null}
              {isBack ? (
                <Button
                  variant="secondary"
                  className="d-flex align-items-center ms-2"
                  onClick={backClick}
                >
                  <i className="bx bx-arrow-back me-1"></i> Back
                </Button>
              ) : null}
              {isCreate ? (
                createLink ? (
                  <Link to={createLink || "#"}>
                    <Button
                      variant="primary"
                      className="d-flex align-items-center ms-2 fs-md"
                    >
                      <i className="bx bx-plus me-1"></i> {createLabel}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center ms-2 fs-md"
                    onClick={createClick}
                  >
                    <i className="bx bx-plus me-1"></i> {createLabel}
                  </Button>
                )
              ) : null}
            </div>
            {/* <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div> */}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
