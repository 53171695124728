import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  allMessageLogs: {},
  MessageLogs: {},
  MmsLogs: {},
  MessageLogDetails: {},
  MmsLogDetails: {},
  templateData: null,
  templateList: [],
  templateDetailsList: [],
  totalTemplates: 0,
  evidenceUploading: false,
  evidenceUploadError: true,
  evidenceLoading: false,
  evidence: {},
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoadingFalse(state) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    allMessageLogsSuccessful(state, action) {
      state.loading2 = false;
      state.allMessageLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    allMessageLogsFailed(state, action) {
      state.loading2 = false;
      state.allMessageLogs = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getMessageLogsSuccessful(state, action) {
      state.loading = false;
      state.MessageLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getMessageLogsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetMessageLogsData(state) {
      state.loading = false;
      state.MessageLogs = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getPersonalMessageLogsSuccessful(state, action) {
      state.loading = false;
      state.MessageLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getPersonalMessageLogsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getMessageLogDetailsSuccessful(state, action) {
      state.loading = false;
      state.MessageLogDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getMessageLogDetailsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    sendSMSSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = JSON.stringify(action.payload);
      state.error = false;
      state.errorMsg = "";
    },
    sendSMSFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    sendMMSSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = JSON.stringify(action.payload);
      state.error = false;
      state.errorMsg = "";
    },
    sendMMSFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    uploadMmsFileSuccessful(state, action) {
      // state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    uploadMmsFileFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createMessageTemplateSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.templateData = action.payload.data.data;
    },
    createMessageTemplateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    findMessageTemplateSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.templateList = action.payload.data;
      state.totalTemplates = action.payload.totalTemplates;
    },
    findMessageTemplateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    findMessageTemplateDetailsSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.templateDetailsList = action.payload.data.data;
    },
    findMessageTemplateDetailsFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    editMessageTemplateSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.templateData = action.payload.data.data;
    },
    editMessageTemplateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deleteMessageTemplateSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
    },
    deleteMessageTemplateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    fileUploadPending(state) {
      state.evidenceUploading = true;
      state.success = false;
      state.successMsg = "";
      state.evidenceUploadError = true;
      state.error = false;
      state.errorMsg = "";
    },
    fileUploadSuccess(state, action) {
      state.evidenceUploading = false;
      state.success = true;
      state.evidenceUploadError = false;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
    },
    fileUploadFailed(state, action) {
      state.evidenceUploading = false;
      state.success = false;
      state.evidenceUploadError = true;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getEvidenceFilePending(state) {
      state.evidenceLoading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getEvidenceFileSuccess(state, action) {
      state.evidenceLoading = false;
      state.success = true;
      state.error = false;
      state.errorMsg = "";
      state.evidence = action.payload;
    },
    getEvidenceFileFailed(state, action) {
      state.evidenceLoading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.evidence = [];
      state.errorMsg = action.payload.message;
    },
    updateAuthUserSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    updateAuthUserFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getMmsLogsSuccessful(state, action) {
      state.loading = false;
      state.MmsLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getMmsLogsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getMmsLogDetailsSuccessful(state, action) {
      state.loading = false;
      state.MmsLogDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getMmsLogDetailsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoadingFalse,
  apiLoading2,
  allMessageLogsSuccessful,
  allMessageLogsFailed,
  getMessageLogsSuccessful,
  getMessageLogsFailed,
  resetMessageLogsData,
  getMessageLogDetailsSuccessful,
  getMessageLogDetailsFailed,
  sendSMSSuccessful,
  sendSMSFailed,
  sendMMSSuccessful,
  sendMMSFailed,
  uploadMmsFileSuccessful,
  uploadMmsFileFailed,
  createMessageTemplateSuccess,
  createMessageTemplateFailed,
  findMessageTemplateSuccess,
  findMessageTemplateFailed,
  editMessageTemplateSuccess,
  editMessageTemplateFailed,
  deleteMessageTemplateSuccess,
  deleteMessageTemplateFailed,
  fileUploadPending,
  fileUploadSuccess,
  fileUploadFailed,
  getEvidenceFilePending,
  getEvidenceFileSuccess,
  getEvidenceFileFailed,
  updateAuthUserSuccessful,
  updateAuthUserFailed,
  findMessageTemplateDetailsSuccess,
  findMessageTemplateDetailsFailed,
  getPersonalMessageLogsSuccessful,
  getPersonalMessageLogsFailed,
  getMmsLogsSuccessful,
  getMmsLogsFailed,
  getMmsLogDetailsSuccessful,
  getMmsLogDetailsFailed,
} = messagesSlice.actions;

export default messagesSlice.reducer;
