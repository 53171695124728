import React, { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import { removeEmptyAndNullValues } from "helpers/common";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import StackedColumn from "./StackedColumn";
import _ from "lodash";
import LineChart from "../Analytics/LineChart";

const selectedTypeArr = ["All", "SMS", "MMS"];

const LsatDays = () => {
  document.title = "Signal House Portal Analytics";

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      loading: analytics.loading,
      analyticsAllV1: analytics.analyticsAllV1,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, analyticsAllV1, groupDetails } = useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedSubType, setSelectedSubType] = useState("totalMessagesSent");
  const [open, setOpen] = useState(false);
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [showPercentage, setShowPercentage] = useState(false);

  const [graphType1, setGraphType1] = useState("Column");

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: moment().subtract(7, "days").startOf("day").toISOString(),
      endDate: moment().endOf("day").format("MM-DD-YYYY"),
      groupId: "",
      subGroupId: "",
      brandId: "",
      campaignId: "",
      phoneNumber: "",
      tag: "",
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      const temp = {
        ...values,
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        statusFilter:
          selectedSubType === "deliveryRate"
            ? ["delivered"]
            : selectedSubType === "failed"
            ? ["failed"]
            : ["delivered", "sent"],
      };
      setOpen(!open);
    },
  });

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const startDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .toISOString();
      const endDate = moment().endOf("day").format("MM-DD-YYYY");

      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getDateGraphData();
    }
  }, [analyticsAllV1]);

  const getDateGraphData = () => {
    const mergeData = (data1: any, data2?: any) => {
      const mergedData = _.mergeWith({}, data1, data2, (objValue, srcValue) => {
        if (_.isObject(objValue) && _.isObject(srcValue)) {
          return _.mergeWith(
            {},
            objValue,
            srcValue,
            (innerObjValue, innerSrcValue) => {
              if (_.isNumber(innerObjValue) && _.isNumber(innerSrcValue)) {
                return innerObjValue + innerSrcValue;
              }
            }
          );
        }
      });

      // Merge phone number data for each date and calculate new fields
      Object.keys(mergedData).forEach((date) => {
        const phoneData = mergedData[date];
        mergedData[date] = Object.values(phoneData).reduce(
          (acc: any, curr: any) => {
            Object.keys(curr).forEach((key) => {
              acc[key] = (acc[key] || 0) + curr[key];
            });
            return acc;
          },
          {}
        );

        // Calculate new fields
        const totalSent = mergedData[date].delivered + mergedData[date].sent;
        mergedData[date].deliveryRate = totalSent
          ? Number((mergedData[date].delivered / totalSent) * 100)
          : 0;
        mergedData[date].failedRate =
          totalSent > 0 ? Number(100 - mergedData[date].deliveryRate) : 0;
        mergedData[date].totalMessagesSent = totalSent;
      });

      return mergedData;
    };

    const result =
      selectedType === "All"
        ? mergeData(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
        : selectedType === "SMS"
        ? mergeData(analyticsAllV1?.SMS)
        : selectedType === "MMS"
        ? mergeData(analyticsAllV1?.MMS)
        : {};

    setDateGraphObj(result);
  };

  return (
    <React.Fragment>
      <Col xl={5}>
        <Card className="card-height-100">
          <Card.Header className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Message Deliverability</h4>
            <div className="d-flex gap-1">
              <div>
              <Button
                  variant={showPercentage ? "secondary" : "secondary"}
                  onClick={() => setShowPercentage(!showPercentage)}
                  className="ms-2"
                >
                  {showPercentage ? "Show Numbers" : "Show Percentage"}
                </Button>
                <Button
                  variant={graphType1 === "Column" ? "secondary" : "light"}
                  className="ms-2 fs-2xl p-0"
                  onClick={() => setGraphType1("Column")}
                >
                  <i className="ph ph-chart-bar m-2" />
                </Button>
                <Button
                  variant={graphType1 === "Bar" ? "secondary" : "light"}
                  className="ms-2 fs-2xl p-0"
                  onClick={() => setGraphType1("Bar")}
                >
                  <i className="ph ph-chart-line m-2" />
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="w-100">
              {loading ? (
                <div className="position-relative wrapper listing-table">
                  <img
                    src={Loader}
                    alt="Loading"
                    className="position-absolute top-50 start-50 translate-middle"
                  />
                </div>
              ) : (

                  <Card.Body>
                    <div className="w-100">
                      {graphType1 === "Column" ? (
                        <StackedColumn
                          graphObj={dateGraphObj}
                          selectedSubType={selectedSubType}
                          showPercentage={showPercentage} 
                          setShowPercentage={setShowPercentage} 

                        />
                      ) : (
                        <LineChart
                          graphObj={dateGraphObj}
                          selectedSubType={selectedSubType}
                        />
                      )}
                    </div>
                  </Card.Body>
         
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LsatDays;