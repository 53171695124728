import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncPortingPhoneNumberList,
  asyncSubgroupList,
  capitalizeString,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getFileThunk, getPortRequestListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";

const initialFilterValues = {
  portingId: "",
  submissionDate: "",
  startDate: "",
  endDate: "",
  completionDate: "",
  activationDateStart: "",
  activationDateEnd: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
  phoneNumber: [],
};

const PortRequests = (props: any) => {
  document.title = "Signal House Portal Port Requests";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [iconDisable, setIconDisable] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selector = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.Groups,
    (ports, groups) => ({
      loading: ports.loading,
      portRequestsData: ports.portRequestsArr,
      error: ports.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, portRequestsData, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Requested Date</span>,
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm A")}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (
          new Date(rowA.createdDate).getTime() -
          new Date(rowB.createdDate).getTime()
        );
      },
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Phone Number</span>,
      selector: (row: { phoneNumberToBePorted: string[] }) => {
        if (
          Array.isArray(row.phoneNumberToBePorted) &&
          row.phoneNumberToBePorted.length > 1
        ) {
          return (
            <Tooltip
              placement="top"
              overlay={<span>{row.phoneNumberToBePorted.join(", ")}</span>}
            >
              <span>
                {row.phoneNumberToBePorted[0]} <br />{" "}
                <small> and {row.phoneNumberToBePorted.length - 1} more</small>
              </span>
            </Tooltip>
          );
        }
        return row.phoneNumberToBePorted || "-";
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const phoneA = Array.isArray(rowA.phoneNumberToBePorted)
          ? rowA.phoneNumberToBePorted[0]
          : rowA.phoneNumberToBePorted;
        const phoneB = Array.isArray(rowB.phoneNumberToBePorted)
          ? rowB.phoneNumberToBePorted[0]
          : rowB.phoneNumberToBePorted;
        return phoneA.localeCompare(phoneB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Owners Name</span>,
      selector: (row: { ownersName: string }) => row.ownersName || "-",
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (rowA.ownersName || "").localeCompare(rowB.ownersName || "");
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Porting ID</span>,
      selector: (row: { portingId: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.portingId}</p>}
        >
          <div onClick={() => handleRowClick(row)}>
            {row.portingId.length > 10
              ? `${row.portingId.slice(0, 23)}...`
              : row.portingId}
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return rowA.portingId.localeCompare(rowB.portingId);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">LOA</span>,
      selector: (row: { letterOfAuthorization: string }) =>
        row.letterOfAuthorization ? (
          <i
            className={`ri-eye-fill ${
              iconDisable ? "cursor-not-allowed" : "cursor-pointer"
            } fs-2xl text-secondary`}
            onClick={() =>
              iconDisable ? {} : handleViewLoa(row.letterOfAuthorization)
            }
          />
        ) : (
          "-"
        ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (rowA.letterOfAuthorization || "").localeCompare(
          rowB.letterOfAuthorization || ""
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { portingStatus: string }) => {
        switch (cell.portingStatus.toLowerCase()) {
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
          case "success":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge border"
                style={{
                  borderColor: "#6c757d",
                  background: "#d3d3d3e0",
                  color: "#6c757d",
                }}
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
        }
      },
      sortFunction: (rowA: any, rowB: any) => {
        return rowA.portingStatus.localeCompare(rowB.portingStatus);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Completion Date</span>,
      selector: (row: { activationDate: string }) =>
        row.activationDate ? (
          <span data-tag="allowRowEvents" className="d-flex align-items-center">
            <i
              data-tag="allowRowEvents"
              className="bi bi-calendar3 me-2 fs-lg text-secondary"
            />
            {dateFormat(row.activationDate, "MM/DD/YYYY, hh:mm A")}
          </span>
        ) : (
          "-"
        ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (
          new Date(rowA.activationDate).getTime() -
          new Date(rowB.activationDate).getTime()
        );
      },
      width: "220px",
    },
  ];

  const handleRowClick = (row: { portingId: string }) => {
    navigate(`/porting-status/${row.portingId}`);
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({
        ...values,
        submissionDate: "",
        startDate: values.startDate
          ? moment(values.startDate, "MM-DD-YYYY").startOf("day").toISOString()
          : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        completionDate: "",
        activationDateStart: values.activationDateStart
          ? moment(values.activationDateStart, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        activationDateEnd: values.activationDateEnd
          ? moment(values.activationDateEnd, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          values.phoneNumber.length > 0
            ? values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
      });
      setOpen(!open);
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getPortRequestListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        submissionDate: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
        completionDate: "",
        activationDateStart: validation.values.activationDateStart
          ? moment(validation.values.activationDateStart, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        activationDateEnd: validation.values.activationDateEnd
          ? moment(validation.values.activationDateEnd, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        submissionDate: "",
        startDate: validation.values.startDate
          ? moment(validation.values.startDate, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
        completionDate: "",
        activationDateStart: validation.values.activationDateStart
          ? moment(validation.values.activationDateStart, "MM-DD-YYYY")
              .startOf("day")
              .toISOString()
          : "",
        activationDateEnd: validation.values.activationDateEnd
          ? moment(validation.values.activationDateEnd, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
      })
    );
  };

  const handleViewLoa = (loadId: string) => {
    setIconDisable(true);
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      window.open(temp2, "_blank");
    };
    dispatch(getFileThunk(loadId, cb)).then(() => {
      setIconDisable(false);
    });
  };

  return (
    <Container fluid>
      <BreadCrumb
        title="Porting Requests"
        isFilter={true}
        filterButton={
          <Dropdown
            align="end"
            className="filterDropDown2"
            show={open}
            onToggle={() => {
              if (!datePickerModal) {
                setOpen(!open);
              }
            }}
          >
            <Dropdown.Toggle variant="secondary">
              <i className="bx bx-filter-alt me-1"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-md p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="portingId">Porting ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="portingId"
                      placeholder="Enter Porting ID"
                      onChange={(e) => {
                        validation.setFieldValue("portingId", e.target.value);
                      }}
                      value={validation.values.portingId}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="submissionDate">
                      Requested Date
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="submissionDate"
                      placeholder="Requested Date Range"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue(
                            "submissionDate",
                            dateStr || ""
                          );
                          value?.[0] &&
                            validation.setFieldValue(
                              "startDate",
                              moment(value?.[0]).format("MM-DD-YYYY")
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "endDate",
                              moment(value?.[1]).format("MM-DD-YYYY")
                            );
                        },
                      }}
                      value={validation.values.submissionDate || ""}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="completionDate">
                      Completion Date
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="completionDate"
                      placeholder="Completion Date Range"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue(
                            "completionDate",
                            dateStr || ""
                          );
                          value?.[0] &&
                            validation.setFieldValue(
                              "activationDateStart",
                              moment(value?.[0]).format("MM-DD-YYYY")
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "activationDateEnd",
                              moment(value?.[1]).format("MM-DD-YYYY")
                            );
                        },
                      }}
                      value={validation.values.completionDate || ""}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                    <AsyncPaginate
                      isDisabled={!groupDetails?.records?.[0]?.group_id}
                      key={groupDetails?.records?.[0]?.group_id}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.subGroupId &&
                          validation?.errors?.subGroupId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.subGroupId?.length > 0
                          ? validation.values.subGroupId.map((dt: string) => ({
                              sub_group_id: dt,
                            }))
                          : null
                      }
                      loadOptions={asyncSubgroupList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                        }),
                        "subGroupId"
                      )}
                      getOptionValue={(option: any) => option?.sub_group_id}
                      getOptionLabel={(option: any) => option?.sub_group_id}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.map((dt: any) => dt.sub_group_id)
                          );
                          validation.setFieldValue("brandId", []);
                          validation.setFieldValue("campaignId", []);
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("subGroupId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.subGroupId.toString() ||
                        groupDetails?.records?.[0]?.group_id ||
                        "brandId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.brandId &&
                          validation?.errors?.brandId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.brandId?.length > 0
                          ? validation.values.brandId
                          : null
                      }
                      loadOptions={asyncBrandList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                        }),
                        "brandId"
                      )}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) => option?.brandId}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                            }))
                          );
                          validation.setFieldValue("campaignId", []);
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("brandId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          .toString() ||
                        validation.values.subGroupId.toString() ||
                        groupDetails?.records?.[0]?.group_id ||
                        "campaignId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.campaignId &&
                          validation?.errors?.campaignId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.campaignId?.length > 0
                          ? validation.values.campaignId
                          : null
                      }
                      loadOptions={asyncCampaignList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                          brandId:
                            validation.values.brandId.length > 0
                              ? validation.values.brandId?.map(
                                  (dt: any) => dt.brandId
                                )
                              : "",
                        }),
                        "campaignId"
                      )}
                      getOptionValue={(option: any) => option?.campaignId}
                      getOptionLabel={(option: any) => option?.campaignId}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.brandId.find(
                                    (item: any) =>
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.brandId]
                            )
                          );
                          validation.setFieldValue(
                            "campaignId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                              campaignId: dt?.campaignId || "",
                            }))
                          );
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("campaignId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.campaignId
                          ?.map((dt: any) => dt.campaignId)
                          .toString() ||
                        validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          .toString() ||
                        validation.values.subGroupId.toString() ||
                        "phoneNumber"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.phoneNumber &&
                          validation?.errors?.phoneNumber
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.phoneNumber
                          ? validation.values.phoneNumber
                          : null
                      }
                      loadOptions={asyncPortingPhoneNumberList(
                        removeEmptyAndNullValues({
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                          brandId:
                            validation.values.brandId.length > 0
                              ? validation.values.brandId?.map(
                                  (dt: any) => dt.brandId
                                )
                              : "",
                          campaignId:
                            validation.values.campaignId.length > 0
                              ? validation.values.campaignId?.map(
                                  (dt: any) => dt.campaignId
                                )
                              : "",
                        }),
                        "phoneNumber"
                      )}
                      getOptionValue={(option: any) => option?.phoneNumber}
                      getOptionLabel={(option: any) => option?.phoneNumber}
                      onChange={(option: any) => {
                        if (
                          option &&
                          option.phoneNumber === validation.values.phoneNumber
                        ) {
                          return;
                        }

                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.brandId.find(
                                    (item: any) =>
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.brandId]
                            )
                          );
                          validation.setFieldValue(
                            "campaignId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.campaignId.find(
                                    (item: any) =>
                                      item.campaignId === dt.campaignId &&
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                    campaignId: dt.campaignId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.campaignId]
                            )
                          );
                          validation.setFieldValue(
                            "phoneNumber",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                              campaignId: dt.campaignId || "",
                              phoneNumber: dt?.phoneNumber || "",
                            }))
                          );
                        } else {
                          validation.setFieldValue("phoneNumber", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    className="btn btn-secondary me-2"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.setValues(initialFilterValues);
                      validation.resetForm();
                      setDatePickerModal(false);
                      handleGetData(currPage, rowsPerPage);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Spinner size="sm" animation="border" />} Apply
                  </Button>
                </div>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        }
        isCreate={true}
        createLabel="New Port Request"
        createLink="/port-numbers/new-porting-requests"
      />
      <div className="position-relative">
        <Card className="wrapper2">
          <Card.Body className="listing-table3">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(portRequestsData?.records)
                    ? portRequestsData?.records
                    : []
                }
                columns={columns}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleRowClick={handleRowClick}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={portRequestsData?.totalRecords}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default withRouter(PortRequests);
