import {
  getAllMessageLogsApi,
  getMessageLogsApi,
  getMessageLogDetailsApi,
  sendSMSApi,
  sendMMSApi,
  createMessageTemplateApi,
  findMessageTemplateApi,
  editMessageTemplateApi,
  deleteMessageTemplateApi,
  getMessageTemplateDetailsApi,
  // messageTemplatefileUplaoadApi,
  // getEvidenceApi,
  messageFileUploadApi,
  messaeGetFileApi,
  getPersonalMessageLogsApi,
  uploadMmsFileApi,
  getMmsLogsApi,
  getMmsLogDetailsApi,
  getMmsFileApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoadingFalse,
  apiLoading2,
  allMessageLogsSuccessful,
  allMessageLogsFailed,
  getMessageLogsSuccessful,
  getMessageLogsFailed,
  resetMessageLogsData,
  getMessageLogDetailsSuccessful,
  getMessageLogDetailsFailed,
  sendSMSSuccessful,
  sendSMSFailed,
  sendMMSSuccessful,
  sendMMSFailed,
  uploadMmsFileSuccessful,
  uploadMmsFileFailed,
  createMessageTemplateSuccess,
  createMessageTemplateFailed,
  findMessageTemplateSuccess,
  findMessageTemplateFailed,
  editMessageTemplateSuccess,
  editMessageTemplateFailed,
  deleteMessageTemplateSuccess,
  deleteMessageTemplateFailed,
  // fileUploadPending,
  // fileUploadSuccess,
  // fileUploadFailed,
  // getEvidenceFilePending,
  // getEvidenceFileSuccess,
  // getEvidenceFileFailed,
  // updateAuthUserSuccessful,
  findMessageTemplateDetailsFailed,
  findMessageTemplateDetailsSuccess,
  updateAuthUserFailed,
  getPersonalMessageLogsSuccessful,
  getPersonalMessageLogsFailed,
  getMmsLogsSuccessful,
  getMmsLogsFailed,
  getMmsLogDetailsSuccessful,
  getMmsLogDetailsFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getAllMessageLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await getAllMessageLogsApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code || data?.errorCode) {
        dispatch(allMessageLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(allMessageLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(allMessageLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getMessagesThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getMessageLogsApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code) {
        dispatch(getMessageLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getMessageLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getMessageLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resetMessageLogsList = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(resetMessageLogsData());
};

export const getMessageLogDetailsThunk =
  (payload: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getMessageLogDetailsApi(payload);

      if (data?.code) {
        dispatch(getMessageLogDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getMessageLogDetailsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getMessageLogDetailsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const sendSMSThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await sendSMSApi({ ...payload });

      if (data?.code || data?.errorCode) {
        dispatch(sendSMSFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(sendSMSSuccessful(data));
        toast.success(data?.message || "Message sent succesfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(sendSMSFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const sendMMSThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await sendMMSApi({ ...payload });

      if (data?.code || data?.errorCode) {
        dispatch(sendMMSFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(sendMMSSuccessful(data));
        toast.success(data?.message || "Message sent succesfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(sendMMSFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const uploadMmsFileThunk =
  (files: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const promises = files?.map(async (dt: any) => {
        const formData = new FormData();
        formData.append("syniverseMMSFile", dt);
        return await uploadMmsFileApi(formData, true);
      });
      const results = await Promise.all(promises);

      if (results?.[0]?.id) {
        dispatch(uploadMmsFileSuccessful("MMS uploaded successfully"));
        // toast.success("MMS uploaded successfully");
        cb && cb(results);
      } else {
        dispatch(uploadMmsFileFailed("Something went wrong"));
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(uploadMmsFileFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getMmsFileThunk =
  (id: string, cb?: any) => async (dispatch: any) => {
    try {
      const data: any = await getMmsFileApi(id);

      if (data?.code || data?.message) {
        toast.error(data?.message || "Something went wrong");
      } else {
        cb && cb(data);
      }
    } catch (error: any) {
      console.log("error", error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// createMessageTemplateThunk
export const createTemplateThunk = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    let data: any = await createMessageTemplateApi({
      ...payload,
    });

    if (data?.code || data?.errorCode) {
      dispatch(createMessageTemplateFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(createMessageTemplateSuccess(data));
      toast.success(data?.message);
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(createMessageTemplateFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

// findMessageTemplateThunk
export const findTemplateThunk = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    const { page, pageSize, ...otherParams } = payload;

    let data: any = await findMessageTemplateApi({
      page,
      pageSize,
      ...otherParams,
    });

    if (data?.code || data?.errorCode) {
      dispatch(findMessageTemplateFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(findMessageTemplateSuccess(data));
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(findMessageTemplateFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

// findMessageTemplateDetailsThunk
export const findTemplateDetailsThunk =
  (payload: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await getMessageTemplateDetailsApi(payload);

      if (data?.code || data?.errorCode) {
        dispatch(findMessageTemplateDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(findMessageTemplateDetailsSuccess(data));
        // toast.success(data?.message);
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(findMessageTemplateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// editMessageTemplateThunk
export const editTemplateThunk = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    let data: any = await editMessageTemplateApi(payload);

    if (data?.code || data?.errorCode) {
      dispatch(deleteMessageTemplateApi(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(editMessageTemplateSuccess(data));
      toast.success(data?.message);
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(editMessageTemplateFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

// deleteMessageTemplateThunk
export const deleteTemplateThunk =
  (templateId: string) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await deleteMessageTemplateApi(templateId);

      if (data?.code || data?.errorCode) {
        dispatch(deleteMessageTemplateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(deleteMessageTemplateSuccess(data));
        toast.success(data?.message || "Template deleted successfully");
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(deleteMessageTemplateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// export const uploadEvidenceThunk =
// (payload: any) => async (dispatch: any) => {
//   dispatch(fileUploadPending());
//   try {
//     const response: any = await messageTemplatefileUplaoadApi( payload);
//     if (response?.code || response?.message) {
//       dispatch(fileUploadFailed(response));
//       toast.error(response?.message || "");
//     } else {
//       dispatch(fileUploadSuccess(response));
//       toast.success("Evidence files added successfully");
//     }
//   } catch (error: any) {
//     dispatch(fileUploadFailed(error));
//     toast.error(error?.response?.data?.message || "Something went wrong");
//   }
// };

// export const getUploadedEvidenceThunk =
// (brandId: string) => async (dispatch: any) => {
//   dispatch(getEvidenceFilePending());
//   try {
//     const response: any = await getEvidenceApi(brandId);
//     if (response?.code || response?.message) {
//       dispatch(getEvidenceFileFailed(response));
//       toast.error(response?.message || "");
//     } else {
//       dispatch(getEvidenceFileSuccess(response));
//     }
//   } catch (error: any) {
//     dispatch(getEvidenceFileFailed(error));
//     toast.error(error?.response?.data?.message || "Something went wrong");
//   }
// };

export const msgFileUploadThunk =
  (file: any, isFormData?: boolean, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await messageFileUploadApi(file, isFormData);

      if (data?.length) {
        cb && cb(data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updateAuthUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      dispatch(apiLoadingFalse()); // Set apiLoading to false after API call completes
    }
  };

export const getMsgFileThunk =
  (path: string, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await messaeGetFileApi(path);

      if (data) {
        cb &&
          cb(new File([data], `file.${data?.type?.split("/").pop()}`), path);
      } else {
        dispatch(updateAuthUserFailed({ message: "Something went wrong" }));
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updateAuthUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      dispatch(apiLoadingFalse()); // Set apiLoading to false after API call completes
    }
  };

export const getPersonalMessagesLogsThunk =
  (pagination: any, phoneNumber: any, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      // Pass additional parameters to getMessageLogsApi function
      const data: any = await getPersonalMessageLogsApi(
        pagination,
        phoneNumber,
        otherParams
      );
      if (data?.code) {
        dispatch(getPersonalMessageLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getPersonalMessageLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getPersonalMessageLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getMmsLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getMmsLogsApi(page, recordsPerPage, otherParams);
      if (data?.code) {
        dispatch(getMmsLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getMmsLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getMmsLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getMmsLogDetailsThunk =
  (payload: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getMmsLogDetailsApi(payload);

      if (data?.code) {
        dispatch(getMmsLogDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getMmsLogDetailsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getMmsLogDetailsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
