import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Button, Form, Spinner } from "react-bootstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {
  getAuthUserThunk,
  getLocationThunk,
  loginUserThunk,
} from "slices/thunk";
import { createSelector } from "reselect";
import {
  clearLoginData,
  getLoginData,
  setLoginData,
} from "helpers/services/auth/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { setAuthorization } from "helpers/api_helper";
import {
  osName,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { toast } from "react-toastify";

const Signin = (props: any) => {
  document.title = "Signal House Portal Login";

  const dispatch: any = useDispatch();
  const recaptchaRef = useRef<any>();

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      loginData: login.loginData,
      loading: login.loading,
      isUserLogout: login.isUserLogout,
    })
  );

  const { loginData, loading, isUserLogout } = useSelector(
    selectAccountAndLogin
  );
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<boolean>(false);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailId: "",
      password: "",
      location: "",
      device: "",
      deviceInfo: {},
      ip: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: () => {
      handleRememberMe();
      recaptchaRef.current.execute();
    },
  });

  useEffect(() => {
    validation.setFieldValue("device", `${osName || ""} ${browserName || ""}`);
    validation.setFieldValue("deviceInfo", {
      osName,
      browserVersion,
      browserName,
      mobileVendor,
      mobileModel,
      deviceType,
    });

    const data = getLoginData();
    if (data?.emailId) {
      validation.setFieldValue("emailId", data?.emailId);
      validation.setFieldValue("password", data?.password);
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    if (obj.apiKey && obj.authToken && !isUserLogout) {
      setAuthorization(obj.apiKey, obj.authToken);

      const cb = (user: any) => {
        if (user?.companyName && user?.phone) {
          props.router.navigate("/dashboard");
        } else {
          toast.warning(
            "Please complete your profile to enhance your experience"
          );
          props.router.navigate("/settings/general");
        }
      };
      dispatch(getAuthUserThunk(cb));
    }
  }, [loginData, isUserLogout]);

  const handleRememberMe = () => {
    if (isChecked) {
      setLoginData(validation?.values);
    } else {
      clearLoginData();
    }
  };

  const onChange = async (value: any) => {
    if (value) {
      const cb = (data: {
        city?: string;
        region_name?: string;
        country_name?: string;
        zip?: string;
        ip?: string;
      }) => {
        validation.setFieldValue(
          "location",
          `${data?.city || ""}, ${data?.region_name || ""}, ${
            data?.country_name || ""
          }, ${data?.zip || ""}`
        );
        validation.setFieldValue("ip", data?.ip);

        dispatch(
          loginUserThunk(
            {
              ...validation.values,
              location: `${data?.city || ""}, ${data?.region_name || ""}, ${
                data?.country_name || ""
              }, ${data?.zip || ""}`,
              ip: data?.ip,
            },
            props.router.navigate,
            recaptchaRef
          )
        );
      };

      dispatch(getLocationThunk(cb));
    }
  };

  return (
    <ParticlesAuth>
      <React.Fragment>
        <Col lg={5} xl={6} className="mx-auto">
          <Card className="mb-0 border-0 shadow-none mb-0">
            <Card.Body className="p-sm-5 m-lg-4">
              <div className="text-center mt-5">
                <h5 className="fs-3xl">Welcome Back</h5>
                <p className="text-muted">
                  Sign in to continue to Signal House
                </p>
              </div>
              <div className="p-2 mt-5">
                <Form
                  action="#"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <ReCAPTCHA
                    onChange={onChange}
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LfhWLUpAAAAABRtUNaQvSlm_mNws1zmJYEoIY3A"
                  />
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="emailId">
                      Username <span className="text-primary">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      id="emailId"
                      name="emailId"
                      placeholder="Enter username"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.emailId || ""}
                      isInvalid={
                        validation.touched.emailId && validation.errors.emailId
                          ? true
                          : false
                      }
                    />
                    {validation.touched.emailId && validation.errors.emailId ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.emailId}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <div className="float-end">
                      <Link
                        to={process.env.PUBLIC_URL + "/forgot-password"}
                        className="text-muted"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <Form.Label htmlFor="password">
                      Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Control
                        type={passwordShow ? "text" : "password"}
                        id="password"
                        name="password"
                        className="form-control pe-5 password-input"
                        placeholder="Enter password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        isInvalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.password}
                        </Form.Control.Feedback>
                      ) : null}
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="formRememberMe">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                      id="auth-remember-check"
                      checked={isChecked}
                      onChange={() => {
                        setChecked && setChecked(!isChecked);
                      }}
                    />
                  </Form.Group>

                  <div className="mt-4">
                    <Button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && <Spinner size="sm" />} Sign In
                    </Button>
                  </div>
                </Form>

                <div className="text-center mt-5">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <Link
                      to={process.env.PUBLIC_URL + "/register"}
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      SignUp
                    </Link>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default withRouter(Signin);
